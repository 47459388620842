$brand-color1: #00A759;
$brand-color2: #262630;
$brand-color3: #707070;

$light-gray : #9B9B9B;
$semi-gray: #B3B8BD;
$semi-dark-gray: #828282;
$semi-lightgray: #707070;
$semi-black: #212121;
$semi-ligther-gray: #757575;
$brand-green1: #53C457;
$brand-green-light: #00A85B;
$brand-red: #EC3237;

