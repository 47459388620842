.inapp-wrap {
    background: #F3F3F3;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .page-main-content {
        // display: flex;
        position: fixed;
        background: #F3F3F3;
        overflow: auto;
        // width: 67%;
        left: 290px;
        padding: 30px 4% 10% 1.5%;
        top: 0;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 1024px) {
            left: 0;
            padding-left: 2%;
            padding-right: 2%;

            &.full_width {
                padding-left: 0;
                padding-right: 0;

                .products-list .main-txt-head {
                    display: flex;
                }
                .for_orders{
                    display: block !important;
                    margin: 0;
                    padding-bottom: 10px;
                }
                .order-info-head{
                    padding-left: 2%;
                    padding-right: 2%;
                }

                .orders-header {
                    padding-left: 2%;
                    padding-right: 2%;
                }
            }
        }
    }

    .inapp-container {

        // top: 10px;
        position: relative;
        // padding-left: 2%;
        overflow-y: auto;
        margin-top: 30px;

        &:not(.mobile) {
            // width: calc(100% - 250px);
            // left: 250px;
        }

        &.mobile,
        &.no-stores-wrap {
            width: 100%;
            padding-left: 0;
            left: 0;
        }
    }

    .cards {
        background: #ffffff;
        border-radius: 5px;

        .cardheading {
            color: #333333;
            font-weight: 300;
            padding: 12px 0;
            border-bottom: 1px solid #EBF2F7;
            margin: 0 18px;

            &.pd-0 {
                margin: 0;
            }

            &.with-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 12px;
                }
            }
        }

        .cardbody {
            padding: 20px 12px;
        }
    }

    .mb-0 {
        margin-bottom: 0;
    }
}

.mobile {
    .page-content {
        &.dashboard {
            display: block;
        }
    }
}