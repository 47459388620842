@import "./shared/colors";
@import "./shared/constants";


.containers {
    width: 100%;
    height: 100%;
    background: #FCFCFC;


    .landing-header {
        display: flex;
        justify-content: space-between;
        background: #FFF;
        box-shadow: 0px 4px 14px 0px rgba(91, 87, 87, 0.10);
        padding: 1rem 4rem;

        .logo {
            width: 130.313px;
            height: 32.153px;
        }

        .logo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        .links {
            display: flex;
            align-items: center;
        }

        .login {
            color: $brand-green-light;
            font-size: 13px;
            font-weight: $font-regular;
            margin-right: 2rem;
        }

        .get-button {
            display: flex;
            height: 48px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px;
            background: $brand-green-light;
            color: #FFF;
            font-size: 13px;
            font-weight: $font-semibold;
        }

    }

    @media only screen and (max-width: 400px) {
        .landing-header {
            padding: 1rem 1rem;

            .get-button {
                display: none;
            }
        }

        .logo {
            width: 10.313px;
            height: 30.153px;
        }

    }

    .hero-bg {
        width: 100%;
        height: 685px;
        background-position: center;
        background-size: contain;
        background: url("../assets/Frame\ 10265.svg");
        position: relative;
        display: flex;

        .title {
            color: #FFF;
            font-size: 30px;
            font-weight: $font-mediumbold;
            width: 50%;
            padding-left: 5.5rem;
            padding-top: 4rem;
            line-height: 40.8px;
        }

        .create-form {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 14px 0px rgba(44, 44, 44, 0.10);
            padding: 1.5rem;
            justify-content: space-between;
            width: 41%;
            margin-left: 7%;
            position: absolute;
            top: 35%;
            z-index: 1000;
        }

        .sideImg {
            width: 60%;
            height: fit-content;
            position: absolute;
            right: 0%;
            top: 0;
        }

        .sideImg .img1 {
            object-fit: contain;
            object-position: center;
            margin-right: 2rem;
            width: auto;
        }

        .sideImg .img2 {
            position: absolute;
            top: 66%;
            right: 10%;
            object-fit: contain;
            object-position: center;
            width: auto;
            bottom: 0;
            max-height: fit-content;
        }

        .flex {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        .formTitle {
            color: #312E2E;
            font-size: 17px;
            font-weight: $font-semibold;
        }

        .times {
            color: #312E2E;
            font-size: 24px;
            margin-top: -.4rem;
            cursor: pointer;
        }


        .biz-cat {
            .dropdown-container {
                box-shadow: none !important;
                z-index: 1000;
                border-radius: 5px !important;
                // border-radius: 35px !important;
                height: 44px;
                border: 1.2px solid #BDBDBD;
                margin-top: 1rem;
                margin-bottom: 1rem;
                font-size: 13px;
                // width: 100%;
                outline: none;
                width: 100%;
                height: 44px;
                outline: none;
                border: none;
                border-radius: 5px;
                background: #F4F4F4;
                color: #7A7A7A;
                font-size: 13px;
                font-weight: $font-semiregular;
                padding-left: -.6rem;
            }

            &.invalid-input-wrap .dropdown-container {
                background: #fef3f2 !important;
                border-color: #fda29b !important;
            }
        }

        .biz-cat input[type=checkbox] {
            width: 16px;
            height: 16px;
        }

        .css-yk16xz-control {
            border: none;
            outline: none;
            border-radius: 5px;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            box-shadow: 0 0 0 1px #FFF;
            padding-left: -.6rem;
            font-weight: $font-semiregular;
            padding-left: .6rem;
        }

        .css-1pahdxg-control {
            align-items: center;
            background-color: #F4F4F4;
            border-color: none;
            border-radius: 4px;
            border-style: none;
            border-width: 0;
            box-shadow: 0 0 0 1px #FFF;
            outline: none !important;
            position: relative;
            font-size: 13px;
            font-weight: 300;
            padding-left: .6rem;
        }

        .css-1hwfws3 {
            position: static;
            z-index: 1;
        }

        .each-options {
            font-size: 12px;
            margin-top: .5rem;
        }

        .each-options label {
            margin-left: .4rem;
        }

        .url_header {
            font-size: 13px;
            margin-bottom: .6rem;
            margin-top: .7rem;
        }

        .text {
            color: $semi-ligther-gray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            // margin-top: -.7rem;
            width: 90%;
        }

        .line {
            width: 49%;
            height: 4px;
            border-radius: 1px;
            background: rgba(161, 161, 162, 0.3);

        }

        .line_bg {
            height: 100%;
            width: 0px;
            border-radius: 1px;
            opacity: 1 !important;
            background: #00A85B;
            transition: ease all 500ms;
        }

        .line_bg_full {
            width: 100% !important;
        }

        .store-title {
            color: #312E2E;
            font-size: 15px;
            font-weight: $font-regular;
            line-height: 25.6px;
            margin-top: .7rem;
        }

        .store-logo {
            width: 68px;
            height: 68px;
        }

        .store-logo .store-img1 {
            width: 100%;
            height: 100%;
        }

        .inputbox {
            width: 80%;
        }

        .inputbox input {
            width: 100%;
            height: 44px;
            outline: none;
            border: none;
            border-radius: 5px;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            font-weight: $font-semiregular;
            padding-left: 1rem;
        }

        .inputbox2 {
            width: 100%;
            position: relative;
        }

        .inputbox2 input {
            width: 100%;
            height: 44px;
            outline: none;
            border: none;
            border-radius: 5px;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            font-weight: $font-semiregular;
            padding-left: 1rem;
            margin-top: 1rem;
        }

        .link {
            color: $brand-green-light;
            font-size: 10px;
            font-weight: $font-semiregular;
            line-height: 26px;
        }

        .select {
            width: 100%;
            height: 44px;
            outline: none;
            border: none;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            border-radius: 5px;
            padding-left: .8rem;
        }

        .select2 {
            width: auto;
            height: 44px;
            outline: none;
            border: none;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            border-radius: 5px 0 0 0;
            padding-left: .8rem;
        }

        .flex-box {
            display: flex;
            gap: 15px;
            margin-top: 1rem;
        }

        .input-container {
            display: flex;
            width: 100%;
            align-items: flex-start;
        }

        .inputbox3 {
            width: 50%;
            position: relative;
        }

        .inputbox3,
        input {
            width: 100%;
            height: 44px;
            outline: none;
            border: none;
            border-radius: 5px;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            font-weight: $font-semiregular;
            padding-left: 1rem;
        }

        .terms {
            color: #605E5E;
            font-size: 13px;
            font-weight: $font-semiregular;
            display: flex;
            flex-wrap: wrap;
            margin-left: .7rem;
        }

        .flexer {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;
        }

        .check {
            width: 16px;
            height: 16px;
        }

        .terms2 {
            color: $brand-green-light;
            font-size: 13px;
            font-weight: $font-regular;
            margin: 0 .2rem;
        }

        .eye {
            position: absolute;
            right: 2%;
            top: 50%;
            color: #312E2E;
            font-weight: $font-semiregular;
        }

        .flex2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .continue {
            display: flex;
            width: 116px;
            height: 48px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 5px;
            background: $brand-green-light;
            border: none;
            outline: none;
            margin-bottom: 1rem;
            color: #FFF;
            font-size: 13px;
            font-weight: $font-semibold;
        }

        @media only screen and (max-width: 1230px) {
            .title {
                font-size: 26px;
                padding-left: 4rem;
                width: 50%;
            }

            .create-form {
                width: 44%;
                padding-left: 1rem;
                left: -2%;
            }

            .sideImg {
                width: 55%;
            }

            .sideImg .img1 {
                margin-right: 0;
            }

            .sideImg .img2 {
                right: 5%;
            }
        }

        @media only screen and (max-width: 1024px) {
            .title {
                font-size: 24px;
                padding-left: 3rem;
                line-height: 35.8px;
                width: 60%;
            }

            .sideImg .img2 {
                right: 0%;
            }
            .create-form{
                top: 27%;
            }
        }

        @media only screen and (max-width: 920px) {
            .title {
                font-size: 22px;
                padding-left: 2.5rem;
                line-height: 27.8px;
                width: 60%;
            }

            .sideImg .img2 {
                right: 0%;
                top: 75%;
            }

            .create-form {
                width: 50%;
                top: 25%;
            }
        }

        @media only screen and (max-width: 784px) {
            .title {
                font-size: 32px;
                padding-left: 4rem;
                line-height: 35.8px;
                width: 90%;
            }

            .create-form {
                width: 90%;
                position: relative;
                margin-bottom: 2rem;
                margin-top: 2rem;

            }
        }

        @media only screen and (max-width: 520px) {
            .title {
                font-size: 24px;
                padding-left: 1.5rem;
                line-height: 35.8px;
                width: 85%;
            }

            .create-form {
                width: 90%;
                position: relative;
                margin-bottom: 2rem;
                margin-top: 2rem;

            }

            @media only screen and (max-width: 400px) {
                .title {
                    font-size: 20px;
                    padding-left: 1.5rem;
                    line-height: 30.8px;
                    width: 90%;
                }

                .create-form {
                    width: 90%;
                    position: relative;
                    margin-bottom: 2rem;
                    margin-top: 2rem;

                }
                .text {
                    font-size: 11px;
                    line-height: 18.4px;
                    width: 100%;
                }
                .store-logo {
                    width: 64px;
                    height: 64px;
                }
                .inputbox{
                    margin-left: .6rem;
                }
                .flex2 .text{
                    display: none;
                }
                .flex2{
                    justify-content: flex-end;
                }
                .flex-box {
                    flex-direction: column;
                    gap: 2px;
                }
        
            }
        }
    }

    @media only screen and (max-width:920px) {
        .hero-bg {
            height: 620px;
        }
    }

    @media only screen and (max-width:784px) {
        .hero-bg {
            height: 100%;
            flex-direction: column;
            padding-bottom: 3rem;

            .sideImg .img2 {
                width: 100%;
                position: relative;
                height: 100%;
            }

            .sideImg .img1 {
                width: 100%;
                height: 100%;
            }

            .sideImg {
                position: relative;
                width: 100%;
                height: 30%;

            }
        }
    }

    .second-container {
        background: #FCFCFC;
        width: 100%;
        height: 100%;
        padding: 2rem 3rem;

        .right {
            display: flex;
            justify-content: flex-end;
            width: 40%;
            margin-left: 60%;

        }

        .growth {
            color: #312E2E;
            font-size: 32px;
            font-weight: $font-semibold;
            line-height: 45.4px;
            /* 50.4px */
        }

        .icon {
            width: 130px;
            height: 40px;
        }

        .icon img {
            width: 100%;
            height: 100%;
        }

        .icons {
            display: flex;
            justify-content: space-between;
            margin-top: 1.5rem;
        }

        @media only screen and (max-width: 784px) {
            .right {
                display: flex;
                justify-content: flex-end;
                width: 90%;
                margin: auto;

            }

            .icons {
                display: flex;
                justify-content: space-between;
                margin-top: 1.5rem;
                width: 100%;
            }
        }

        @media only screen and (max-width: 520px) {
            .growth {
                color: #312E2E;
                font-size: 28px;
                font-weight: $font-semibold;
                line-height: 40.4px;
                /* 50.4px */
            }

        }

        @media only screen and (max-width: 400px) {
            .growth {
                color: #312E2E;
                font-size: 21px;
                font-weight: $font-semibold;
                line-height: 35.4px;
                /* 50.4px */
            }

        }
    }

    @media only screen and (max-width: 784px) {
        .second-container {
            padding: 2rem 0rem;
        }
    }

    .third-container {
        margin-top: 6rem;

        .scale {
            text-align: center;
            color: #312E2E;
            font-size: 25px;
            font-weight: $font-semibold;
            /* 39.2px */
        }

        .flex {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 2rem 5rem;
            align-items: center;
        }

        .flex2 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 2rem 5rem;
            align-items: center;
        }

        .graph {
            width: 47%;
            height: 390px;
        }

        .graph img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        .row2 {
            width: 47%;

            .roww {
                display: flex;
                padding: 1px 12px;
                width: 40%;
                height: 40px;
                align-items: center;
                gap: 4px;
                border-radius: 50px;
                background: rgba(231, 250, 232, 0.65);
            }

            .data {
                color: $brand-green-light;
                font-size: 12px;
                font-weight: $font-regular;
                margin-top: 1.3rem;
            }

            .key {
                color: #312E2E;
                font-size: 18px;
                font-weight: $font-semibold;
                margin-top: 1rem;
                line-height: 28px;
                display: block;
            }

            .key2 {
                display: none;

            }

            .texts {
                color: $semi-ligther-gray;
                font-size: 13px;
                font-weight: $font-semiregular;
                line-height: 22.4px;
                margin-top: .7rem;
                width: 85%;
            }

        }

        @media only screen and (max-width: 784px) {
            .scale {
                font-size: 28px;
            }

            .flex {
                flex-direction: column;
                padding: 1.3rem 2rem;
            }

            .row2 {
                width: 100%;

                .texts {
                    color: $semi-ligther-gray;
                    font-size: 13px;
                    font-weight: $font-semiregular;
                    line-height: 22.4px;
                    margin-top: .7rem;
                    width: 100%;
                }
            }

            .graph {
                width: 100%;
            }

            .graph img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .flex2 {
                flex-direction: column-reverse;
                padding: 1.7rem 2rem;
            }

        }

        @media only screen and (max-width: 520px) {
            .scale {
                text-align: center;
                font-size: 24px;
            }

            .graph img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }

            .graph {
                margin-top: -4rem;
            }

            .row2 {
                .roww {
                    width: 60%;
                    margin-top: -3rem;
                }

                .key {
                    display: none;
                }

                .key2 {
                    display: block;
                    color: #312E2E;
                    font-size: 16px;
                    font-weight: $font-semibold;
                    margin-top: 1rem;
                    line-height: 26px;
                }
            }

        }

        @media only screen and (max-width: 400px) {
            .scale {
                font-size: 22px;
            }

            .graph {
                margin-top: -5rem;
            }

            .row2 {
                .roww {
                    width: 70%;
                }

            }
            .flex2 {
                justify-content: flex-end;
                align-items: flex-end;
            }

        }

    }

    @media only screen and (max-width: 784px) {
        .third-container {
            margin-top: 3rem;
        }
    }

    .graphbg {
        background: url('../../src/assets/graph-bg.svg');
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        padding: 5rem 4rem;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        position: relative;

        .first {
            width: 40%;
        }

        .first2 {
            display: flex;
            flex-direction: column;
            width: 60%;
        }

        .first h3 {
            width: 95%;
            margin-top: 2.5rem;
            color: #312E2E;
            font-size: 25px;
            font-weight: $font-semibold;
            line-height: 39.2px;
        }

        .first p {
            color: $brand-color3;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            margin-top: 1rem;
        }

        .grid {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 100%;
            gap: .5rem;
            column-gap: 2rem;
        }

        .grid2 {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 2rem;
            height: 100%;

        }

        .cardBg {
            padding: 2rem 2rem 2rem 2rem;
            height: 100%;
        }

        .cardBg2 {
            padding: 2rem 2rem 2rem 2rem;
            height: 100%;
            margin-top: 30px;
        }

        .icon {
            font-size: 20px;
            color: $brand-green-light;
        }

        .cardTitle {
            color: #312E2E;
            font-size: $font-size-medium;
            font-style: normal;
            font-weight: $font-semibold;
            margin-top: 1.3rem;
        }

        .cardtext {
            color: $semi-ligther-gray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            margin-top: 1rem;
        }


    }

    @media only screen and (max-width: 1100px) {
        .graphbg {
            padding: 2rem 1.3rem 4rem 1.3rem;
            display: flex;
            flex-direction: column;

            .first {
                width: 50%;
                margin-left: 7%;
            }

            .first2 {
                width: 90%;
                margin: auto;
            }

            .grid {
                width: 100%;
                gap: 0;
                row-gap: 2rem;
                column-gap: 0rem;
            }

            .cardBg {
                padding: 4rem 2rem 2rem 2rem;
                height: 100%;
            }

        }
    }

    @media only screen and (max-width: 920px) {
        .graphbg {
            padding: 2rem 1.3rem 4rem 1.3rem;
            display: flex;
            flex-direction: column;

            .first {
                width: 70%;
                margin-left: 7%;
            }

            .first2 {
                width: 90%;
                margin: auto;
            }

            .grid {
                width: 100%;
                gap: 2rem;
                row-gap: .1rem;
                column-gap: 1.7rem;
            }

            .cardBg {
                padding: 2rem 2rem 2rem 2rem;
                height: 100%;
            }

        }
    }

    @media only screen and (max-width: 784px) {
        .graphbg {
            padding: 2rem 1.3rem;
            display: flex;
            flex-direction: column;

            .first {
                width: 90%;
            }

            .first2 {
                width: 95%;
                margin: auto;
            }

            .grid {
                grid-template-columns: 1fr;
                row-gap: 2rem;
                column-gap: 0rem;

            }

            .cardBg {
                height: 100%;
                padding: 2rem 2rem 2rem 3rem;
            }
            .cardBg2 {
                padding: 2rem 2rem 2rem 2rem;
                height: 100%;
                margin-top: 0px;
            }
        }

        @media only screen and (max-width: 520px) {
            .graphbg {
                padding: 2rem .6rem;

                .first h3 {
                    width: 95%;
                    margin-top: 2.5rem;
                    color: #312E2E;
                    font-size: 20px;
                    font-weight: $font-semibold;
                    line-height: 36px;
                }

                .grid {
                    row-gap: .5rem;
                }
            }
        }

        @media only screen and (max-width: 400px) {
            .graphbg {
                padding: 1rem .6rem;

                .first h3 {
                    width: 95%;
                    margin-top: 1.5rem;
                    color: #312E2E;
                    font-size: 18px;
                    font-weight: $font-semibold;
                    line-height: 30px;
                }

                .grid {
                    grid-template-columns: 1fr;
                    row-gap: .1rem;
                    column-gap: 0rem;

                }
            }
        }
    }
    .success{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;
    }
    .resend{
        text-align: center;
    }
    .error{
        font-size: 11px;
        color: rgb(252, 112, 112);
    }
    .ctas_wrapper {
        display: flex;
        align-items: center;
        .times{
            margin-right: 15px;
        }
        .back_cta {
            background: #EAEDED;
            margin-right: 10px;
            color: #7A7A7A;
            font-size: 28px;
        }
    }
}

.login-link, .login-link:hover, .login-link:active, .login-link:visited{
    color: #00A85B;
    text-decoration: none;
}