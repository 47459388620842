@import "./shared/colors";
@import "./shared/constants";
@import "./shared/global";

.cardContainer2 {
    width: 100%;
    padding: 4rem 4rem;

    .cards {
        display: grid;
        grid-template-columns: 2fr 1.2fr 1.2fr 1.2fr;
        gap: 1.3rem;
        margin-top: 1rem;

        .card {
            display: flex;
            height: 508px;
            padding: 379px 35px 24px 24px;
            flex-direction: column;
            justify-content: flex-end;
        }

        .icon {
            color: #fff;
            font-size: 20px;
        }

        .cardTitle {
            color: #FFF;
            font-size: $font-size-medium;
            font-weight: $font-semibold;
            line-height: 28.8px;
        }

        .cardText {
            color: #F3F3F3;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 1100px) {
        .cards {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 1.3rem;
            margin-top: 1rem;
        }
    }

    @media only screen and (max-width: 784px) {
        .cards {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1.3rem;
            margin-top: 1rem;

            .card {
                display: flex;
                height: 170px;
                padding: 51px 127px 16px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }

    @media only screen and (max-width: 520px) {
        .cards {
            .card {
                display: flex;
                height: 150px;
                padding: 51px 50px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .cards {
            gap: 1rem;
            margin-top: 1rem;

            .card {
                display: flex;
                height: 150px;
                padding: 51px 0px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }

            .card0 {
                display: flex;
                height: 200px;
                padding: 51px 0px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
    }


    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .benefit {
        color: $brand-green-light;
        font-size: 12px;
        font-weight: $font-regular;
    }

    .title {
        color: #312E2E;
        font-size: 2.3rem;
        font-weight: 600;
        text-align: center;
        line-height: 39.2px;
        width: 100%;
    }

    .titles {
        display: none;
    }

    .subtext {
        color: $brand-color3;
        font-size: 13px;
        font-weight: $font-semiregular;
        line-height: 22.4px;
        margin-top: 3rem;
        width: 70%;
        margin-left: 30%;
    }

    .faq-wrap {
        padding-top: 4rem;
    }

    .contact-cta {
        text-align: center;
        margin: 1.6rem 0;

        a {
            border: 1px solid $brand-green-light;
            color: $brand-green-light;
            padding: 0.5rem 1rem;
            font-weight: 500;
        }
    }

    .top2 {
        display: block;
        justify-content: space-between;

        .subtext2 {
            color: $semi-lightgray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            width: 90%;
            margin-top: 1rem;
        }

        .answer {
            color: $semi-ligther-gray;
            font-size: 0.9rem;
            font-weight: $font-semiregular;
            line-height: 22.4px;
        }

        .flexes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }

        .question {
            color: #312E2E;
            font-size: 1.2rem;
            font-weight: $font-regular;
            padding-top: .1rem;
            padding-bottom: .7rem;

        }

        .right {
            width: 95%;
            margin: 0 auto;
            padding-top: 1rem;
        }

        .left {
            width: 40%;
        }

        .bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 1rem;
        }

        .contact {
            display: flex;
            height: 48px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px;
            background: $brand-green-light;
            border: none;
            outline: none;
            color: #FFF;
            font-size: 13px;
            font-weight: $font-semibold;
        }
    }

    @media only screen and (max-width: 784px) {
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        .titles {
            display: block;
            color: #312E2E;
            font-size: $font-size-big;
            font-weight: $font-semibold;
            line-height: 39.2px;
        }

        // .title {
        //     display: none;
        // }

        .subtext {
            margin-top: 1.5rem;
            width: 70%;
            margin-left: 0%;
        }
    }


    @media only screen and (max-width: 520px) {
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        .titles {
            display: block;
            color: #312E2E;
            font-size: 22px;
            font-weight: $font-semibold;
            line-height: 34.2px;
            width: 100%;
        }

        .subtext {
            width: 100%;
            margin-top: .7rem;
        }
    }

    @media only screen and (max-width: 400px) {
        .titles {
            display: block;
            color: #312E2E;
            font-size: 18px;
            font-weight: $font-semibold;
            line-height: 30.2px;
            width: 100%;
        }

        .subtext {
            width: 100%;
            margin-top: .7rem;
        }
    }

    .top {
        margin-top: 4rem;

        .users {
            display: grid;
            width: 38%;
            grid-template-columns: 1fr;
            margin-top: 2.5rem;
            gap: 1rem;
        }

        // .users-mobile {
        //     display: none;
        // }

        .user {
            display: flex;
            align-items: center;
            padding: 20px 70px 20px 20px;
            border-radius: 12px;
            background: rgba(231, 250, 232, 0.65);
        }

        .user2 {
            display: flex;
            align-items: center;
            padding: 20px 70px 20px 20px;
            cursor: pointer;
            border-bottom: 1px solid #f2f2f2;

            &:hover {
                // padding: 20px 70px 20px 20px;
                border-radius: 12px;
                background: rgba(231, 250, 232, 0.65);
                border-bottom: none;
            }
        }

        .user img {
            width: 80px;
            height: 80px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;
        }

        .user2 img {
            width: 80px;
            height: 80px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;
        }

        .details {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            margin-top: 1.2rem;
        }

        .name {
            color: #312E2E;
            font-size: 1rem;
            font-weight: 600;
            margin-top: -1rem;
            padding-top: -2rem;

        }

        .store {
            color: #414141;
            font-size: 13px;
            font-weight: $font-semiregular;
            margin-bottom: -.2rem;
            line-height: 30px;
        }

        .rows {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: stretch;
        }

        .comments {
            width: 58%;
            // margin-bottom: 2.7rem;
            background: #F9F9F9;
            padding: 5% 7%;
            border-radius: 16px;
            margin-top: 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .commentTitle {
            color: #312E2E;
            font-size: 1.9rem;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0.4px;
            margin-bottom: 1.7rem !important;
            padding-bottom: 3px;

            line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
        }

        .commentText {
            color: $brand-color3;
            font-size: 1.4rem;
            font-weight: $font-semiregular;
            line-height: 1.5;
        }

        .quote {
            height: unset;
            margin: unset;
        }

        // @media only screen and (max-width:1100px) {
        //     .users {
        //         display: grid;
        //         width: 45%;
        //         grid-template-columns: 1fr;
        //         margin-top: 2.5rem;
        //         gap: 3rem;
        //     }
        // }

        @media only screen and (min-width: 769px) {
            //here
            // .rows {
            //     display: flex;
            //     width: 100%;
            //     justify-content: space-between;
            //     align-items: flex-start;
            // }

            // .users {
            //     display: grid;
            //     width: 100%;
            //     grid-template-columns: 1fr;
            //     margin-top: 2.5rem;
            //     gap: 3rem;
            // }

            // .comments {
            //     width: 100%;
            //     margin-top: 2.7rem;
            // }

            // .user {
            //     padding: 20px 0px 20px 20px;
            // }

            // .user2 {
            //     display: flex;
            //     align-items: center;
            //     padding: 20px 0px 20px 20px;

            // }

            // .quote {
            //     height: 30%;
            //     margin: 23% 0;
            // }
        }

        @media only screen and (max-width: 899px) {
            margin-top: 0;
            //here
            .rows {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
            }
            .toggle-icon{
                position: absolute;
                top: 20px;
                right: 20px;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }



            .comments,
            .users {
                width: 100%;
                margin-top: 1.5rem;
            }
            .user2, .user{
                align-items: flex-start !important;
                .details{
                    margin-top: 0;
                }
                .name{
                    margin-top: 0;
                }
                img{
                    width: 50px;
                    height: 50px;
                }
            }


            .user {
                padding: 20px ;
                position: relative;
                flex-wrap: wrap;
                .comments{
                    flex-basis: 100%;
                    background: none;
                    padding: 20px 0;
                    margin-top: 0 ;
                }
                .commentTitle{
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 1.2;
                    margin-bottom: 15px !important;
                    
                }
                .commentText{
                    font-size: 1rem;
                }
            }
            .store{
                line-height: 1.3;
                margin-bottom: 10px;
            }

            .user2 {
                display: flex;
                align-items: center;
                padding: 20px ;
                position: relative;
                
            }

            .quote {
                // display: none;
            }
        }

        @media only screen and (max-width: 899px) {
            // padding: 4rem 2rem;

            // .top {
            //     margin-top: 0 !important;

            //     .title {
            //         font-size: 2rem;
            //         margin-bottom: 20px !important;
            //     }

            //     .users {
            //         margin-top: 0;
            //         gap: unset;
            //     }

            //     .user2 {
            //         padding-top: 0;
            //         margin-bottom: 20px;
            //         border-bottom: 1.3px solid #ddd;
            //     }

            //     .user {
            //         margin-bottom: 20px;
            //         padding-left: 20px;
            //         padding-right: 20px;

            //         img {
            //             width: 60px;
            //             height: 60px;
            //         }
            //     }
            // }
        }

        @media only screen and (min-width: 900px) and (max-width: 1024px) {
            margin-top: 0;

            .rows {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;

                .comments {
                    padding-right: 3%;
                    padding-left: 3%;
                }
            }

            .users {
                width: 45%;
            }

            .comments {
                width: 53%;
            }

        }

    }


    @media only screen and (min-width: 900px) and (max-width: 1024px) {
        padding: 4rem 3rem;

        .rows {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
        }

    }

    @media only screen and (max-width: 767px) {
        padding: 4rem 2rem;
    }

}


@media only screen and (max-width: 1024px) {
    .top2 {
        display: block;
        justify-content: flex-start;
        flex-direction: column;

        .right {
            width: 100%;
            padding-top: 1rem;
        }

        .left {
            width: 100%;
        }
    }

}



@media only screen and (max-width: 520px) {
    .cardContainer2 {
        padding: 4rem 2rem;
    }

}

@media only screen and (max-width: 400px) {
    .cardContainer2 {
        padding: 4rem 1rem;
    }

}

.mapBg2 {
    background: url('../../src/assets/landing/join-bg.png');
    width: 100%;
    height: 100%;
    // padding: 4rem 0;
    padding: unset;
    background-position: center;
    background-size: cover;

    position: relative;

    &::before {
        content: "";
        background: rgba(231, 250, 232, 0.58);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
    }

    .joinus-wrap {
        z-index: 2;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        padding: 5rem 0;
        position: relative;
        //   padding: 5rem 4rem;
    }

    .join {
        color: $brand-green-light;
        font-size: 12px;
        font-weight: $font-regular;
    }

    .mapTitle {
        color: #312E2E;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        line-height: 38px;
        width: 50%;
    }

    .mapText {
        color: #575757;
        text-align: center;
        font-size: 12px;
        font-weight: $font-semiregular;
        line-height: 21px;
        width: 47%;
        margin-top: .5rem;
    }

    .join-cta {
        background: $brand-green-light;
        color: #FFF;
        padding: 0.8rem 1.2rem;
        position: relative;

        &::after {
            content: " ";
            position: absolute;
            width: 25%;
            background: url('../../src/assets/landing/join-arrow.png');
            background-repeat: no-repeat;
            background-size: 80%;
            padding: 10%;
            right: -30%;
            bottom: -58%;
        }
    }

    .start {
        display: flex;
        width: 145px;
        height: 48px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        background: $brand-green-light;
        border: none;
        outline: none;
        color: #FFF;
        font-size: 13px;
        font-weight: $font-semibold;
        line-height: 22.4px;
    }

}

@media only screen and (max-width: 784px) {
    .mapBg2 {
        padding: 2rem 0;

        .mapTitle {
            width: 70%;
            font-size: 24px;
            line-height: 36px;
        }

        .mapText {
            width: 74%;
        }

    }
}

@media only screen and (max-width: 520px) {
    .mapBg2 {
        padding: 2rem 0;

        .mapTitle {
            width: 90%;
            font-size: 24px;
            line-height: 36px;
        }

        .mapText {
            width: 80%;
        }

    }
}

@media only screen and (max-width: 400px) {
    .mapBg2 {
        padding: 2rem 0;

        .mapTitle {
            width: 90%;
            font-size: 18px;
            line-height: 27px;
        }

        .mapText {
            width: 85%;
        }

    }
}