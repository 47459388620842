.input-wrap {
    border-radius: 5px !important;
    // border-radius: 35px !important;
    height: 40px;
    border: 1.2px solid #BDBDBD;
    padding: 0 10px;
    font-size: 13px !important;
    width: 100%;
    outline: none;

}

input.input-wrap,
textarea.input-wrap {
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

select.form-control {
    background: transparent;
    box-shadow: none !important;
}

.form-input-error {
    color: #EC3237;
    font-size: 12px;
    margin-left: 10px;
}

// &.btn-frozen{
//     background: $very-light-gray;
//     color: $semi-dark-gray;
//     pointer-events: none;
// }
.success-animation {
    margin: 10px auto;
}

.success-message-txt {
    font-size: 15px;
    text-align: center;
}

.custom_radio {
    width: 13px;
    height: 13px;
    display: inline-block;
    position: relative;
    z-index: 1;
    opacity: 0.6;
    // top: 5px;
    background: url("../../assets/check-mark-alt.svg") no-repeat;
    background-size: cover;

    &:hover:not(.selected_radio) {
        opacity: 1;
    }

    &.selected_radio {
        background: url("../../assets/icons/check-mark.svg") no-repeat;
        background-size: cover;
        opacity: 1;
    }

    &+label {
        cursor: pointer;
    }
}

.success_icon_tick {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.custom_radio input[type="radio"] {
    margin: 1px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;
    /* CSS hacks for older browsers */
    // _noFocusLine: expression(this.hideFocus=true);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -khtml-opacity: 0;
    -moz-opacity: 0;
}

input.input-wrap.is-invalid-input,
input.input-wrap.is-invalid-input,
select.input-wrap.is-invalid-input,
textarea.input-wrap.is-invalid-input,
.is-invalid-input,
.is-invalid-input.address-search>div
 {
    background: #fef3f2;
    border-color: #fda29b !important;
    
    // &.withwrap{
    //     border-top-right-radius: 35px;
    //     border-bottom-right-radius: 35px;
    // }
    &~.error {
        color: #EC3237;
        font-size: 12px;
    }
}
.inputbox2, .inputbox3{
    .error {
        color: #EC3237;
        font-size: 12px;
    }
}
 

input:focus,
textarea:focus {
    border-color: #98a2b3;
    box-shadow: none !important;
}

.all-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

button:disabled {
    opacity: 0.7;
}

select {}

.input-with-divider {
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 40px;
    border: 1.2px solid #BDBDBD;
    padding: 0 10px;
    font-size: 13px;
    width: 100%;
    outline: none;

    select {
        border: none;
        outline: none;
    }

    .divider {
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid #BDBDBD;
        padding-right: 5px;

        &.normal {
            // display: block;
            font-size: 11px;
            padding: 5px;

            &>div {
                line-height: 1;
                // margin-bottom: 3px;
            }
        }

        .label-text {
            color: #9B9B9B;
        }

        &.store-url {
            display: block;
            font-size: 11px;
            color: #9B9B9B;
            padding: 5px;

            &.forinapp {
                display: flex;
            }

            .prefix-url {
                line-height: 1;
            }
        }
    }

    input {
        border: none !important;
        height: 35px !important;
    }

}

.input-with-divider.with-content {
    padding-right: 0 !important;

    &.with-content-error {
        background: #fef3f2;
        border-color: #fda29b !important;
    }
}

.input-content {
    height: 100%;

    input {
        height: 100% !important;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.alt-cta {

    border: 1px solid #BDBDBD;
    color: #fff;
    border-radius: 5px;
    height: 40px;

    &.full {
        width: 100%;
    }
}

.main-cta {
    background: #00A85B;
    border: none;
    color: #fff;
    border-radius: 5px;
    height: 40px;

    &.full {
        width: 100%;
    }
}

.form-link {
    font-size: 12px;
    color: #707070;
    margin-top: 10px;
    margin-left: 6px;

    a {
        color: #262630;
        margin-left: 5px;
    }
}

.cards,
.page-content.lists-wrap .cards.lists-header {
    @media screen and (max-width: 768px) {
        border-radius: 15px !important;
    }
}