@import "./../../../scss/shared/colors";
@import "./../../../scss/shared/constants";

.header-wrap {
    // background: #fff;
    // display: flex;
    // justify-content: space-between;
    padding: 10px 0;
    // position: fixed;
    width: 100%;
    z-index: 1;
    .logo-head{
        img{
            width: 150px;
        }
        @media screen and (max-width: 768px) {
            img{
                width: 120px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.space_in{
            padding-left: 2%;
            padding-right: 2%;
        }
    }
   

    .dropdown-item.active, .dropdown-item:active{
        background: $brand-color1 !important;
    }
    .header-menus {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .page-heading-content{
            flex-basis: 64%;
        }
        .mobile-logo{
            img{
                max-width: 100px;
            }
        }
        .left-heading{
            display: flex;
            align-items: center;
            .each-menu{
                margin-left: 10px;
            }
        }
    }
    .each-menu {
        margin-left: 20px;
        img{
            width: 35px;
        }
        &.small{
            img{
                width: 25px;
            }
        }
    }
    .store_info{
        display: flex;
    }
    .store-name, .store-location{
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: 20px;
        img{
            width: 15px;
            margin-right: 3px;
        }
    }
    .other_items {
        display: flex;
        align-items: center;
    }
    .notify-cta{
        img{
            width: 20px;
        }
    }
    .stores_list_heading_wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .see_all_stores{
            font-size: 10px;
            font-weight: 500;
            color: $brand-color1;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .profile-info {
        display: flex;
        align-items: center;
        margin-left: 15px;
        .dropdown-menu {
            border: none;
            min-width: 190px;
        }
        a.stores-heading.dropdown-item.disabled {
            color: #312E2E;
            font-weight: 600;
            font-size: 12px;
            pointer-events: unset;
        }
        .store-info_wrap{
            &:active, &:focus, &:hover{
                background: none !important;
            }
        }
        .store-brief{
            display: flex;
            align-items: center;
            padding: 10px 10px;
            margin-bottom: 5px;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
                background: rgba(0, 168, 91, 0.10) !important;
            }
            .store-logoico{
                margin-right: 8px;
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    min-width: 30px;
                }
            }
            .store-name-text{
                font-size: 12px;
                font-weight: 600;
                max-width: 95px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #404040 !important;
                text-transform: capitalize;
            }
            .store-location-text{
                font-size: 10px;
                color: #757575;
            }
        }
    }
    .add-new-store{
        color: $brand-green-light;
        font-size: 12px;
        border-top: 0.7px solid rgba(0, 0, 0, 0.08);
        padding-top: 6px;
        span{
            font-size: 7px;
            color: #F0B27A;
            font-weight: 600;
        }
        &:hover{

        }
    }
    .profile-store-name{
        margin-right: 10px;
        .name-ofuser {
            font-weight: 600;
            font-size: 12px;
        }
        .name-ofstore {
            color: #757575;
            font-size: 10px;
        }
    }
    .profile-img-drop {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none !important;
        border: none !important;
        &::after{
            content: none;
        }
        .user-img{
            width: 35px;
        }
        .drop-ico{
            margin-left: 2px;
            img{
                width: 13px;
            }
        }
    }
    
}

.product-info-header{
    display: flex;
    align-items: flex-start;
    .left-nav{
        cursor: pointer;
        img{
            width: 25px;
        }
    }
    .other-item-wrap{
        .heading-text{
            font-weight: 600;
            font-size: 23px;
            line-height: 1;
            margin-bottom: 10px;
        }
        .heading-msg{
            font-size: 12px;
            color: #757575;
            font-weight: 300;
        }
    }
}
.nobank_linked {
    background: rgba(255, 232, 232, 0.85);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    .bank_txt {
        color: #757575;
        font-weight: 300;
        font-size: 13px;
        margin-left: 10px;
        a{
            color: $brand-green-light;
            font-weight: 500;
        }
    }
}