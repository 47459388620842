@import "../../scss/shared/colors";
@import "../../scss/shared/constants";

img {
    &:not(.omit) {
        object-fit: cover;
        object-position: center;
        position: relative;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #F8F9F9;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &::after {
            content: "";
            font-size: 8px;
            color: #646464;
            position: absolute;
            z-index: 2;
            top: 50%;
            vertical-align: middle;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            cursor: not-allowed;
            pointer-events: none;
            text-align: center;
        }
    }
}

.page-content {
    &.with-heading {
        position: relative;
        // top: -10px;
    }

    &.form-page {
        width: 96%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .for_import {
            margin-bottom: 15px;

            .heading-msg {
                font-size: 12px;
                color: #757575;
            }
        }
    }

    .help_file {
        color: $brand-red;
        font-size: 12px;
        margin-top: 5px;

        a {
            font-weight: 600;
            text-decoration: underline !important;
            color: inherit;
        }
    }

    .each_data {
        height: 50px;
        overflow: auto;
    }

    .upload_heading {
        font-weight: 600;
    }

    .review_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .heading_txt {
            font-weight: 600;
            font-size: 15px;
        }

        .upload_now {
            background-color: $brand-green-light !important;
            color: #ffffff;
            font-weight: 500;
            height: 40px;
            font-size: 12px;

            &:disabled {
                opacity: 0.9;
            }
        }
    }

    .upload-container {
        width: 47%;
        margin: 30px auto;

        .upload_control {
            text-align: center;

            label {
                width: 100%;
                border: 1.5px dashed rgba(0, 0, 0, 0.15);
                border-radius: 6px;
                padding: 18%;
                cursor: pointer;
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .cta_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .help_link {
                display: flex;
                align-items: center;

                img {
                    width: 12px;
                    display: block;
                }

                a {
                    color: $brand-green-light;
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }

        .ctas_list {
            margin-top: 7px;

            .submit {
                background: $brand-green-light !important;
                color: #ffffff;
                font-size: 12px;
                height: 40px;
                font-weight: 500;
                margin-left: 12px;
            }

            .cancel {
                border: 1px solid #757575;
                color: #757575;
                font-size: 12px;
                height: 40px;
            }
        }

        .drag_txt {
            font-size: 12px;
            font-weight: 500;
            margin-top: 15px;
        }

        .file-size {
            font-size: 11px;
            font-weight: 400;
        }

        .upload-products {}

        #upload-products {
            display: none;
        }

        .icon_wrap {
            img {
                width: 20%;
            }
        }

        .import_from_url {
            margin: 20px 0 10px;
            position: relative;

            label {
                font-size: 12px;
            }

            input {
                font-size: 12px !important;
            }

            .import_prompt {
                position: absolute;
                right: 10px;
                font-weight: 500;
                font-size: 10px;
                color: $brand-green-light;
                bottom: 13px;
                cursor: pointer;

                &:hover {
                    font-weight: 600;
                }
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;

            .cta_wrap {
                .help_link a {
                    font-size: 10px;
                }

                .btn {
                    padding-left: 8px;
                    padding-right: 8px;
                    font-size: 11px;
                }

                // display: block;
            }
        }
    }


    .mobile_back_nav {
        display: flex;
        align-items: center;
        margin: 10px 0 15px;

        img {
            width: 20px;
        }

        .back_nav_txt {
            font-size: 14px;
            font-weight: 500;
            margin-left: 5px;
        }
    }

    .page-heading {
        color: #212121;
        font-size: 20px;
        font-weight: 300;
    }

    .product-form {
        background: #fff;
        padding: 31px 29px;
        border-radius: 10px;

        &.for-upload {
            padding-top: 25px;

            .list-contanier {
                margin-top: 0;
            }

            @media screen and (max-width: 767px) {
                .review_heading {
                    .heading_txt {
                        font-size: 12px;
                    }

                    .upload_now {
                        font-size: 11px;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }

        }

        .other-item-wrap {
            margin: 15px 0 22px;

            .heading-text {
                font-weight: 600;
                font-size: 18px;
                line-height: 1;
                margin-bottom: 10px;
            }

            .heading-msg {
                font-size: 12px;
                color: #757575;
                font-weight: 300;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 10px;
        }
    }

    .addproduct-heading {
        background: #D7F9EA;
        padding: 21px 29px;

        h3 {
            color: #00A85B;
            font-weight: 600;
            font-size: 15px;
        }

        .addproduct-top {
            font-size: 12px;
            color: #818181;
        }
    }

    &.dashboard {
        .top-section {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
        }

        .head-wrap {
            display: flex;
            align-items: center;
            padding: 5px 0;

            .head-txt {
                font-weight: 600;
                font-size: 13px;
                flex-basis: 75%;
            }

            select {
                flex-basis: 15%;
                border: none;
                font-size: 11px;
                color: #312E2E;
                outline: none;
            }
        }

        .each-topsection {
            // flex-basis: 100%;
            padding: 10px 18px;
            // display: flex;
            align-items: center;
            justify-content: space-between;

            .section-title {
                color: $semi-black;
                font-weight: 300;
                font-size: 19px;
            }

            .section-wrap {
                flex-basis: 93%;
            }

            .each-scoll {
                width: 9px;
                height: 9px;
                border-radius: 100%;
                border: solid #707070;
                margin-bottom: 3px;

                &.active {
                    background: $brand-color1;
                    border: none;
                }
            }

            .txt-content {
                color: $light-gray;
                font-weight: 300;
                font-size: 13px;
                margin: 15px 0;
            }

            &.with-loader {
                text-align: center;
                justify-content: center;
            }

            .item-cta {
                button {
                    border: 1.5px solid $brand-color1;
                    background: none;
                    color: $semi-ligther-gray;
                    border-radius: 5px;
                    font-weight: 300;
                    font-size: 13px;
                    padding: 6px 20px;
                }
            }

            &.withdrawal {
                flex-basis: 27%;
                margin-left: 3%;
                text-align: center;
                color: $brand-color1;
                background: rgba(230, 254, 241, 0.4);
                border: 1px dashed #00A85B;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    width: 20px;
                }

                .withdrawal-txt {
                    font-size: 13px;
                    font-weight: 500;
                    flex-basis: 100%;
                }
            }

            &.customers {
                flex-basis: 45%;
                margin-left: 3%;
                display: flex;
                background: #ffffff;
                border-radius: 10px;

                .top-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .item-icon {
                    width: 35px;
                }

                .count-txt {
                    font-weight: 600;
                    font-size: 18px;
                }

                .each-data {
                    &:first-of-type {
                        border-right: 1px solid rgba(0, 0, 0, 0.08);
                        padding-right: 13px;
                        margin-right: 13px;
                    }

                    flex-basis: 48%;
                }

                .item-name-txt {
                    color: #757575;
                    font-weight: 300;
                    font-size: 13px;
                    margin: 5px 0;
                }

                .percent-txt {
                    display: flex;
                    font-size: 11px;
                    color: #A09A9A;
                    font-weight: 300;

                    img {
                        width: 15px;
                        height: 15px;
                    }

                    span {
                        margin-right: 3px;

                        &.gr {
                            color: #37BD6B;
                        }

                        &.down {
                            color: #EC3237
                        }
                    }
                }
            }

            &.counts {
                flex-basis: 27%;
                background: #ffffff;
                border-radius: 10px;

                .item-icon {
                    img {
                        width: 14px;
                    }
                }

                .item-name {
                    font-size: 11px;
                    font-weight: 300;
                    margin-left: 5px;
                    color: #707070;
                }

                .each-count {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                    padding: 5px 0;

                    &:last-of-type {
                        border: none;
                    }
                }
            }

            .count-left {
                display: flex;
                align-items: center;
            }

            .count-right {
                font-weight: 500;
                font-size: 12px;
            }
        }

        .review-summary {
            background: #ffffff;
            border-radius: 10px;
            padding: 10px 15px;
            margin-top: 3%;
            min-height: 150px;

            .with-loader {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .heading-txt {
                font-weight: 600;
                font-size: 14px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                padding: 5px 0;
                margin-bottom: 15px;
            }

            .all-rating-cap {
                display: flex;
                margin-top: 15px;
                margin-bottom: 8px;

                .heading {
                    color: #757575;
                    font-weight: 300;
                    font-size: 12px;
                    margin-bottom: 6px;
                }

                .each-cap {
                    margin-right: 20%;
                }

                .icon-wrap {
                    font-weight: 600;
                    font-size: 13px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 18px;
                        margin-right: 5px;
                    }
                }
            }

            .all-bands {
                display: flex;
                align-items: center;

                .each-band {
                    border-radius: 5px;
                    height: 22px;
                    margin-left: 15px;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &.negative {
                        background: #FF718B;
                    }

                    &.neutral {
                        background: #FFEB3A;
                    }

                    &.neutral {
                        background: #FFEB3A;
                    }

                    &.positive {
                        background: #7FE47E;
                    }
                }
            }
        }

        .mid-section {
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            .left-side {
                flex-basis: 75%;
            }

            .right-side {
                flex-basis: 27%;
                margin-left: 3%;
                background: #ffffff;
                border-radius: 10px;
                padding: 10px 15px;
                margin-top: 2%;

                .chartdata-wrap {
                    text-align: center;
                    position: relative;

                    canvas {
                        width: 150px !important;
                        height: 150px !important;
                        margin: 20px auto !important;
                        z-index: 1;
                    }

                    .chartdata-inner {
                        position: absolute;
                        top: 50px;
                        left: 50%;
                        transform: translateX(-50%);

                        img {
                            width: 27px;
                        }

                        span {
                            display: block;
                            width: 100%;
                            font-weight: 600;
                            font-size: 13px;
                        }
                    }
                }

                .statinfo.with-loading {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .other-section {
            &.horizontal {}

            .sales-wrap {
                // display: flex;
                justify-content: space-between;
                margin-top: 3%;
                align-items: baseline;

                @media screen and (max-width: 767px) {
                    display: block;

                    .all-sale-stats {
                        margin: 20px 0;
                    }
                }
            }

            .all-sale-stats {
                padding: 10px 15px;
                min-height: 300px;

                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    min-height: unset;
                }

                &.cards {
                    border-radius: 10px;
                }

                .allstats {
                    padding: 20px 0;
                }

                .heading-txt {
                    font-weight: 600;
                    font-size: 14px;
                }

                &.with-loading .allstats {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .export-cta {
                    border: 1px solid #D4D4D8;
                    border-radius: 5px;
                    font-size: 11px;
                    color: #312E2E;
                    padding: 3px 4px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        border: 1px solid darken(#D4D4D8, 5%);
                    }

                    img {
                        width: 13px;
                        height: 13px;
                        margin-right: 3px;
                    }
                }
            }

            .customer-summary {
                flex-basis: 30%;
            }

        }

        .each-customer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .avi {
                img {
                    border-radius: 100%;
                }
            }

            .price {
                color: #B3B8BD;
                font-size: 11px;
            }
        }

        .all-customers {
            position: relative;
        }

        .customer-info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name-of-cust {
                color: #52575D;
                font-size: 12px;
                font-weight: 300;
                margin-left: 10px;
            }

            .price {
                color: #B3B8BD;
                font-size: 13px;
            }
        }

        .view-all {
            background: rgba(255, 255, 255, 0.9);
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            text-align: center;
            height: 56px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            img {
                width: 30px;
            }
        }



        .stats-heading {
            display: flex;
            align-items: center;
            margin-top: 5px;
            justify-content: space-between;

            .each-stat-head {
                color: $semi-gray;
                cursor: pointer;
                transition: .2s ease;
                font-size: 12px;
                margin-right: 20px;

                &.active,
                &:hover {
                    background: $brand-color1;
                    color: #ffffff;
                    border-radius: 5px;
                    padding: 4px 7px;
                }
            }
        }

        .statinfo {
            margin-top: 40px;
        }

        .other-stats {
            margin-top: 2%;
            display: flex;
            justify-content: space-between;

            .each-info-stat {
                flex-basis: 32.5%;

                &:last-of-type {
                    flex-basis: 30%;
                }

            }

            @media screen and (max-width: 768px) {
                display: block;
            }


        }

        .stat-line {
            height: 5px;
            border-radius: 5px;
            width: 100%;
            position: relative;

            &::before {
                position: absolute;
            }

            &.blue {
                background: #5289C9;
            }

            &.lightblue {
                background: #2862FF;
            }

            &.lightred {
                background: #FF6A81;
            }

            &.darkred {
                background: #EB4A8A;
            }
        }

        .data-stat {
            margin-bottom: 13px;
        }

        .data-percent {
            font-size: 9px;
        }

        .label-data {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .data-count {
                margin-bottom: 7px;

                span {
                    font-size: 11px;

                    &:nth-of-type(2) {
                        margin-left: 20px;
                        color: #BDBDBD;
                    }
                }
            }
        }

        .progress {
            height: 5px;

            &.blue {
                background: rgba(82, 137, 201, 10%)
            }

            &.lightblue {
                background: rgba(40, 98, 255, 10%)
            }

            &.lightred {
                background: rgba(255, 106, 129, 10%)
            }

            &.darkred {
                background: rgba(235, 74, 138, 10%)
            }
        }

        .progress-bar.bg-lightgreen {
            background: #0CEFAB;
        }

        .progress-bar.bg-lightyellow {
            background: #FABC1E;
        }

        .progress-bar.bg-purple {
            background: #6652DE;
        }

        .progress-bar.bg-darkpurple {
            background: #FF2CF7;
        }

        .stat-count {
            margin-left: 10px;
            margin-bottom: 20px;

            .stat-num {
                color: #333333;
                font-weight: 500;
                font-size: 18px;
            }

            .stat-date {
                color: #B3B8BD;
                font-size: 10px;
                font-weight: 300;
                line-height: 1;
            }
        }

        .chart-wrap {
            canvas {
                width: 150px !important;
                height: 150px !important;
                margin: 0 auto;
            }
        }
    }

    &.customer-wrap {
        .all-customers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        .each-customer {
            flex-basis: 32%;
            margin-top: 2%;
            padding: 35px 25px 20px;
            display: flex;
            justify-content: space-between;
            border-radius: 30px;
        }

        .customer-info {
            display: flex;
        }

        .img-wrap {
            img {
                width: 45px;
            }
        }

        .customer-name {
            color: #707070;
            font-size: 14px;
            font-weight: 500;
        }

        .customer-location {
            color: #BDBDBD;
            font-size: 13px;
        }

        .customer-date {
            color: #9B9B9B;
            font-size: 13px;
            font-weight: 300;
            margin-top: 16px;
        }

        .other-info {
            margin-left: 12px;
        }

        .amount-title {
            color: #9B9B9B;
            font-size: 11px;
            margin-left: 8px;
        }

        .buys {
            .amount {
                color: #00A85B;
                font-weight: 500;
                font-size: 18px;
            }
        }
    }

    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin: 50px 0 0;

        // width: 80;
        .each-page {
            background: #fff;
            margin: 0 4px;
            padding: 8px 13px;
            font-size: 9px;
            // border: aliceblue;
            border-radius: 4px;
            font-weight: 300;
            transition: .1s ease;
            cursor: pointer;

            &.active,
            &:hover {
                background: $brand-color1 !important;
                color: #ffffff;
            }

            img {
                width: 7px;
            }

            &.prev-page,
            &.next-page {
                &:hover {
                    background: #fff !important;
                }
            }
        }
    }

    &.lists-wrap {
        .lists-header {
            padding: 10px 0;
            border-radius: 30px !important;

            @media screen and (max-width: 1024px) {
                &.products {
                    padding-left: 2%;
                    padding-right: 2%;
                }
            }

        }

        .header-wrap-items {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.for_products {
                @media screen and (max-width: 1024px) {
                    flex-wrap: wrap;

                    .header-ctas {
                        flex-basis: 100%;
                        justify-content: space-between;

                        .each-cta {
                            flex-basis: 48%;
                            margin-right: 0;
                            padding: 10px;
                        }
                    }

                    .list-filters-wrap {
                        margin-top: 10px;
                        flex-basis: 100%;
                        background: rgba(183, 183, 183, 0.09);
                        border-radius: 5px;

                        .for-search {
                            flex-basis: 100%;

                            input {
                                width: 100%;
                                margin-left: 0;
                                background: rgba(183, 183, 183, 0.09);

                            }
                        }
                    }
                }
            }
        }

        .heading-txt {
            color: #757575;
            font-size: 19px;
            font-weight: 600;
            color: #312E2E;
        }

        .header.msg {
            font-size: 12px;
            color: #9B9B9B;
            font-weight: 300;
        }

        .products {
            .header-ctas {
                margin-top: 12px;


                .each-cta {
                    border: 1px solid $brand-color1;
                    background: none;
                    font-size: 12px;
                    border-radius: 5px;
                    padding: 8px 13px;
                    margin-right: 10px;
                    color: $brand-green-light;

                    &:first-of-type {
                        background: $brand-green-light;
                        color: #ffffff;
                    }

                }

                @media screen and (max-width: 768px) {
                    display: flex;
                    width: 100%;
                    margin-top: 10px;

                    .each-cta {
                        font-size: 10px;
                        padding: 5px 8px;
                    }
                }


            }
        }
    }

    &.order-wrap {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px;

        .orders-header {
            margin-bottom: 20px;

            h3 {
                font-weight: 500;
                font-size: 27px;
                margin-bottom: 15px !important;
            }
        }

        .orders-txt {
            font-size: 12px;
            color: #757575;
            font-weight: 400;

            &.for-cart {
                width: 70%;

                @media screen and (max-width: 764px) {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 768px) {
            background: unset;
            padding: 0;

            .orders-header {
                h3 {
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
    }

    .list-content-wrap {
        padding: 20px;

        &.order-details {
            padding-top: 0;
            padding-left: 0;
        }
    }

    .list-filters-wrap {
        display: flex;
        align-items: center;
    }

    .each-filter {
        // border: 1px solid #C5C5C5;
        height: 40px;
        border-radius: 5px;
        min-width: 27%;
        // margin-right: 12px;

        input,
        select {
            border: none;
            font-size: 12px;
        }

        &.for-search,
        &.filter-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px;

            button {
                background: none;
                border: none;
                width: 33px;
                height: 33px;
                padding: 0;
            }

            input,
            select {
                height: 38px;
                // width: 80%;
                position: relative;
                margin-left: 11px;
                outline: none;
                background: #ffffff;
                padding: 3px 15px;
                border-radius: 5px;
                color: #BDBDBD;
            }

            input {
                min-width: 220px;
            }

        }

        .dropdown-menu {
            width: 100%;
            margin-top: 6px;
            margin-bottom: 6px;
            font-size: 12px;
        }
    }

    .list-contanier {
        margin-top: 20px;

        &.order-info {
            margin-top: 0;
        }

    }

    .list-title {
        font-weight: 300;
        font-size: 24px;
        margin-bottom: 9px;
    }

    .date-range {
        display: flex;
        height: 100%;

        .each-date:first-of-type {
            border-right: 1px solid #C5C5C5;
        }
    }

}
.toolbar-wrapper-class{
    background: rgba(216, 216, 216, 0.3);
    min-height: 200px;
    .rdw-editor-main{
        padding: 20px;
    }
}

.product-info-items {
    display: flex;

    .product-img {
        flex-basis: 30%;

        img {
            width: 100%;
            border-radius: 3px;
            border: 1px solid #f2f2f2;
        }
    }
}

.all-items-list {
    thead {
        background: none !important;
        border: none;

        tr {
            // background: #F3F3F3 !important;
        }

        th {
            padding: 0;
            border: none;
            background: none !important;
            height: 40px;
            color: #9B9B9B;
            text-transform: capitalize;
            border-bottom: none;

            &:not(:first-of-type),
            &:not(:last-of-type) {
                background: #F3F3F3 !important;
                border-bottom: none;
            }
        }

        th:first-of-type {
            background: none !important;

            span {
                background: #F3F3F3;
                padding: 20px 10px;
                display: block;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

        th:last-of-type {
            background: none !important;

            span {
                background: #F3F3F3;
                padding: 20px 10px;
                // height: 100%;
                display: block;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                padding-left: 0;
                height: 100%;
            }
        }

    }

    .table>:not(caption)>*>* {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    tbody {
        .product-info {
            display: flex;

            img {
                width: 30px;
                height: 30px;
                border-radius: 5px;
                background: #F3F3F3;
                content: "...";
            }
        }

        tr {
            cursor: pointer;

            &:hover {
                &>* {
                    background: #fafafa !important;
                    --bs-table-accent-bg: unset;
                }
            }

            td:last-of-type {
                padding-right: 0;
            }

            td:first-of-type {
                padding-left: 10px;
            }
        }
    }

    .prod-name {
        color: #4D4F5C;
        font-size: 12px;
        margin-left: 10px;

        span {
            display: block;
            color: #9B9B9B;
            cursor: pointer;
            font-size: 10px;
        }
    }

    td>.stock {
        // background: #B2F6D6;
        // border-radius: 5px;
        // padding: 3px 10px;
        font-size: 10px;
    }

    td>.unit-price {
        color: #4D4F5C;
        font-size: 10px;
    }

    td>.cate {
        font-size: 10px;
        color: #4D4F5C;
        background: #F1F1F3;
        border-radius: 16px;
        padding: 3px 10px;
        margin-right: 2px;
        margin-bottom: 4px;
        display: inline-block;
    }

    .sales {
        background: #D8D8D8;
        border-radius: 14px;
        padding: 3px 10px;
        text-align: center;
        width: 60%;
        color: rgba(0, 0, 0, 0.5);

        &.on {
            background: #B2F6D6;
        }
    }

    .sales-rate {
        opacity: 0.5;
        font-size: 10px;
        font-style: italic;
        text-align: center;
        width: 60%;
    }

    .date-item {
        color: #4D4F5C;
        font-size: 12px;
    }

    // button {
    //     border: 1px solid $brand-color1;
    //     font-size: 12px;
    //     padding: 3px 15px;
    //     margin-right: 10px;
    //     font-weight: 300;
    //     background: none;
    //     border-radius: 5px;
    // }


}

.product-actions {
    display: flex;
    align-items: center;

    >div {
        display: flex;
    }

    .action-cta {
        background: none !important;
        border: none !important;
        padding: 0;

        img {
            width: 20px;
        }

        &.dropdown-toggle::after {
            display: none;
        }
    }

    .dropdown-item {
        font-size: 12px;
    }

    img.tick_img {
        width: 16px;
        position: relative;
        bottom: 2px;
        left: 2px;
    }

    img.un_tick_img {
        width: 16px;
        position: relative;
        bottom: 2.5px;
        // left: 2px;
    }

    @media screen and (max-width: 1024px) {
        justify-content: right;

        .is-active {
            margin-right: 10px;
        }
    }
}

.all-items {
    margin-top: 5px;

    // @media screen and (max-width: 1024px) {
    //     position: relative;
    //     margin-left: -1.5%;
    //     margin-right: -4%;
    // }

    .items-heading {
        font-size: 17px;
        color: #4D4F5C;
        margin-left: 18px;
        margin-bottom: 9px;
    }
}

.is-active {
    span {
        color: #9B9B9B;
        position: relative;
        bottom: 7px;
        left: 5px;
    }

    .react-switch-bg {

        svg {
            display: none;
        }
    }
}

.pagination-wrap {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;

    // width: 80;
    &.to-right {
        justify-content: flex-end;
    }

    .previous,
    .next {
        list-style: none;
        list-style-type: none;
    }

    .each-page {
        background: #fff;
        margin: 0 4px;
        padding: 8px 13px;
        font-size: 9px;
        border: 1px solid #E8E9EC;
        border-radius: 4px;
        font-weight: 300;
        transition: .1s ease;
        cursor: pointer;
        list-style: none;
        list-style-type: none;

        &.active,
        &:hover {
            background: $brand-color1 !important;
            color: #ffffff;
            border: none;
        }

        img {
            width: 7px;
        }

        &.prev-page,
        &.next-page {
            &:hover {
                background: #fff !important;
            }
        }
    }
}

.sub-headings {
    display: flex;
    margin-bottom: 20px;
    // border-bottom: 0.7px solid #F1F1F3;

    .each-menu {

        a {
            color: #212121;
            font-weight: 300;
            font-size: 13px;
            padding: 10px;
            display: block;
            margin-right: 10px;

            // &.active-nav,
            // &:hover {
            //     border-bottom: 2px solid #00A85B;
            // }
            .underline {
                display: none;
            }

            &.active-nav,
            &:hover {
                color: $brand-green-light;
                font-weight: 500;

                .underline {
                    display: block;
                    width: 70%;
                    background: $brand-green-light;
                    height: 2px;
                    margin: 5px auto 0;
                }
            }
        }

    }

    @media screen and (max-width: 768px) {
        background: #ffffff;
        padding: 0 10px;

        .each-menu a {
            color: #212121;
            font-weight: 300;
            font-size: 12px;
            padding: 9px 5px;
            display: block;
            margin-right: 10px;

            &.active-nav,
            &:hover {
                border-bottom: 2px solid #00A85B;
                color: #00A85B;
                font-weight: 400;
            }
        }
    }
}

// }

.reason_wrap,
.address_modal_wrap {
    .modal-content {
        border-radius: 20px;
        border: none;
    }

    .modal-dialog {
        max-width: 400px;
    }

    .modal-header {
        border-bottom: none;
        justify-content: center;
        padding: 28px 40px 10px;
        align-items: center;
        font-size: 19px;
    }

    .reason_heading {
        color: #212121;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }

    .modal-body {
        padding-bottom: 14px;
        padding-top: 0;
        width: 85%;
        margin: 0 auto;
    }

    .reason_help_txt {
        font-size: 12px;
        border-radius: 14px;
        border: 1px solid #C5C5C5;
        padding: 10px;
        margin: 11px 0 13px;
        color: #9B9B9B;
        font-weight: 300;
    }

    .each_reason {
        padding: 7px 0;
        font-weight: 300;
        font-size: 13px;

        img {
            width: 16px;
            margin-right: 8px;
        }

        label {
            cursor: pointer;
            margin-left: 6px;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .reason_help_head {
        font-weight: 300;
        margin-top: 13px;
    }

    .isopened {
        margin-top: 13px;
        font-weight: 300;
    }



    .reason_options {
        margin-top: 15px;
    }

    .reason_cta_wrap {
        display: flex;
    }


    .customer-info {
        display: flex;
    }

    .customer-name {
        font-weight: 300;
        font-size: 19px;
    }

    .customer-email {
        font-weight: 300;
        font-size: 13px;
        margin: 0 0 10px;
    }

    .customer-details {
        margin-left: 17px;
    }

    .customer-avatar img {
        width: 90px;
    }

    .call-cta {

        margin-top: 8px;

        button {
            display: flex;
            background: transparent;
            color: #707070;
            font-weight: 300;
            font-size: 12px;
            border: 1px solid #C5C5C5;
            border-radius: 20px;
            padding: 8px 13px;
            min-width: 165px;
            justify-content: space-evenly;

            span {
                display: block;
            }
        }

        img {
            margin-right: 7px;
        }
    }

    .customer-address {
        border-top: 1px solid #D8D8D8;
        margin-top: 20px;
        padding-top: 20px;
        font-size: 14px;
        font-weight: 300;
        color: #757575;

        h3 {
            font-size: 19px;
            font-weight: 300;
            color: #212121;
            margin-bottom: 6px !important;
        }
    }

    .inbox-cta {
        button {
            display: flex;
            background: $brand-color1;
            color: #ffffff;
            font-weight: 300;
            font-size: 12px;
            border: none;
            border-radius: 20px;
            padding: 8px 13px;
            min-width: 165px;
            justify-content: space-evenly;

            span {
                display: block;
            }
        }

        img {
            margin-right: 7px;
        }
    }

    .btn_reason_save {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #00A759;
        background: none;
        color: #757575;
        font-size: 13px;
        font-weight: 300;
        padding: 10px 35px;
    }

}

.product-form .form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 5px;

    @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 767px) {
        display: block;

        .right-product-form {
            width: unset;
        }
    }

}

.product-cta,
.update-store-cta {
    text-align: center;
    margin: 25px 0;

    button {
        width: 100%;
        height: 48px;
    }

    button,
    button:hover,
    button:active,
    button:focus {
        background: $brand-color1;
        background-color: $brand-color1;
        color: #ffffff;
        font-size: 14px;
        padding: 7px 20px;
        font-weight: 400;
        border-radius: 5px;

        &.btn:disabled {
            color: #ffffff;
            background-color: $brand-color1 !important;
            opacity: 0.7 !important;
            border: none;
        }
    }
}

.update-store-cta {
    width: 30%;
    margin: 0 auto;
}

.info_data_section {
    .update-store-cta {
        width: 100%;
        display: flex;
        justify-content: end;

        button {
            flex-basis: 30%;
        }
    }
}

.product-info {
    flex-basis: 50%;

    .help-text {
        font-size: 10px;
        line-height: 1;
        display: inline-block;
        color: #757575;
    }

    .clear-selected-button {
        transform: scale(0.7);
    }

    .form-group {
        margin-bottom: 15px;

        label {
            font-size: 13px;
            color: #605E5E;
            font-weight: 300;
            display: flex;
            justify-content: space-between;
        }
    }

    .input-wrap {
        height: 48px;
        font-size: 12px;
    }

    textarea {
        height: 100px !important;
        border-radius: 27px;
        resize: none;
        padding: 18px;
    }

    .taxes-head {
        background: #F1F1F1;
        color: #767676;
        font-size: 12px;
        padding: 9px;
        border-radius: 5px;
        margin-bottom: 13px;
    }

    .two-sided {
        display: flex;
        justify-content: space-between;

        .form-group {
            width: 48%;
        }

        @media screen and (max-width: 768px) {
            display: block;

            .form-group {
                width: 100%;
            }
        }

        &.for_pricing {
            @media screen and (max-width: 1024px) {
                display: flex;

                .pricing_wrap {
                    flex-basis: 40%;
                }

                .qty_only_wrap {
                    flex-basis: 55%;
                }
            }
        }
    }

    .discount-wrap {
        margin-top: 20px;

        @media screen and (max-width: 1024px) {
            .two-sided {
                display: flex !important;

                .form-group {
                    flex-basis: 48%;

                    input {
                        height: 48px;
                        background: #F3F3F3;
                        border: none;
                        outline: none;
                    }
                }
            }

            .react-datepicker-wrapper {
                width: 100%;
            }

            .react-datepicker__input-container {
                .react-datepicker__calendar-icon {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 1px;
                    opacity: 0.2;
                    width: 8%;
                }

                input {
                    width: 100%;
                    border-radius: 5px;
                    font-size: 12px;
                    padding-left: 10px;
                }
            }
        }
    }

    .add-discount-wrap {
        display: flex;
        align-items: center;

        label {
            font-size: 13px;
            font-weight: 400;
            margin-left: 3px;
            cursor: pointer;
        }
    }

    .panel-input {
        margin-bottom: 15px;

        .input-title {
            background: #F1F1F1;
            padding: 14px 30px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            font-size: 13px;
            color: #BDBDBD;
            display: flex;
            justify-content: space-between;
            font-weight: 300;

            &.forstocks {
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
            }

            img {
                width: 10px;
            }
        }

        .category-lists,
        .status-lists {
            display: flex;
            flex-wrap: wrap;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border: 0.5px solid #ACACAC;
            border-top: none;
            padding: 20px 30px;

            &.forstocks {
                display: block;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

            }
        }

        .category-lists {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .each-cat,
        .each-status {
            margin-right: 20px;
            display: flex;
            // align-items: center;
            margin-bottom: 6px;

            label:nth-of-type(2) {
                margin-left: 24px;
                font-size: 13px;
                font-weight: 300;
                color: #818181;
            }
        }
    }

    .qty-input {
        display: flex;
        align-items: center;
        border-radius: 5px;
        height: 48px;
        border: 1.2px solid #BDBDBD;
        padding: 10px 0;
        font-size: 13px;
        overflow: hidden;

        &.invalid-input {
            border: 1.2px solid #f04438 !important;
            background: #fef3f2;
        }

        select,
        input {
            height: 100%;
            // height: 38px;
            border: none;
            outline: none;
            color: #818181;
            background: none;
        }

        select {
            flex-basis: 30%;
            border-radius: 5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        input {
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: 100%;
            padding: 0 10px;
        }

        .input-item {
            border-right: 1.2px solid #BDBDBD !important;
            height: 100%;
            display: flex;
            align-items: center;
            flex-basis: 70%;
        }
    }
}

.product-images-container {


    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media screen and (max-width: 1024px) {
        display: block;
        margin-bottom: 50px;
    }
}

.right-product-form {
    width: 47%;
    flex-basis: 47%;

    .uploads-msg {
        font-size: 11px;
        margin: 10px 0 20px;
        color: #757575;
    }

    .add-discount-wrap {
        display: flex;
        align-items: center;

        label {
            font-size: 13px;
            font-weight: 400;
            margin-left: 3px;
            cursor: pointer;
        }
    }

    .discount-wrap {
        margin-top: 20px;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        .react-datepicker__calendar-icon {
            top: 50%;
            transform: translateY(-50%);
            right: 1px;
            opacity: 0.2;
            width: 8%;
        }

        input {
            width: 100%;
            border-radius: 5px;
            font-size: 12px;
            padding-left: 10px;
        }
    }

    .two-sided {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .form-group {
            width: 48%;

            label {
                font-size: 13px;
                color: #605E5E;
                font-weight: 300;
                display: flex;
                justify-content: space-between;
            }

            input {
                height: 48px;
                background: #F3F3F3;
                border: none;
                outline: none;
            }

            input.input-wrap.is-invalid-input,
            input.input-wrap.is-invalid-input,
            select.input-wrap.is-invalid-input,
            textarea.input-wrap.is-invalid-input,
            .is-invalid-input,
            .invalid-input-wrap .react-datepicker__input-container input {
                background: #fef3f2;
                border: 1px solid;
                border-color: #fda29b !important;
            }
        }

        @media screen and (max-width: 1024px) {
            display: block;

            .form-group {
                width: 100%;
            }
        }


    }
}

.image-control {
    flex-basis: 70%;

    &.full {
        flex-basis: 100%;
    }
}

.images-label {
    font-size: 13px;
    color: #605E5E;
    font-weight: 300;
    height: 20px;
}

.product-images {

    text-align: center;
    border-radius: 5px;
    // border: 1px dashed $brand-color1;
    padding: 37px 0;
    cursor: pointer;


    height: 220px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
    // background: rgba(230, 254, 241, 0.3);
    // background: rgba(0, 168, 91, 0.1) !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2300A759FF' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
    position: relative;
    z-index: 2;

    &::after {
        content: "";
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        background: rgba(230, 254, 241, 0.3);
        z-index: 3;
        border-radius: 5px;
    }

    &:hover {
        opacity: 0.9;
    }

    img {
        width: 40%;
        max-width: 50px;
    }

    .upload-txt {
        color: #000;
        font-size: 13px;
        margin: 0 auto;
        margin-top: 15px;
        width: 60%;
        font-weight: 400;

        span {
            font-weight: 500;
            color: $brand-green-light;
        }
    }

    @media screen and (max-width: 1024) {
        border: 1px dashed #00A85B;
        background: #F1F1F1;
        //         .upload-txt{
        // font-size: 12px;
        //         }
    }
}

.upload_images_wrap {
    .modal-dialog {
        max-width: 65%;

    }

    .modal-header {
        border-bottom: none;
        font-size: 18px;
        width: 100%;
    }

    .modal-body {
        width: 100%;
    }

    .modal-content {
        border: none;
        border-radius: 5px;
    }

    .btn-close {
        font-size: 13px;
        opacity: 1;
    }

    .other_productinfo_tabs {
        border-bottom: 1px solid #F1F1F3;
        // .dzu-submitButtonContainer{
        //     display: none;
        // }

    }

    .nav-item {
        margin-right: 10px;
    }

    .nav-item a {
        font-weight: 300;
        color: #212121;
        font-size: 13px;
        padding-left: 3px;
        padding-right: 3px;
    }

    .nav-item a.active,
    .nav-item a:hover {
        border-bottom: 2px solid $brand-color1;
    }

    .upload_img_wrap {
        border: 1px dashed $brand-color1;
        border-radius: 20px;
        text-align: center;
        position: relative;
        width: 65%;
        margin: 10px auto 35px;
        min-height: 200px;

        display: flex;
        // align-items: center;
        justify-content: center;

        &.disabled_wrap {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.2;
        }

        .dzu-submitButton {
            background-color: unset;
            font-weight: 400;
            border-radius: 20px;
            border: 1px solid #00A85B;
            color: #00A85B;
        }

        &.forstore {
            width: 100%;
            flex-wrap: wrap;
            padding: 40px 0;

            >div {
                flex-basis: 100%;
            }

            .filename {
                text-align: left;
            }
        }

        .upload_cnt {
            width: 100%;
            // height: 100%;
            // .upload_msg{

            // }
        }

        .dzu-dropzone {
            position: absolute;
            top: 0;
            bottom: 0;
            border: none
        }

        img {
            width: 10%;
        }

        .upload-txt {
            font-size: 14px;
            color: #9B9B9B;
            margin: 8px 0;
        }

        .or-txt {
            font-size: 12px;
            color: #9B9B9B;
        }

        .upload-cta {
            display: inline-block;
            margin: 0 auto;
            background: none;
            border: 1.5px solid $brand-color1;
            font-size: 12px;
            font-weight: 400;
            color: #212121;
            border-radius: 20px;
            padding: 5px 10px;
            margin-top: 10px;
        }

        .modal-content {
            border-radius: 20px;
            border: none;
        }

    }

    .progress-indicator {
        margin-top: 7px;

        .filename {
            color: #bdbdbd;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 88%;
        }

        .sizetxt,
        .percent-txt {
            color: #BDBDBD;
            font-size: 12px;
        }

        .uploaded {
            font-size: 12px;
            color: #BDBDBD;
            display: flex;
            justify-content: space-between;
            width: 65%;
            margin: 10px auto 45px;
        }

        .uploading {
            display: flex;
            width: 65%;
            margin: 0 auto;
            align-items: center;
            justify-content: center;
        }

        .file-progress-wrap {
            // flex-basis: 67%;
            width: 300px;
            background: #F4F6F7;
            margin-right: 6px;
            padding: 2px 4px;
        }

        .file-progress {
            height: 4px;
            background: #BDBDBD;
            border-radius: 4px;
            margin: 5px 0;
        }

        .percent-width {
            height: 4px;
            background: $brand-color1;
            width: 80%;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .size-percent {
            display: flex;
            justify-content: space-between;
        }

        .status-icons {
            img {
                width: 100%;
            }
        }

        .size-msg {
            img {
                width: 15px;
                margin-left: 4px;
            }
        }
    }

    .modal-footer {
        box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
        width: 100%;

        button {
            background: $brand-color1;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            padding: 8px 17px;
            border: none;
            border-radius: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        .modal-dialog {
            max-width: 100%;
        }

        .upload_img_wrap {
            width: 100%;
        }

        // .modal-content {
        //     border: none;
        //     border-radius: 24px;
        // }
    }
}

.content-head {
    color: #757575;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.day-month-wrap {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding-left: 2px;
    color: #9B9B9B;
    font-size: 12px;
    height: 48px;

    .day-select {
        height: unset;
        flex-basis: 20%;
    }

    .add-hours {
        background: $brand-color1;
        color: #ffffff;
        display: flex;
        align-items: center;
        flex-basis: 30%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        &:hover {
            background: darken($brand-color1, 4%);
        }
    }

    .month-select {
        border-left: 1px solid #D8D8D8;
        flex-basis: 25%;
        padding-left: 5px;

        label {
            display: block;
        }
    }

    >div {
        height: 100%;
    }

    select,
    input {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        color: #9B9B9B !important;
    }
}

.days-list {
    border-radius: 30px;
    border: 1px solid #BDBDBD;
    padding: 20px 15px;
    margin-top: 20px;

    .each-day {
        display: flex;
        align-items: center;
    }

    .day-clock {
        flex-basis: 50%;
        font-size: 11px;
        color: #212121;

        span {
            margin-left: 5px;
        }

        img {
            width: 13px;
        }
    }

    .time-choice {
        font-size: 11px;
        color: #212121;
        flex-basis: 30%;
    }

    .remove-cta {
        flex-basis: 20%;
        text-align: right;
        cursor: pointer;

        &:hover img {
            opacity: 0.6;
        }
    }

    .remove-cta {
        img {
            width: 18px;
        }
    }
}

.other-wrap {
    padding: 30px 0 40px;
    border-top: 1px solid #F2F3F4;
}

.search-gallery {
    margin-top: 20px;

    .each-filter {
        border: 1px solid #C5C5C5;
        height: 40px;
        border-radius: 5px;
        // min-width: 27%;
        margin-right: 12px;


        &.for-search {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px;

            button {
                background: none;
                border: none;
                width: 33px;
                height: 33px;
                padding: 0;
            }

            input {
                border: none;
                font-size: 12px;
                height: 33px;
                width: 80%;
                position: relative;
                margin-left: 11px;
                outline: none;
            }

        }


    }

    .filter-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px;
        flex-basis: 40%;

        button {
            background: none;
            border: none;
            width: 33px;
            height: 33px;
            padding: 0;
        }

        input {
            border: none;
            font-size: 12px;
            height: 33px;
            width: 80%;
            position: relative;
            margin-left: 11px;
            outline: none;
        }

        &>div>div,
        &>div>div:hover {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none !important;
            outline: none !important;
            background: #fff;
        }

        &>div>div:nth-of-type(2) {
            border-color: hsl(0, 0%, 80%) !important;
            background: #fff;

            >div {
                border: 1px solid #ccc;
                background: #fff;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 150%;
                font-size: 12px;

                >div {
                    flex-basis: 48%;
                }
            }

            >div>div {
                display: flex;
                flex-wrap: wrap;
            }


        }

        .css-26l3qy-menu {
            width: 125%;
            background: #ffffff;

            .css-9gakcf-option {
                background: none;
                color: #00A759;
            }
        }

        .select-wrap {
            min-width: 50%;

            >div:first-of-type {
                border-radius: 5px;
                height: 33px;
                text-align: center;

                >div:nth-of-type(2) {
                    display: none;
                }

                >div:first-of-type {
                    >div:first-of-type {
                        font-size: 13px;
                        font-weight: 500;
                    }

                    >div:nth-of-type(2) {
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }

        .css-26l3qy-menu>div {
            width: 100%;
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: space-between !important;

            >div {
                cursor: pointer;
                background: none !important;

                &:hover {
                    color: #00A759;
                    font-weight: 500;
                }
            }
        }
    }

    .gallery-head {
        margin: 25px 0;

        h3 {
            font-size: 17px;
        }

        .gallery-txt {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .filter-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        label {
            font-size: 13px;
            color: #BDBDBD;
            font-weight: 300;
            margin-right: 8px;
        }

        .filter-options input {
            width: 100px;
        }

        .dropdown-menu {
            left: 0 !important;
            right: -80% !important;
            display: flex;
            flex-wrap: wrap;

            a {
                width: 48%;
                font-size: 12px;
                color: #9B9B9B;

                &:first-of-type {
                    color: $brand-color1;
                }
            }
        }
    }

    .photos-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 300px;
        overflow: auto;
    }

    .each-image {
        flex-basis: 23%;
        margin-bottom: 2.5%;
        cursor: pointer;
        transition: .2s ease;
        height: 150px;
        background-size: 98%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 2%;

        &:hover {
            // border: 1px solid #9B9B9B;
            // padding: 10px;
            // border-radius: 10px;
            opacity: 0.6;
        }

        img {
            width: 100% !important;
            height: 100%;

            display: inline-block;
            font-family: Arial, sans-serif;
            font-weight: 300;
            line-height: 2;
            text-align: center;
            position: relative;
            background-color: #F8F9F9;
            object-fit: cover;
            object-position: center;

            &::before {
                content: '';
                width: 100%;
                // height: calc(100% + 10px);
                height: 100%;
                background-color: #F8F9F9;
                // border-radius: 10px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &::after {
                content: "Unable to Load";

                font-size: 12px;
                color: rgb(100, 100, 100);

                // display: block;
                position: absolute;
                z-index: 2;
                top: 50%;
                vertical-align: middle;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                cursor: not-allowed;
                pointer-events: none;
                // height: 100%;
            }
        }

        &.chosen-image {
            position: relative;

            &::after {
                content: '';
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 1;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
            }

            &::before {
                z-index: 1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 45px;
                height: 45px;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm5.54,7.032c-.04.036-.08.068-.108.1q-3.348,3.354-6.7,6.7c-.036.036-.072.068-.1.1-.016.016-.032.028-.048.044l-.132.12-.136-.116a.28.28,0,0,0-.04-.032c-.028-.024-.056-.048-.084-.076l-1-.992c-.952-.952-1.94-1.94-2.908-2.9a.35.35,0,0,1-.12-.256A.359.359,0,0,1,4.3,9.48a1.661,1.661,0,0,0,.132-.144.692.692,0,0,1,.432-.3c.224-.02.372.168.492.32a1.277,1.277,0,0,0,.112.128c.868.864,1.944,1.936,2.968,2.972l.012.012s.012-.008.016-.016c1.04-1.044,2.1-2.1,3.124-3.128,1.064-1.064,2.164-2.168,3.248-3.252a.446.446,0,0,1,.332-.164.436.436,0,0,1,.292.176,1.2,1.2,0,0,0,.124.12.556.556,0,0,1,.256.4C15.836,6.784,15.68,6.916,15.54,7.032Z' fill='%2300a85b'/%3E%3C/svg%3E") !important;
            }
        }

        @media screen and (max-width: 768px) {
            flex-basis: 48%;
            height: 140px;
        }
    }

    #scrollableDiv>div {
        height: 400px;
    }

    .infinite-scroll-component {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

}

.top-content {
    border-bottom: 1px solid #F1F1F3;
}

.setting-page {
    background: #ffffff;
    border-radius: 10px;

    .page-heading {
        padding: 40px 30px 10px;

        h3 {
            font-weight: 600;
            font-size: 23px;
        }
    }

    .page-menus {
        padding: 0 30px;
        // border-top: 1px solid #F1F1F3;
        // border-bottom: 1px solid #F1F1F3;
        margin-bottom: 20px;
        display: flex;

        a {
            color: #212121;
            font-weight: 300;
            font-size: 13px;
            padding: 15px 0;
            display: block;
            transition: .2s ease;
            margin-right: 30px;

            &:first-of-type {
                padding-left: 0;
            }

            &.active-nav,
            &:hover {
                border-bottom: 2px solid $brand-color1;
                color: $brand-color1;
                font-weight: 500;
            }
        }
    }

    .profile-form {
        .some-details {
            padding-bottom: 20px;
            width: 60%;
            margin: 0 auto;
        }

        .other-store-info,
        .some-details {
            width: 60%;
            margin: 0 auto 50px;
        }

        @media screen and (max-width: 767px) {
            .each_info_wrap {
                padding: 0 2%;
                display: block;
            }

            .update-store-cta {
                display: block;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    .two-sided {
        display: flex;
        justify-content: space-between;

        >div {
            flex-basis: 49%;
        }
    }

    .input-with-divider {
        >div:first-of-type {
            flex-basis: 25%;
        }

        >div:nth-of-type(2) {
            flex-basis: 75%;
        }
    }

    .input-wrap,
    .input-with-divider {
        height: 48px !important;
        padding-right: 0;
    }

    .input-wrap {
        background: rgba(216, 216, 216, 0.30);
        border-width: 0;
        color: #7A7A7A;
    }

    .data_label_txt {
        color: #605E5E;
        font-size: 16px;
        margin-bottom: 3px;
    }

    .input-with-divider input {
        height: 46px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .email-phone-ctas {

        button,
        button:hover {
            background: #FFE3E7;
            border: none;
            border-radius: 25px;
            font-weight: 300;
            font-size: 11px;
            padding: 5px 15px;
            margin-right: 15px;
        }
    }

    .submit-cta {
        text-align: right;
        padding: 20px 0 40px;

        button,
        button:hover,
        button:focus {
            background: transparent;
            border: 1px solid $brand-color1;
            font-size: 12px;
            font-weight: 300;
            border-radius: 25px;
            padding: 4px 30px;
        }
    }

    .light-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;

        h4 {
            color: #BDBDBD;
            font-weight: 300;
            font-size: 17px;
        }

        .btn {
            background: transparent;
            border: 1px solid $brand-color1;
            font-size: 12px;
            font-weight: 300;
            border-radius: 25px;
            padding: 7px 15px;

            &.other-btn {
                color: #D8D8D8;
                border: 1px solid #D8D8D8;
            }

            img {
                width: 13px;
                margin-right: 5px;
            }
        }
    }

    .staff-table,
    .payout-table {
        // padding-bottom: 50px;

        .tablecomponent-container .container {
            padding: 0;
        }

        table.table thead th {
            background: unset;
            padding-left: 0;
            text-transform: capitalize;

            &:first-of-type {
                padding-left: 30px;
            }
        }

        .table tr td:first-of-type {
            padding-left: 30px;
        }

        .table td {
            border-bottom: none;
            color: #707070;
            font-weight: 300;

            .cta-txt {
                color: $brand-color1;
                display: block;

                img {
                    width: 10px;
                    margin-right: 5px;
                }
            }
        }

    }

    .payout-table {
        padding: 0 30px;
        padding-bottom: 20px;

        @media screen and (max-width: 767px) {
            padding: 0 0 20px;
        }

        .mobile-data-grid {
            .main-txt-head {
                display: block;
            }

            &.for_details {
                .each_data_item {
                    background: #fff;
                    // border-bottom: 1px solid #BDBDBD;
                    padding: 15px 2%;
                    // margin-bottom: 15px;
                    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    .price_txt {
                        color: #00A759;
                        font-weight: 500;
                    }
                }

                .order_id_action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 10px;

                    img {
                        width: 20px;
                    }

                    .id_txt {
                        font-weight: 500;
                        font-size: 13px;

                        .date_item {
                            color: #667085;
                            font-size: 11px;
                            font-weight: 400;
                        }
                    }
                }

                .each_mobile_prod_info {

                    // border-radius: 5px;
                    padding: 10px 7px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px;

                    &:nth-of-type(2n-2) {
                        background: rgba(157, 180, 192, 0.1);
                    }

                    .title-txt {
                        color: #312E2E;
                    }

                    .main_item {
                        font-weight: 500;
                    }

                    .measure {
                        text-transform: lowercase;
                    }
                }

                @media screen and (max-width: 1024px) {
                    .main-txt-head {
                        display: flex;
                    }

                    .other_action {
                        text-align: right;
                    }
                }
            }

            &.for_orders {
                .id_txt {
                    font-weight: 500;
                    font-size: 13px;
                }

                .order_id_action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .order_action {
                    font-size: 12px;
                    font-weight: 500;

                    &.confirm {
                        color: #697A02;
                    }

                    &.got_to {
                        color: #00A759;
                    }
                }
            }
        }

        .get-invoice {
            display: flex;
            color: $brand-green-light;
            align-items: center;
            font-weight: 500;

            img {
                width: 20px;
                margin-right: 5px;
            }
        }

        .table td {

            color: #707070;
            font-weight: 400;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        .table tr td:first-of-type {
            // padding: 0;
            // border-bottom: none;

            padding-left: 15px !important;
            // span{
            //     display: block;
            //     padding: 18px 15px 18px 0;
            //     padding-left: 15px !important;
            //     border-bottom: 1px solid;
            //     // min-height: 62.5px;
            // }
        }

        .txt-status {
            display: flex;
            border-radius: 16px;
            display: inline-block;
            padding: 4px 13px;
            font-weight: 500;
            font-size: 12px;

            span.status-tick {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: #12B76A;
                border-radius: 100%;
                margin-right: 4px;
                position: relative;
                bottom: 1px;
            }

            &.success {
                color: #027A48;
                background: #ECFDF3;

                span.status-tick {
                    background: #12B76A;
                }
            }


        }

        th {
            padding: 0;
            border: none;
            background: none !important;
            height: 40px;
            color: #312E2E;
            text-transform: capitalize;
            border-bottom: none;

            &:not(:first-of-type),
            &:not(:last-of-type) {
                background: #F3F3F3 !important;
                border-bottom: none;
            }
        }

        th:first-of-type {
            background: none !important;
            padding-left: 0 !important;

            span {
                background: #F3F3F3;
                padding: 20px 10px;
                display: block;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

        th:last-of-type {
            // background: none !important;

            span {
                background: #F3F3F3;
                padding: 20px 10px;
                display: block;
                display: block;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 100%;
            }
        }
    }

    &.deletepage {
        width: 40%;

        .business-info {
            padding: 20px;
            text-align: center;
            border-bottom: 1px solid #F1F1F3;
        }

        .send-head {
            color: #BDBDBD;
            text-align: center;
            font-weight: 300;
            font-size: 20px;
            line-height: 1;
            margin-bottom: 18px;
        }

        .business-name {
            font-weight: 300;
            font-size: 16px;
        }

        .sub-info {
            color: #9B9B9B;
            font-size: 12px;
            line-height: 1;
        }

        .confirm {
            text-align: center;
            font-size: 18px;
            color: #757575;
            font-weight: 400;
            margin-top: 10px;
        }

        .confirm-txt {
            text-align: center;
            color: #757575;
            font-weight: 300;
            font-size: 12px;
            padding: 20px 30px 40px;
        }

        form {
            padding: 0 20px 50px;

            .btn,
            .btn:hover {
                background: $brand-color1;
                color: #ffffff;
                border-radius: 25px;
                width: 100%;
                height: 48px;
            }

            .form-group {
                margin-bottom: 20px;
            }
        }
    }

    .remove-btn {
        border: 1px solid $brand-color1;
        padding: 4px 9px;
        font-size: 11px;
        // color: #ffffff;
        border-radius: 25px;

        &.grayed {
            border: 1px solid #757575;
        }
    }

    .payout-options {
        position: relative;
        min-height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .existing-payout-setups {
        display: flex;
        align-items: stretch;
        padding: 0 30px;
        // flex-wrap: nowrap;
        // white-space: nowrap;
        // overflow-x: auto;
        position: absolute;

        @media screen and (max-width: 767px) {
            padding: 0;
        }

        .each-payout-setup {
            height: 180px;

            &:first-child {
                margin-left: auto;
            }

            &:last-child {
                margin-right: auto;
            }

            &.for-card {
                background: url('../../assets/card-withdraw.png') no-repeat center;
                background-size: cover;
                width: 320px;
                height: 180px;
                border-radius: 5px;
                padding: 20px 15px;
                color: #ffffff;
                position: relative;

                .db-card-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .card-num {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .card-brand {
                    img {
                        width: 40px;
                    }
                }

                .account-cta {
                    img {
                        width: 20px;
                    }

                    button {
                        background: none;
                        border: none;
                        outline: none;

                        &::after {
                            border: none;
                        }
                    }

                    .dropdown-item,
                    .dropdown-item.active,
                    .dropdown-item:active {
                        font-size: 12px;
                        background-color: #f8f9fa !important;
                    }
                }

                .other-card-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #ffffff;
                    position: absolute;
                    bottom: 20px;
                    width: 90%;

                    .title {
                        font-weight: 200;
                        font-size: 10px;
                    }

                    .info-detail {
                        font-weight: 300;
                        font-size: 12px;
                    }
                }
            }

            &.for-bank {
                display: flex;
                background: #FFFFFF;
                box-shadow: 0px 4px 14px rgba(90, 90, 90, 0.1);
                border-radius: 5px;
                height: 180px;
                width: 290px;
                border-radius: 5px;
                margin-left: 23px;

                .left {
                    flex-basis: 50%;
                    padding: 20px 15px;
                    position: relative;
                }

                .top-txt {
                    font-size: 10px;
                    font-weight: 600;
                    color: $brand-color1;
                    margin-top: 7px;
                }

                .left-middle {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 13px;
                }

                .left-top {
                    img {
                        width: 20px;
                        cursor: pointer;
                    }
                }

                .left-bottom {
                    position: absolute;
                    bottom: 20px;
                    width: 90%;

                    .title-txt {
                        color: #757575;
                        font-weight: 200;
                        font-size: 10px;
                    }

                    .type-txt {
                        color: #312E2E;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    .pending-cta {
                        color: $brand-green-light;
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 500;

                        &:hover {
                            text-decoration: underline;
                        }

                        &.ongoing {
                            color: #E59866;
                            font-size: 12px;
                        }

                        &.with-error {
                            color: #EC3237;
                            font-size: 12px;
                        }
                    }
                }


                .right {
                    background: url('../../assets/bank-withdraw.png') no-repeat center;
                    background-size: cover;
                    flex-basis: 50%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    padding: 20px 15px;

                    .account-cta {
                        text-align: right;
                        display: inline-block;
                        float: right;
                        cursor: pointer;

                        img {
                            width: 20px;
                        }
                    }
                }
            }

            &.add-withwrawal-cta {
                // flex-basis: 27%;
                // margin-left: 3%;
                text-align: center;
                color: $brand-color1;
                background: rgba(230, 254, 241, 0.4);
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2300A759FF' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
                border-radius: 5px;
                // border: 1px dashed #00A85B;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                margin-left: 20px;
                cursor: pointer;

                img {
                    width: 25px;
                }

                .withdrawal-txt {
                    font-size: 15px;
                    font-weight: 500;
                    flex-basis: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        background: unset !important;
        border-radius: unset !important;

        .page-menus {
            padding: 0 10px;
            border-top: none;
            border-bottom: 1px solid #F1F1F3;
            margin-bottom: 20px;
            background: #fff;
            // justify-content: space-between;

            a {
                padding: 9px 5px;
                font-size: 11px;
                margin-right: 0;

                &.active-nav {
                    border-bottom: 2px solid #00A85B;
                    color: #00A85B;
                    font-weight: 400;

                }
            }
        }

        .profile-form {
            .some-details {
                width: 100%;
            }

            .other-store-info {
                width: 100%;
            }
        }

        .delivery_switch {
            width: 100%;
        }

        .day-month-wrap {
            display: block;
            height: unset;
            padding: 10px;
            border-radius: unset;

            .add-hours {
                border-radius: 5px !important;
                padding: 10px;
            }

            .day-select {
                border-left: unset;
                padding-left: 0;
                margin-bottom: 10px;

                select {
                    width: 100%;
                    height: 38px;
                }
            }

            .month-select {
                border-left: unset;
                padding-left: 0;
                margin-bottom: 10px;
            }
        }
    }

}

.payout-list-wrap {
    @media screen and (max-width: 767px) {
        background: #fff;
        padding: 10px 15px;
        border-radius: 5px;

        .availables.done {
            display: flex;
            border-radius: 16px;
            display: inline-block;
            padding: 4px 13px;
            font-weight: 500;
            font-size: 12px;
            color: #027A48;
            background: #ECFDF3;

            span {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: #12B76A;
                border-radius: 100%;
                margin-right: 4px;
                position: relative;
                bottom: 1px;
            }

            // &.success {
            //     color: #027A48;
            //     background: #ECFDF3;

            //     span.status-tick {
            //         background: #12B76A;
            //     }
            // }


        }
    }
}

.heading_payout_txtn {
    padding-bottom: 15px;

    .main {
        font-weight: 500;
        font-size: 14px;
    }

    .other {
        font-size: 12px;
        color: #312E2E;
    }
}

.mt-20 {
    margin-top: 20px;
}


.invite_staff_wrap {
    .modal-dialog {
        width: 32%;

    }

    .modal-content {
        border-radius: 24px;
        border: none;
    }

    .business-info {
        text-align: center;
        margin: 20px 0 18px;
    }

    .send-head {
        color: #BDBDBD;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 18px;
    }

    .business-name {
        font-weight: 300;
        font-size: 16px;
    }

    .sub-info {
        color: #9B9B9B;
        font-size: 12px;
        line-height: 1;
    }

    .invite-cta {

        .btn,
        .btn:hover,
        .btn:focus {
            width: 100%;
            background: $brand-color1;
            border-radius: 5px;
            color: #ffffff;
            font-weight: 300;
            font-size: 17px;
            padding: 8px;
            margin: 15px 0 30px;
        }
    }
}

.payout_wrap,
.new_store_wrap {
    @media screen and (min-width: 1025px) {
        .modal-dialog {
            width: 35%;
        }
    }


    .modal-header {
        font-weight: 500;
        border-bottom: none;
        padding: 20px 22px 0 22px;
    }

    .heading-help {
        font-size: 12px;
        color: #757575;
        margin-bottom: 20px;
    }

    .btn-close {
        font-size: 11px;
    }

    .acc_details_tabs {
        .nav-link {
            color: #707070;
            font-size: 12px;

            .underline {
                display: none;
            }

            &.active {
                color: $brand-green-light;

                .underline {
                    display: block;
                    width: 70%;
                    background: $brand-green-light;
                    height: 2px;
                    margin: 5px auto 0;
                }
            }
        }
    }

    .modal-content {
        border-radius: 10px;
        border: none;
    }

    .new_store-form {
        label {
            font-size: 12px;
        }

        .url_header_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .show_urls {
                display: block;
                font-size: 13px;
                color: $brand-color1;
                cursor: pointer;
                margin-bottom: 6px;

                &:hover {
                    font-weight: 500;
                }
            }
        }

        .logo_url {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .link {
                font-size: 12px;
                font-weight: 500;
                color: $brand-color1;
                margin-bottom: 0;
                word-break: break-all;
            }

            .form-group {
                flex-basis: 80%;
            }

            .logo-icon {
                // width: 85px;
                // height: 85px;
                margin-right: 10px;

                label {
                    cursor: pointer;
                    width: 80px;
                    height: 80px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }

        }

        .loading_urls {
            font-size: 12px;
            text-align: center;
        }

        .form-group {
            padding: 6px 0;
        }

        input {
            font-weight: 300 !important;
            font-size: 12px !important;
        }

        .url_options {
            margin-bottom: 20px;
            background: #F7F9F9;
            padding: 10px;
            border-radius: 3px;

            .url_header {
                font-size: 14px;
                margin-bottom: 6px;
                font-weight: 500;
            }
        }

        .each-options {
            display: flex;
            align-items: center;
            margin-bottom: 3px;
            word-break: break-all;

            label {
                font-size: 12px;
                margin-left: 6px;
            }
        }

        @media screen and (max-width: 1024px) {
            .each-options {
                margin-bottom: 12px;

                label {
                    font-size: 10px;
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                    display: block;
                    width: 100%;
                    background: #ffffff;
                    padding: 5px 7px;
                    border-radius: 3px;

                    &.selected_url {
                        background: #00A759;
                        color: #ffffff;
                    }
                }

                .custom_radio {
                    width: 0;
                    height: 0;
                }
            }

            .logo_url {
                .link {
                    font-size: 10px;
                    margin-top: 3px;
                }
            }

            .url_options {
                .url_header {
                    font-size: 12px;
                }
            }
        }
    }

    .biz-cat {
        .dropdown-container {
            display: flex;
            align-items: center;

            box-shadow: none !important;
            z-index: 1000;
            border-radius: 5px !important;
            // border-radius: 35px !important;
            // height: 44px;
            // border: 1.2px solid #BDBDBD;
            // margin-top: 1rem;
            // margin-bottom: 1rem;
            font-size: 13px;
            // width: 100%;
            outline: none;
            width: 100%;
            height: 44px;
            outline: none;
            // border: none;
            border-radius: 5px;
            // background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            font-weight: $font-semiregular;
            padding-left: 0;
        }

        &.invalid-input-wrap .dropdown-container {
            background: #fef3f2 !important;
            border-color: #fda29b !important;
        }
    }

    #storeProvince {
        cursor: pointer;
        pointer-events: none;
    }

    .address-wrap {
        font-size: 13px !important;
    }

    .store-address-select__control--is-focused {
        box-shadow: unset !important;
        border-color: unset !important;
    }

    .biz-cat input[type=checkbox] {
        width: 16px;
        height: 16px;
    }

    .terms_txt {

        input[type=checkbox] {
            position: relative;
            bottom: -3px;
            margin-right: 5px;
        }

        label {
            font-size: 12px;

            a {
                color: $brand-green-light;
                font-weight: 500;
            }
        }
    }

    .two-sided {
        display: flex;
        justify-content: space-between;
        padding: 7px 0;

        .form-group {
            width: 48%;
        }

        label {
            font-size: 13px;
        }
    }

    .send-head {
        color: #BDBDBD;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 18px;
    }



}

.payout-cta {
    margin: 25px 0 10px;

    .btn,
    .btn:hover,
    .btn:focus {
        width: 100%;
        background: $brand-color1 !important;
        border-radius: 5px;
        color: #ffffff !important;
        font-weight: 500;
        font-size: 13px;
        padding: 8px;
        height: 40px;
        border: none !important;

        &.alt-btn {
            border: 1px solid $brand-color1 !important;
            background: none !important;
            color: $brand-color1 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 20px;
                margin-right: 5px;
            }
        }
    }

    &.sided {
        display: flex;
        justify-content: space-between;

        button {
            flex-basis: 48%;
        }
    }
}

.other-info {
    display: flex;
    margin-top: 10px;
}

.each-other-info {
    margin-right: 10px;

    .wrap {
        border: 1.5px solid $brand-color1;
        border-radius: 20px;
        font-size: 12px;
        color: #757575;
        padding: 4px 18px 4px 4px;
        display: flex;
        align-items: center;

        img {
            width: 15px;
            margin-right: 5px;
        }
    }

    &.alt .wrap {
        border-color: #D8D8D8;
    }

    .default-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 8px;

        >div {
            width: 10px;
            height: 10px;
            background: $brand-color1;
            border-radius: 100%;
            margin-right: 7px;

            &.alt {
                background: #BDBDBD;
            }
        }

    }
}

.notes {
    color: #707070;
    font-size: 13px;
    font-style: italic;
    margin-top: 10px;
}

.infinite-scroll-component {
    // flex-direction: unset !important;
    // justify-content: space-between;
}

.all-images-chosen {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    margin-top: 25px;

    flex-basis: 25%;
    max-height: 220px;
    overflow: auto;

    .product-image-wrap {
        height: 70px;
        flex-basis: 28%;
        // margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid #f2f2f2;
        border-radius: 3px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2300A759FF' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
        &:hover {
            .image-actions {
                display: block;
            }
        }

        .image-actions {
            position: absolute;
            height: 100%;
            width: 100%;
            display: none;
            z-index: 1;
        }

        .select-cover {
            position: absolute;
            top: 0;
            left: 6px;

            input[type="radio"] {
                width: 10px;
                -webkit-appearance: checkbox;
                /* Chrome, Safari, Opera */
                -moz-appearance: checkbox;
                /* Firefox */
                -ms-appearance: checkbox;
                /* not currently supported */
            }
        }

        .deleteicon {
            position: absolute;
            bottom: 0;
            right: 6px;

            img {
                width: 8px;
            }
        }

        .select-cover {
            position: absolute;
            top: 0;
            left: 6px;
        }
    }

    .product-image-item {
        width: 80%;
        height: 50px;
        // background-size: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // background-repeat: no-repeat;
        // background-position: center;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        overflow: auto;

        .product-image-wrap {
            margin-right: 10px;
            height: 50px;
            flex-basis: unset;
            flex-shrink: 0;
            width: 3em;
        }
    }
}



.checkbox-wrap {
    display: block;
    position: relative;
    // padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox-wrap:hover input~.checkbox_checkmark {
    // background-color: #ccc;
    border: 1px #00A85B solid;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrap input:checked~.checkbox_checkmark {
    background-color: #00A85B;
    border: 1px #00A85B solid;

}

/* Create the checkbox_checkmark/indicator (hidden when not checked) */
.checkbox_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkbox_checkmark when checked */
.checkbox-wrap input:checked~.checkbox_checkmark:after {
    display: block;
}

/* Style the checkbox_checkmark/indicator */
.checkbox-wrap .checkbox_checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
}

.btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

progress {
    background: #00A85B !important;
}

//   progress::-moz-progress-bar {
//     background: lightcolor;
//   }

progress::-webkit-progress-value {
    background: #00A85B !important;
}

//   progress::-webkit-progress-bar {
//     background: #00A85B !important;
//   }
.image-info-details {
    width: 65%;
    margin: 30px auto 0;

    .select-wrap {
        width: 100%;

        >div:first-of-type {
            border-radius: 5px;
            height: 48px;
            text-align: center;

            >div:nth-of-type(2) {
                display: none;
            }

            >div:first-of-type {
                >div:first-of-type {
                    font-size: 13px;
                    font-weight: 500;
                }

                >div:nth-of-type(2) {
                    position: absolute;
                    width: 100%;
                }
            }
        }
    }

    .select-cat-wrap {

        &>div>div,
        &>div>div:hover {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none !important;
            outline: none !important;
        }

        &>div>div:nth-of-type(2) {
            border-color: hsl(0, 0%, 80%) !important;

            >div {
                border: 1px solid #ccc;
                font-size: 12px;

                >div {
                    flex-basis: 48%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.status-icons {
    display: flex;
    align-items: center;
    // margin-left: 10px;
    margin-bottom: 2px;
}

.remove-now {
    opacity: 0.7;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.store_address {
    .react-select__value-container {
        height: 48px;
        width: 85% !important;

        &>div {
            width: 80% !important;
        }
    }

    .react-select__indicators {
        display: none;
    }

    .react-select__control {
        height: 100%;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
        // padding-left: 25px;
        // margin-top: 25px;
        font-size: 15px;
        background: transparent;

        // &:hover{
        //   border: 1px solid $brand-green-light !important;
        // }
        &--is-focused {
            border: none !important;
            box-shadow: 0 0 0 1px #BDBDBD !important;
        }
    }

    .react-select__input {
        width: 100% !important;
    }

    input {
        width: 100% !important;
    }
}

.view-order {

    a,
    a:hover,
    .confirm_cta {
        color: $brand-green-light;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none !important;
    }
}

.order-items-list {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 15px;

    .store_info {
        display: flex;
        align-items: flex-start;

        .store_logo {}

        .store_name {
            font-size: 14px;
            margin-left: 10px;

            .delivery_tip {
                font-size: 10px;
            }
        }
    }

    .all_products {
        margin-top: 20px;

    }

    .delivered_num {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .order_num {
            font-size: 11px;
            color: #757575;
        }

        .delivered {
            background: rgba(0, 168, 91, 0.2);
            border-radius: 6px;
            padding: 5px 10px;
            color: #2E2E2E;
            font-weight: 600;
            font-size: 13px;

        }
    }

    .each_product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #BDBDBD;
        padding: 15px 0;
        padding-left: 10px;
        margin-bottom: 15px;

        &:last-of-type {
            border-bottom: none;
        }

        .order_summary_left {
            flex-basis: 65%;
            display: flex;
            align-items: flex-start;

            .product_image {
                img {
                    width: 100px;
                }
            }
        }

        .order_summary_right {
            // flex-basis: 35%;
        }

        .product_info {
            margin-left: 10px;

            .name {
                color: #2E2E2E;
                font-weight: 600;
                font-size: 12px;
            }

            .order_count {
                color: #757575;
                font-size: 10px;
            }

            .amount_txt {
                font-weight: 600;
                font-size: 16px;
                margin-top: 15px;
            }
        }

        .add_cart {
            background: $brand-green-light;
            color: #ffffff;
            font-size: 12px;
            display: block;
            text-decoration: none;
            padding: 8px 15px;
            border-radius: 5px;
            border: none;
            width: 120px;
            height: 36px;
        }

        .return_cta {
            width: 120px;
            height: 36px;
            background: transparent;
            color: #BDBDBD;
            border: 1px solid #BDBDBD;
            font-size: 12px;
            display: block;
            padding: 8px 15px;
            border-radius: 5px;
            margin-top: 10px;
        }
    }
}

.info-txt {
    font-size: 12px;
    font-weight: 300;
    color: #212121;
}

.price-info-txt {
    font-size: 10px;
    font-weight: 300;
    color: #212121;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px 8px;
    cursor: move;
    border-radius: 2px;
}

div.ReactTags__selected .ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
    border: none;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.delivery_switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 75%;
    margin: 25px 0;

    .prompt {
        font-size: 15px;
        color: #757575;

        span {
            font-weight: 500;
            color: #00A85B;
        }
    }
}

.suggestionsClass {
    background: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

    ul {
        margin-left: 0;
        padding-left: 0;
    }

    li {
        list-style: none;
        padding: 7px 5px;
        cursor: pointer;
    }
}

.activeSuggestionClass {
    background: #F2F3F4;
}

.no-products {
    text-align: center;

    .empty-msg {
        margin-bottom: 20px;
        color: #AEB6BF;
        font-weight: 500;
    }

    .new-product-cta {
        border: 1px solid #00A759;
        background: #ffffff;
        font-size: 12px;
        border-radius: 25px;
        padding: 5px 18px;
        display: inline-block;
    }
}

.help-txt {
    font-size: 12px;
    color: #85929E;
    margin-left: 14px;
}

.products-list {
    &.list-content-wrap {
        padding: 0 0 20px;
    }

    &.all-products {
        padding: 20px;
    }

    &.incart {
        margin-top: 25px;

        th {
            color: rgba(49, 46, 46, 0.8);
            font-weight: 500;
            font-size: 13px;
        }
    }

    @media screen and (max-width: 768px) {
        background: unset !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .list-filters-wrap {
            display: block !important;
        }

        .each-filter {
            margin-bottom: 10px;
            background: #ffffff;

            input {
                background: unset;
            }

            &.date-filter {
                border: none !important;
                background: unset;

                .each-date {
                    background: #ffffff;
                    border: 1px solid #C5C5C5;
                    border-radius: 5px;

                    &:first-of-type {
                        margin-right: 10px;
                    }
                }
            }


            .date-cta {
                display: none;
            }
        }
    }
}

.all-items-list {
    position: relative;


    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .each_data_item {
        background: #fff;
        // border-bottom: 1px solid #BDBDBD;
        padding: 10px 2%;
        margin-bottom: 15px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

        .price_txt {
            color: #00A759;
            font-weight: 500;
        }
    }

    .top-tiny-txt {
        color: #757575;
        font-size: 10px;
    }

    .mobile-data-grid {
        .main-txt-head {
            display: block;
        }

        &.for_details {
            @media screen and (max-width: 1024px) {
                .main-txt-head {
                    display: flex;
                }

                .other_action {
                    text-align: right;
                }
            }
        }

        &.for_orders {
            .id_txt {
                font-weight: 500;
                font-size: 13px;
            }

            .order_id_action {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .order_action {
                font-size: 12px;
                font-weight: 500;

                &.confirm {
                    color: #697A02;
                }

                &.got_to {
                    color: #00A759;
                }
            }
        }
    }

    .main-txt-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        margin: 10px 0 6px;

        .prod_def {
            width: 65%;
            display: flex;
            align-items: center;
        }

        .other_action {
            flex-basis: 40%;

            .order_action {
                font-size: 12px;
                font-weight: 500;

                &.got_to {
                    color: #00A759;
                }
            }
        }

        .prod_img {
            flex-basis: 20%;
            background: #fafafa;

            img {
                width: 100%;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            }

        }

        .prod_name_cat {
            width: 80%;
            padding-left: 7px;
        }

        .name_only {
            font-size: 0.8rem;
            font-weight: 500;
            width: 75%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cate {
            font-weight: 400;
            font-size: 11px;
            color: #757575;

        }
    }

    .each_mobile_prod_info {

        // border-radius: 5px;
        padding: 10px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        &:nth-of-type(2n-2) {
            background: rgba(157, 180, 192, 0.1);
        }

        .title-txt {
            color: #312E2E;
        }

        .main_item {
            font-weight: 500;
        }

        .measure {
            text-transform: lowercase;
        }
    }

    .cat-items-list {
        margin: 10px 0 0;
        background: rgba(189, 189, 189, 0.1);
        border-radius: 5px;
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 7px;

        .title-txt {
            color: #757575;
        }
    }

    .cta-btns {
        margin-top: 10px;

        button,
        a.cta-link-txt {
            border: 1px solid $brand-color1;
            font-size: 11px;
            padding: 5px 15px;
            margin-right: 10px;
            font-weight: 400;
            background: none;
            border-radius: 5px;
            color: $brand-color1;
        }

    }

    .other-head-txt {
        &.done {
            color: $brand-color1;
            font-size: 12px;
        }

        &.ongoing {
            color: #EB984E;
            font-size: 12px;
        }

        &.failed {
            color: #E74C3C;
            font-size: 12px;
        }

    }

    .head-txt {
        span {
            display: block;
            font-weight: 300;
            font-size: 10px;
            color: #757575;
        }
    }
}

.cat-select-wrap {
    .dropdown-heading-value>span {
        font-size: 12px;
        color: #BBBBBB;
    }

    .dropdown-container:focus-within {
        border-color: $brand-green-light;
        box-shadow: none;
    }

    .dropdown-container {
        border: 1.2px solid #BDBDBD;
    }

    .dropdown-heading {
        height: 48px;
        // border: 1.2px solid #BDBDBD;
    }
}

.price-wrap {
    position: relative;

    .currency-pre {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-weight: 300;
        font-size: 13px;
    }

    .input-wrap {
        padding-left: 25px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    // -webkit-box-shadow: none !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background: none !important;
    background-color: none !important;
}

.custom-progress {
    height: 5px;
    background: #e9ecef;
    border-radius: 0.375rem;

    .progress-inner {
        height: 5px;
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
    }
}

.no-data {
    font-size: 12px;
    text-align: center;
    margin: 10px 0;
}

.nobank_linked {
    background: rgba(255, 232, 232, 0.85);
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .bank_txt {
        color: #757575;
        font-weight: 300;
        font-size: 12px;
        margin-left: 10px;

        span {
            color: $brand-green-light;
            font-weight: 600;
            margin-left: 5px;
        }
    }
}

.mobile {
    .page-content.dashboard {
        .top-section {
            display: block;

            @media screen and (min-width: 768px) and (max-width: 1024px) {
                display: flex;

                .each-topsection.withdrawal {
                    flex-basis: 23%;
                }

                .each-topsection.counts {
                    flex-basis: 29%;
                }
            }

            .mobile-heading {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }

        .each-topsection {
            margin-left: 0 !important;
            margin-bottom: 20px;
            min-height: 120px;
        }

        .mid-section {
            display: block;

            .right-side {
                margin: 20px 0;
                margin-left: 0;
            }

            @media screen and (min-width: 768px) and (max-width: 1024px) {
                display: flex;

                .review-summary {
                    margin-top: 0;
                    // padding-top: ;
                }

                .right-side {
                    flex-basis: 23%;
                    margin: 0;
                }
            }
        }

        .small-chartdata {
            display: flex;
        }

        .chartdata-wrap {
            flex-basis: 25%;
        }

        .statinfo {
            margin-top: 0 !important;
            flex-basis: 60%;
        }
    }

}

.p-CardNumberInput {
    .Input {
        box-shadow: none !important;
    }
}

.order_details_wrap {
    .head_txt {
        font-size: 12px;
        color: #757575;
        padding-bottom: 15px;
    }
}

.order_info_wrap {
    .heading_text {
        font-size: 15px;
        font-weight: 600;
    }

    .modal-header {
        border: none;
        padding-bottom: 0;

        .btn-close {
            font-size: 10px;
        }
    }
}

.confirm_cta {

    .btn,
    .btn:hover {
        background: $brand-color1;
        color: #ffffff;
        font-size: 13px;
        width: 100%;
        height: 40px;
    }
}

.total_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .title_txt {
        font-size: 12px;
        font-weight: 500;
    }

    .total_amount {
        color: #A9A9A9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.05);
        padding: 8px 13px;
        border-radius: 3px;
        border: 1px solid #D8D8D8;
    }
}

.product_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &:not(:last-of-type) {
        border-bottom: 0.5px solid #D8D8D8;
    }

    .left_product_info {
        flex-basis: 67%;
        display: flex;
        align-items: center;

        .product_img {
            img {
                width: 60px;
                height: 60px;
                border-radius: 5px;
            }
        }

        .product_name {
            font-weight: 500;
            font-size: 12px;

        }

        .product_cats {
            font-size: 10px;
        }
    }

    .right_product_info {
        flex-basis: 30%;

        .amount_qty_wrap {
            display: flex;
            align-items: stretch;
            border: 1px solid #D8D8D8;
            border-radius: 3px;

            input {
                width: 40%;
                border: none;
                outline: none;
                text-align: center;
                font-size: 12px;
                height: 30px;
                margin: 2px;
                overflow: auto;
                padding: 0 3px;
            }

            .amount_total {
                flex-basis: 60%;
                color: #A9A9A9;
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                background: rgba(0, 0, 0, 0.05);
                width: 60%;
                overflow: auto;
                padding: 0 3px;
            }
        }

        .cost_per_unit {
            font-size: 10px;
            font-style: italic;
            margin-top: 3px;
            text-align: center;
        }
    }
}

.order-status {
    padding: 3px 10px;
    display: inline-block;
    border-radius: 16px;
    font-size: 10px;
    font-weight: 500;

    &.others {
        background: rgb(133, 193, 233);
        color: #ffffff;
    }

    &.done {
        background: rgba(0, 168, 91, 0.1);
        color: $brand-green-light;
    }

    &.waiting {
        background: rgb(250, 215, 160);
        color: #ffffff;
    }

    &.failed {
        background: #FFE3E7;
        color: #312E2E;
    }
}

.withdrawal-form {
    .form-group {
        margin-bottom: 7px;
    }

    .form-group label {
        font-size: 12px;
        font-weight: 400;
        color: #605E5E;
    }

    .form-input-error {
        margin-left: 0;
    }

    input {
        border: 1px solid #BDBDBD;
    }

    .address-wrap {
        svg {
            display: none;
        }

        &>div>div:nth-of-type(2) {
            font-size: 13px;
        }

        &>div>div:first-of-type {
            border: 1.2px solid #BDBDBD;
            border-radius: 5px;
            height: 45px;
            font-size: 13px;

            &>div:nth-of-type(2) {
                display: none;
            }
        }
    }
}

.product-img {
    display: inline-block;
    margin-right: 7px;

    img {
        width: 25px;
        border-radius: 5px;
        height: 25px;
        object-fit: cover;
    }
}

.name-ofprod {
    font-weight: 400;
    font-size: 12px;
    color: #312E2E;
}

.each-cat-txt {
    color: #757575;
    font-weight: 300;
    font-size: 12px;
    background: #F1F1F3;
    padding: 3px 10px;
    margin-right: 6px;
    border-radius: 16px;
}

.order-info-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) {
        display: block;
    }

    .order-ctas {
        display: flex;
        align-items: center;
    }

    .btn.change-status-cta,
    .btn.change-status-cta:focus {
        background: $brand-green-light;
        border-radius: 5px;
        outline: none;
        border: none;
        color: #ffffff;
        font-size: 12px;
        height: 35px;
        cursor: pointer;
        line-height: 1.8;
    }

    .btn.change-status-cta:hover {
        font-weight: 500;
    }

    .change-status-cta.faded {
        opacity: 0.8;
    }

    .btn.order-no-action,
    .btn.order-no-action:focus {
        background: #ECF7FD;
        border-radius: 5px;
        outline: none;
        border: none;
        color: #02487A;
        font-size: 12px;
        height: 35px;
        cursor: not-allowed;
        pointer-events: none;
    }

    .btn.order-no-action:hover {
        font-weight: 500;
    }

    .btn.download-invoice,
    .btn.download-invoice:focus {
        border: 1px solid $brand-green-light;
        border-radius: 5px;
        outline: none;
        background: #ffffff;
        color: $brand-green-light;
        font-size: 12px;
        height: 35px;
        margin-left: 13px;
        cursor: pointer;
    }

    .download-invoice {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 15px;
            margin-right: 5px;
        }
    }

    .btn.download-invoice:hover {
        font-weight: 500;
    }
}

.custom_radio {
    width: 13px;
    height: 13px;
    display: inline-block;
    position: relative;
    z-index: 1;
    opacity: 0.6;
    // top: 5px;
    background: url("../../assets/check-mark-alt.svg") no-repeat;
    background-size: cover;

    &:hover:not(.selected_option) {
        opacity: 1;
    }

    &.selected_option {
        background: url("../../assets/check_round.svg") no-repeat;
        background-size: cover;
        opacity: 1;
    }
}

.custom_radio input[type="radio"] {
    margin: 1px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;
    /* CSS hacks for older browsers */
    // _noFocusLine: expression(this.hideFocus=true);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -khtml-opacity: 0;
    -moz-opacity: 0;
}

.all_options {
    display: flex;
    // justify-content: space-between;
    margin-top: 8px;
}

.an_option {
    display: flex;
    align-items: flex-start;
    margin-right: 50px;

    &:nth-of-type(2) {
        margin-right: 10%;
    }


    label {
        font-size: 12px;
        line-height: 1;
        margin-left: 3px;
        cursor: pointer;

        span {
            display: block;
            margin-bottom: 7px;

            &:nth-of-type(2) {
                color: #9B9B9B;
                font-size: 11px;
            }
        }
    }
}

.tax-label {
    color: #605E5E;
    font-size: 13px;

    span {
        font-weight: 500;
        color: $brand-green-light;
    }
}

.card_coming_soon {
    font-size: 15px;
    font-style: italic;
}

ul.pagination_items {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;

    li {

        // padding: 5px 10px;
        // font-size: 11px;
        // margin: 0 5px;
        // border-radius: 4px;
        // color: #667085;
        // border: 0.6px solid #D0D5DD;
        a {
            color: #667085;
            padding: 5px 10px;
            font-size: 11px;
            margin: 0 5px;
            border-radius: 4px;
            color: #667085;
            border: 0.6px solid #D0D5DD;

            &:hover {
                cursor: pointer;
                border: 0.6px solid #D0D5DD;
                background: #E8FDF3;
                font-weight: 600;
            }
        }

        &.active_page_link a {
            background: #E8FDF3;
            color: #77B94E;
            font-weight: 600;
        }



    }
}

.no-stores-wrap {
    width: 55%;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        width: 80%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .section-title {
        font-weight: 600 !important;
    }

    p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .no-stores-cta {
        text-align: right;

        button {
            background: #00A85B;
            display: inline-block;
            padding: 10px 11px;
            border-radius: 30px;
            color: #ffffff;
            font-size: 13px;
            font-weight: 500;
            margin: 10px 0;
        }
    }
}

.resend-link {
    font-size: 12px;
    color: #E74C3C;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
}

.pending-cta {
    color: $brand-green-light;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }

    &.ongoing {
        color: #E59866;
        font-size: 12px;
    }

    &.with-error {
        color: #EC3237;
        font-size: 12px;
    }
}

.setting-page {
    .for_policy {
        textarea.input-wrap {
            min-height: 250px !important;
            max-height: 250px !important;
            padding-top: 20px;
        }
    }
}

.each_info_wrap {
    display: flex;
    align-items: flex-start;
    padding: 0 30px;
    justify-content: space-between;
    margin-bottom: 50px;

    .info_heading_wrap {
        flex-basis: 30%;

        .main_heading_txt {
            font-weight: 600;
            font-size: 18px;
        }

        .sub_info_txt {
            font-size: 12px;
            font-weight: 400;
            color: #757575;
            margin: 6px 0 9px;

            .sub_info_txt span {
                font-weight: 600;
            }
        }

        .info_desc {
            font-size: 12px;
            font-weight: 400;
            color: #757575;
            margin: 6px 0 9px;

            span {
                color: #00A85B;
            }
        }
    }



    .url_txt {
        color: #00A85B;
        font-size: 12px;
        margin-top: 3px;
    }

    .info_data_section {
        flex-basis: 65%;
    }

    .each_data_wrap.for_logo {
        display: flex;
        justify-content: space-between;

        button.btn.edit_prompt {
            border-radius: 5px;
            border: 1px solid #BDBDBD;
            color: #757575;
            font-size: 13px;
            padding: 8px 10px;

            img {
                margin-right: 3px;
            }
        }

        .logo-image {
            display: flex;
            align-items: center;

            .logo-icon {
                width: unset;
                height: unset;
                background: unset;
                padding: unset;
                max-width: unset;

                img {
                    width: 50px;
                    height: 50px
                }
            }
        }

        label {
            margin-left: 13px;
            text-decoration: none;
            font-weight: 500;
            margin-left: 0;
            display: flex;
            align-items: center;

            img {
                margin-right: 5px;
            }
        }

        .logo_label_wrap {
            margin-left: 14px;
        }

        .label_head {
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.card2 {
    border: 1px solid #BDBDBD;
    box-shadow: none !important;
    border-radius: 5px !important;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;

    &>div {
        width: 100%;
    }
}

.remove_account {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
    display: flex;
    align-items: center;

    // jus
    .confirm_remove_txt {
        color: #707070;
        font-size: 12px;
        text-align: center;
        flex-basis: 100%;

        span {
            font-weight: 600;
        }
    }

    .remove_ctas {
        text-align: center;

        .btn {
            margin: 10px;
            font-size: 12px;
        }

        .back,
        .back:active {
            background-color: #fafafa;
            border: none;
            color: #212529;
        }

        .confirm,
        .confirm:active {
            background: $brand-green-light;
            border: none;
            color: #ffffff;
        }

        .confirm:hover {
            background: $brand-red;
        }
    }
}