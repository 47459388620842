.alert-msg {
    border-radius: 6px;
    color: #ffffff !important;
    display: flex;
    font-size: 12px;
    align-items: center;
    margin: 15px 0;
    padding: 10px;
    img{
        width: 13px;
        margin-right: 10px;
    }
    &.error{
        background: #FF001F;
    }
    &.success{
        background: #039855;
    }

}