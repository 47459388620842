@import "./shared/colors";
@import "./shared/constants";
@import "./shared/global";

.cardContainer {
    width: 100%;
    padding: 4rem 4rem;

    .cards {
        display: grid;
        grid-template-columns: 2fr 1.2fr 1.2fr 1.2fr;
        gap: 1.3rem;
        margin-top: 1rem;

        .card {
            display: flex;
            height: 508px;
            padding: 379px 35px 24px 24px;
            flex-direction: column;
            justify-content: flex-end;
        }

        .icon {
            color: #fff;
            font-size: 20px;
        }

        .cardTitle {
            color: #FFF;
            font-size: $font-size-medium;
            font-weight: $font-semibold;
            line-height: 28.8px;
        }

        .cardText {
            color: #F3F3F3;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 1100px) {
        .cards {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 1.3rem;
            margin-top: 1rem;
        }
    }

    @media only screen and (max-width: 784px) {
        .cards {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1.3rem;
            margin-top: 1rem;

            .card {
                display: flex;
                height: 170px;
                padding: 51px 127px 16px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }

    @media only screen and (max-width: 520px) {
        .cards {
            .card {
                display: flex;
                height: 150px;
                padding: 51px 50px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .cards {
            gap: 1rem;
            margin-top: 1rem;

            .card {
                display: flex;
                height: 150px;
                padding: 51px 0px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
            .card0{
                display: flex;
                height: 200px;
                padding: 51px 0px 10px 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
    }


    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .benefit {
        color: $brand-green-light;
        font-size: 12px;
        font-weight: $font-regular;
    }

    .title {
        color: #312E2E;
        font-size: $font-size-big;
        font-weight: $font-semibold;
        line-height: 39.2px;
        width: 68%;
    }

    .titles {
        display: none;
    }

    .subtext {
        color: $brand-color3;
        font-size: 13px;
        font-weight: $font-semiregular;
        line-height: 22.4px;
        margin-top: 3rem;
        width: 70%;
        margin-left: 30%;
    }

    @media only screen and (max-width: 784px) {
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        .titles {
            display: block;
            color: #312E2E;
            font-size: $font-size-big;
            font-weight: $font-semibold;
            line-height: 39.2px;
        }

        .title {
            display: none;
        }

        .subtext {
            margin-top: 1.5rem;
            width: 70%;
            margin-left: 0%;
        }
    }

    @media only screen and (max-width: 520px) {
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        .titles {
            display: block;
            color: #312E2E;
            font-size: 22px;
            font-weight: $font-semibold;
            line-height: 34.2px;
            width: 90%;
        }

        .subtext {
            width: 100%;
            margin-top: .7rem;
        }
    }

    @media only screen and (max-width: 400px) {
        .titles {
            display: block;
            color: #312E2E;
            font-size: 18px;
            font-weight: $font-semibold;
            line-height: 30.2px;
            width: 100%;
        }

        .subtext {
            width: 100%;
            margin-top: .7rem;
        }
    }

    .top {
        margin-top: 4rem;

        .users {
            display: grid;
            width: 35%;
            grid-template-columns: 1fr;
            margin-top: 2.5rem;
            gap: 3rem;
        }

        .users-mobile {
            display: none;
        }

        .user {
            display: flex;
            align-items: center;
            padding: 20px 120px 20px 20px;
            border-radius: 12px;
            background: rgba(231, 250, 232, 0.65);
        }

        .user2 {
            display: flex;
            align-items: center;
            padding: 20px 120px 20px 20px;

        }

        .user img {
            width: 100px;
            height: 100px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;
        }

        .user2 img {
            width: 100px;
            height: 100px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;
        }

        .details {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            margin-top: 1.2rem;
        }

        .name {
            color: #312E2E;
            font-size: 18px;
            font-weight: $font-semibold;
            margin-top: -1rem;
            padding-top: -2rem;

        }

        .store {
            color: #414141;
            font-size: 13px;
            font-weight: $font-semiregular;
            margin-bottom: -.2rem;
            line-height: 30px;
        }

        .rows {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
        }

        .comments {
            width: 45%;
            margin-top: 2.7rem;
        }

        .commentTitle {
            color: #312E2E;
            font-size: 18px;
            font-weight: $font-semibold;
            line-height: 28px;
            letter-spacing: 0.4px;
        }

        .commentText {
            color: $brand-color3;
            font-size: 14px;
            font-weight: $font-semiregular;
            line-height: 25.6px;
        }

        .quote {
            height: 50%;
            margin: 23% 0;
        }

        @media only screen and (max-width:1100px) {
            .users {
                display: grid;
                width: 45%;
                grid-template-columns: 1fr;
                margin-top: 2.5rem;
                gap: 3rem;
            }
        }

        @media only screen and (max-width: 920px) {
            .rows {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
            }

            .users {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr;
                margin-top: 2.5rem;
                gap: 3rem;
            }

            .comments {
                width: 100%;
                margin-top: 2.7rem;
            }

            .user {
                padding: 20px 0px 20px 20px;
            }

            .user2 {
                display: flex;
                align-items: center;
                padding: 20px 0px 20px 20px;

            }

            .quote {
                height: 30%;
                margin: 23% 0;
            }
        }

        @media only screen and (max-width: 784px) {
            .rows {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
            }

            .users {
                display: none;
            }

            .users-mobile {
                display: flex;
            }

            .comments {
                width: 100%;
                margin-top: 2.7rem;
            }

            .user {
                padding: 20px 50px 20px 20px;
            }

            .user2 {
                display: flex;
                align-items: center;
                padding: 20px 50px 20px 20px;

            }

            .quote {
                display: none;
                ;
            }
        }
    }

    .top2 {
        display: flex;
        justify-content: space-between;

        .subtext2 {
            color: $semi-lightgray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            width: 90%;
            margin-top: 1rem;
        }

        .answer {
            color: $semi-ligther-gray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
        }

        .flexes {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .question {
            color: #312E2E;
            font-size: 16px;
            font-weight: $font-regular;
            padding-top: .1rem;
            padding-bottom: .7rem;

        }

        .right {
            width: 47%;
            padding-top: 1rem;
        }

        .left {
            width: 40%;
        }

        .bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 1rem;
        }

        .contact {
            display: flex;
            height: 48px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px;
            background: $brand-green-light;
            border: none;
            outline: none;
            color: #FFF;
            font-size: 13px;
            font-weight: $font-semibold;
        }
    }

    @media only screen and (max-width: 784px) {
        .top2 {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            .right {
                width: 100%;
                padding-top: 1rem;
            }

            .left {
                width: 100%;
            }
        }

    }
}

@media only screen and (max-width: 520px) {
    .cardContainer {
        padding: 4rem 2rem;
    }

}

@media only screen and (max-width: 400px) {
    .cardContainer {
        padding: 4rem 1rem;
    }

}

.mapBg {
    background: url('../../src/assets/map.svg');
    width: 100%;
    height: 100%;
    padding: 4rem 0;
    background-position: center;
    background-size: cover;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .join {
        color: $brand-green-light;
        font-size: 12px;
        font-weight: $font-regular;
    }

    .mapTitle {
        color: #312E2E;
        text-align: center;
        font-size: 26px;
        font-weight: $font-semibold;
        line-height: 38px;
        width: 40%;
    }

    .mapText {
        color: #575757;
        text-align: center;
        font-size: 12px;
        font-weight: $font-semiregular;
        line-height: 21px;
        width: 44%;
        margin-top: .5rem;
    }

    .start {
        display: flex;
        width: 145px;
        height: 48px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        background: $brand-green-light;
        border: none;
        outline: none;
        color: #FFF;
        font-size: 13px;
        font-weight: $font-semibold;
        line-height: 22.4px;
    }

}

@media only screen and (max-width: 784px) {
    .mapBg {
        padding: 2rem 0;

        .mapTitle {
            width: 70%;
            font-size: 24px;
            line-height: 36px;
        }

        .mapText {
            width: 74%;
        }

    }
}

@media only screen and (max-width: 520px) {
    .mapBg {
        padding: 2rem 0;

        .mapTitle {
            width: 90%;
            font-size: 24px;
            line-height: 36px;
        }

        .mapText {
            width: 80%;
        }

    }
}
@media only screen and (max-width: 400px) {
    .mapBg {
        padding: 2rem 0;

        .mapTitle {
            width: 90%;
            font-size: 18px;
            line-height: 27px;
        }

        .mapText {
            width: 85%;
        }

    }
}