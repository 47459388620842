// @import "../shared/";
// @import "../shared/colors";
// @import "../shared/global";

.helpContainer {
    width: 100%;
    background-color: #FCFCFC;
    height: 100%;
}

.helpContent {
    width: 92%;
    height: 100%;
    margin: 0 auto;
    padding: 2rem 0;
}

.ticketContainer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.35);

    .ticketModal {
        overflow-y: auto;
        border-radius: 10px;
        width: 40%;
        max-height: 90vh;
        margin: auto;
        background-color: #fff;
        padding: 2rem 2rem 5rem 2rem;
        z-index: 1000;
    }

    .title {
        color: #312E2E;
        font-size: 18px;
        font-weight: 500;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .flex2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;

    }

    .row2 {
        display: flex;
        margin-top: .5rem;
    }

    .input {
        width: 100%;
        border-radius: 5px;
        background: #F4F4F4;
        border: none;
        outline: none;
        height: 48px;
        padding: 0 1.5rem 0 1rem;
        margin-top: .5rem;
        font-size: 13px;
        color: #A2A2A2;
        font-weight: 300;
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .select {
        height: 48px;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
        padding: 0 1rem;
        outline: none;
        margin-top: .5rem;
        font-size: 13px;
        color: #A2A2A2;
        font-weight: 300;
    }

    .textarea {
        height: 261px;
        border: 1px solid #BDBDBD;
        padding: 1rem;
        outline: none;
        margin-top: .5rem;
        font-size: 13px;
        color: #A2A2A2;
        font-weight: 300;
    }

    .label {
        color: #605E5E;
        font-size: 13px;
        font-weight: 300;
        margin-top: 1rem;
    }

    .row {
        display: flex;
        justify-content: flex-start;
        margin-top: .7rem;
    }

    .upload {
        color: #00A85B;
        font-size: 12px;
        font-weight: 400;
        margin-left: .4rem;
    }

    .button {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #00A85B;
        color: #FFF;
        font-size: 13px;
        font-weight: 500;
        margin-top: 2rem;
        border: none;
        outline: none;
    }

    @media only screen and (max-width: 1000px) {
        .ticketModal {
            width: 60%;
        }

        .textarea {
            height: 200px;
        }
    }

    @media only screen and (max-width: 600px) {
        .ticketModal {
            width: 80%;
        }

        .textarea {
            height: 150px;
        }
    }
}

.dropdownContainer {
    position: absolute;
    right: 3%;
    width: 350px;
    border-radius: 10px;
    background: #EBEBEB;
    box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.05);
    height: auto;
    padding-bottom: 1.2rem;

    .title {
        color: #312E2E;
        font-size: 16px;
        font-weight: 500;
        padding: 1rem
    }

    .ticketContent {

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .card {
        background: #FFF;
        padding: 0 1rem 1rem 1rem;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        width: 90%;
        margin: auto;

    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .id {
        color: #757575;
        font-size: 13px;
        font-weight: 300;
        padding-top: 1rem;
    }

    .red {
        color: #EC3237;
        text-align: right;
        font-size: 13px;
        font-weight: 400;
    }

    .blue {
        color: #1F95C8;
        text-align: right;
        font-size: 13px;
        font-weight: 400;
    }

    .name {
        color: #312E2E;
        font-size: 15px;
        font-weight: 500;
    }

    .description {
        color: #757575;
        font-size: 13px;
        font-weight: 400;
        line-height: 22.4px;
    }

    .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #EC3237;
        margin-right: .4rem;
        margin-top: .5rem;

    }

    .dot2 {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #1F95C8;
        margin-right: .4rem;
        margin-top: .5rem;

    }
}

.chatContent {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.35);

    .chatContainer {
        width: 42%;
        background-color: #fff;
        height: 100%;
        z-index: 1000;
        position: fixed;
        height: 100%;
        top: 0;
        right: 0;
        overflow-y: scroll;
    }

    .img {
        width: 48px;
        height: 48px;

    }

    .h4 {
        color: #312E2E;
        font-size: 16px;
        font-weight: 500;
    }

    .sub {
        color: #757575;
        font-size: 12px;
        font-weight: 300;
    }

    .texts {
        margin-left: 1.5rem;
    }

    .border {
        width: 100%;
        margin-top: -.5rem;
    }

    .header {
        z-index: 1000;
        position: fixed;
        top: 0;
        width: 42%;
    }

    .header2 {
        justify-content: space-between;
        position: relative;
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        padding: 0 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .flexrow {
        display: flex;
        justify-content: space-between;
    }

    .container {
        padding: 1rem 2rem;
        background-color: #fff;

    }

    .delete {
        color: #EC3237;
        font-size: 13px;
        font-weight: 400;
    }

    .chatmsg {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        position: relative;
        height: 100%;
        padding-top: 15rem;
        padding-bottom: 6rem;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 70%;
        align-items: flex-end;
        text-align: justify;
        margin-left: 25%;
        height: 100%;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 70%;
        margin-right: 25%;
        margin-left: 5%;
        align-items: flex-start;
        border-radius: 10px;
    }

    .rightText {
        background-color: #00A85B;
        color: #FFF;
        font-size: 13px;
        font-weight: 300;
        padding: 1rem;
        border-radius: 10px;

    }

    .leftText {
        background: #E8E8E8;
        color: #333234;
        font-size: 13px;
        font-weight: 300;
        padding: 1rem;
        border-radius: 10px;
    }

    .sentImg {
        width: 70%;
        margin-left: 25%;
        height: 200px;
        background-color: none;
        object-fit: cover;
        border-radius: 10px;
        object-position: center;
        margin-bottom: 40px;
    }

    .bottom {
        width: 42%;
        padding-bottom: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        padding: 1.5rem 2rem;
        position: fixed;
        bottom: 0;
        background-color: #FFF;

    }

    .inputbox {
        display: flex;
        width: 100%;
        position: relative;
    }

    .input2 {
        width: 95%;
        display: flex;
        height: 48px;
        padding: 10px 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #C5C5C5;
        color: #BDBDBD;
        font-size: 13px;
        font-weight: 300;
        position: relative;
        outline: none;
    }

    .icon2 {
        position: absolute;
        right: 10%;
        top: 30%;
    }

    .send {
        display: flex;
        height: 48px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        background: #00A85B;
        color: #FFF;
        font-size: 13px;
        outline: none;
        border: none;
        font-weight: 500;
    }

    @media only screen and (max-width: 1000px) {
        .chatContainer {
            width: 62%;
        }

        .header {
            width: 62%;
        }

        .bottom {
            width: 62%;
        }

    }

    @media only screen and (max-width: 600px) {
        .chatContainer {
            width: 100%;
        }

        .header {
            width: 100%;
        }

        .bottom {
            width: 100%;
        }
    }
}