@import "icons";

.sidebar-wrap {
    background: #fff;
    width: 30%;
    max-width: 280px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;
    overflow: auto;
    left: 0;

    .store_logo_n_earnings {
        display: flex;
        padding-left: 15%;
        padding-top: 30px;
        // padding-bottom: 40px;

    }

    .profile-info {
        display: flex;
        align-items: center;
        margin-left: 15px;
        &.for_mobile_profile{
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            margin-left: 0;
            width: 100%;
            display: block;
            // width: 95%;
            border-radius: 5px;
            .dropdown-menu{
                position: relative;
            }
            .dropdown-item{
                padding-left: 7px;
                padding-right: 7px;
            }
            .selected_store .store-brief {
                background: rgba(0, 168, 91, 0.10) !important;
                border-radius: 5px;
            }
        }
        .dropdown-menu {
            border: none;
            min-width: 190px;
        }
        a.stores-heading {
            color: #312E2E;
            font-weight: 600;
            font-size: 12px;
            pointer-events: unset;
        }
        .store-info_wrap{
            &:active, &:focus, &:hover{
                background: none !important;
            }
        }
        .store-brief{
            display: flex;
            align-items: center;
            padding: 10px 10px;
            margin-bottom: 5px;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
                background: rgba(0, 168, 91, 0.10) !important;
            }
            .store-logoico{
                margin-right: 8px;
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    min-width: 30px;
                }
            }
            .store-name-text{
                font-size: 12px;
                font-weight: 600;
                max-width: 95px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #404040 !important;
                text-transform: capitalize;
            }
            .store-location-text{
                font-size: 10px;
                color: #757575;
            }
        }
    }

    .logo-head {
        img {
            width: 150px;
        }

        @media screen and (max-width: 768px) {
            img {
                width: 100px !important;
            }
        }
    }

    .bizname-wrap {
        text-align: center;
        position: relative;
        padding: 70px 0 50px;

        .store_logo img {
            width: 60px;
            border-radius: 5px;
        }
        @media screen and (max-width: 1024px){
            padding-bottom: 10px;
        }
    }

    .all-menu-items {
        // overflow-y: auto;
        // position: relative;
        padding-bottom: 40px;
    }

    .bizname {
        font-size: 15px;
        font-weight: 400;
        color: #262626;
    }

    .biz-type {
        color: #9B9B9B;
        font-size: 10px;
        line-height: 1;
        font-style: italic;
    }

    .menu-item {
        margin: 0 0 7px;

        @media screen and (max-width: 1024px) {
            margin: 0 0 12px;
            &.for-logout{
                border-top: 1.2px solid #f2f2f2;
            }
        }

        a {
            display: flex;
            align-items: center;
            padding: 15px 10px;
            transition: .1.5s ease-in;
            padding-left: 15%;

            @media screen and (max-width: 1024px) {
                padding-left: 10px;
            }

            // padding-bottom: 15px ;
            &:hover,
            &.active-nav {
                border-left: 3px solid #00A759;
                background: rgba(0, 168, 91, 0.1);
                padding-top: 15px;
                padding-bottom: 15px;

                .menu-txt {
                    color: #00A85B;
                }


                .menu-icon {

                    &.dash-ico span {
                        background-image: url("data:image/svg+xml, #{$dashboard-shade}");
                    }

                    &.prod-ico span {
                        background-image: url("data:image/svg+xml, #{$products-shade}");
                    }

                    &.order-ico span {
                        background-image: url("data:image/svg+xml, #{$orders-shade}");
                    }

                    &.chat-ico span {
                        background-image: url("data:image/svg+xml, #{$chat-shade}");
                    }

                    &.settings-ico span {
                        background-image: url("data:image/svg+xml, #{$settings-shade}");
                    }

                    &.support-ico span {
                        background-image: url("data:image/svg+xml, #{$support-shade}");
                    }

                }

                @media screen and (max-width: 1024px) {
                    background: #00A759;
                    border-radius: 5px;
                    padding: 10px;

                    .menu-txt {
                        color: #fff;
                        font-size: 13px;
                    }

                    .menu-icon {
                        &.dash-ico span {
                            background-image: url("data:image/svg+xml, #{$dashboard-light}");
                        }

                        &.prod-ico span {
                            background-image: url("data:image/svg+xml, #{$products-light}");
                        }

                        &.order-ico span {
                            background-image: url("data:image/svg+xml, #{$orders-light}");
                        }

                        &.settings-ico span {
                            background-image: url("data:image/svg+xml, #{$settings-light}");
                        }
                    }
                }
            }

            &.logout_wrap:hover {
                border-left: 3px solid #EC3237;
                background: rgba(236, 50, 55, 0.3) !important;

                .menu-txt {
                    color: #EC3237;
                }
            }

            &.soon {
                cursor: not-allowed;
                pointer-events: none;

                .soon-txt {
                    background: #00A85B;
                    display: inline-block;
                    padding: 3px 10px;
                    border-radius: 30px;
                    color: #ffffff;
                    font-size: 7px;
                    margin-left: 5px;

                    @media screen and (max-width: 1024px) {
                       padding: 3px 6px;
                       font-size: 6px;
                    }
                }
            }
        }

        .logout_wrap {
            display: flex;
            align-items: center;
            padding: 15px 10px;
            transition: .1.5s ease-in;
            padding-left: 15%;
            cursor: pointer;
            @media screen and (max-width: 1024px) {
                padding-left: 10px;
             }
        }

        .logout_wrap:hover {
            border-left: 3px solid #EC3237;
            background: rgba(236, 50, 55, 0.3) !important;

            .menu-txt {
                color: #EC3237;
            }
        }

        .menu-txt {
            color: #9B9B9B;
            font-size: 14px;
            margin-left: 18px;

            &.logout {
                color: #EC3237;
            }
        }

        .menu-icon {
            span {
                width: 15px;
                height: 15px;
                display: inline-block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.dash-ico span {
                background-image: url("data:image/svg+xml, #{$dashboard-white}");
            }

            &.prod-ico span {
                background-image: url("data:image/svg+xml, #{$products-white}");
            }

            &.order-ico span {
                background-image: url("data:image/svg+xml, #{$orders-white}");
            }

            &.chat-ico span {
                background-image: url("data:image/svg+xml, #{$chat-white}");
            }

            &.settings-ico span {
                background-image: url("data:image/svg+xml, #{$settings-white}");
            }

            &.support-ico span {
                background-image: url("data:image/svg+xml, #{$support-white}");
            }

            &.logout-ico span {
                background-image: url("data:image/svg+xml, #{$logout-white}");
            }
        }

        &.logout {
            display: flex;

            // margin-top: ;
            img {
                width: 40px;
            }
        }
    }

    .other-menu {
        margin-top: 30px;

        .title {
            text-transform: uppercase;
            color: #8A8F9C;
            font-size: 12px;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    .signout {
        position: absolute;
        bottom: 14px;
        left: 10px;
        cursor: pointer;

        &:hover .menu-txt {
            font-weight: 600;
            color: red;
        }
    }

    &.withmobile {
        width: 85%;
        max-width: unset;
        position: fixed;
        top: 0;
        bottom: 0;
        overflow: auto;
        left: 15%;
        // max-width: 250px;
        border-radius: 0;
        z-index: 4;
        padding: 0 3%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        @media screen and (min-width: 426px) {
            // width: 40%;
            // left: 60%;
        }

        @media screen and (min-width: 769px) {
            width: 35%;
            left: 65%;
        }

        .bizname-wrap {
            top: 0;
            margin-top: 0px;
            padding-top: 0;
        }

        .mobile-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 25px 0 5px;
        }

        .store_logo_n_earnings {
            padding: 10px 0;

            .store_logo {
                img {
                    width: 40px;
                }
            }
        }

        .store_name_txt {
            font-weight: 600;
            font-size: 13px;
        }

        .store_location_txt {
            font-size: 11px;
            color: #757575;
        }

        .store_info_m {
            margin-left: 10px;
            text-align: left;
        }
    }
}

.underlay_mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
    left: 0;
}

.product-action {
    display: flex;
}

.switch-biz-wrap {
    text-align: center;
    margin: 5px auto;
    width: 70%;

    .switch-cta {
        font-size: 10px;
        opacity: 0.6;
        cursor: pointer;
        border-top: 1px solid #EAEDED;

        &:hover {
            font-weight: 600;
        }
    }
}

.btn:disabled {
    background-color: inherit !important;
}

.sidebar-close {
    text-align: right;
    padding: 10px 0 10px 20px;
}

.earnings_wrap {
    text-align: left;
    margin-left: 23px;

    .heading {
        color: #757575;
        font-size: 14px;
        font-weight: normal;
    }

    .earning_amount {
        color: #312E2E;
        font-size: 27px;
        font-weight: 600;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 10px;

        .heading {
            font-size: 13px;
            font-weight: 300;
        }
    }
}