// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import "../../../scss/shared/colors";

.tablecomponent-container{
    // font-family: "Noto Sans JP", sans-serif;
    font-size: 12px;
    .container{
        overflow: auto;
    }
}



.loading-data {
    text-align: center;
    font-size: 20px;
}

.each-page-action{
    border: 0.6px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 8px 14px;
    color: #475467;
    &.unaallowed{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.move-page-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

span.each-pagenum {
    // background-color: white;
    padding: 5px 10px;
    font-size: 11px;
    margin: 0 3px;
    // border: 1px solid #DFE3E8;
    border-radius: 4px;
    color: #667085;
    &.active{
        // border: 1px solid #78B943;
        background: #F6FFF5;
        color: #77B94E;
    }
    &:hover{
        cursor: pointer;
        border: 0.6px solid #D0D5DD;
        // background: #A9CCE3;
    }
}

.middot {
    background-color: white;
    padding: 3px 8px;
    margin: 1px 3px 0;
    border: 1px solid #DFE3E8;
}

table.table {
    border-radius: 8px;

    thead th {
        border: none;
        border-bottom: 1px solid #EAECF0;
        // color: $inapp-brand-color8;
        font-size: 13px;
        font-weight: 400;
        // text-transform: uppercase;
        background: #F9FAFB;
        // padding-left: 24px;
        vertical-align: middle;
        min-width: 150px;
        color: #312E2E;
        font-weight: 500;
    }
    td{
        padding: 18px 15px 18px 0;
    }
}

table.table-bordered td, table.table-bordered th {
    border: 1px solid #f1f2f3;
}

table.table-striped tbody tr:nth-of-type(odd), table.table-striped tbody tr:nth-of-type(odd) td{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1 !important;

}
