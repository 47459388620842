@import "./shared/colors";
@import "./shared/constants";
@import "./shared/global";

body {
    height: 100vh;
    /* Viewport height */
    margin: 0;
    padding: 0;
}

.postboarding-wrap {
    // background: url('../assets/postboard_bg.svg') no-repeat center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    // height: 100%;
    min-height: 100vh;

    .postboarding-container {
        width: 50%;
        height: 100%;
        background: #FFF;

        @media screen and (max-width: 768px) {
            display: block;
            padding: 10% 5%;

            .page-meta {
                margin-bottom: 30px;
                text-align: center;
            }

            .content-heading {
                text-align: justify;
            }

            .logo-wrap {
                img {
                    width: 200px;
                }
            }
        }
    }

    &.centered-bg-greeen {
        background: $brand-color1;
        display: block;
        padding: 3% 0 10%;

        .page-meta {
            margin: 0;
        }

        @media (max-width: 767px) {
            background: #fff;
        }

        .postboarding-container {
            width: 38%;
            margin: 0 auto;
            border-radius: 10px;
            padding: 2rem;

            .create-form {
                width: 100% !important;
            }

            @media (min-width: 1440px) {
                width: 30%;
            }

            @media (min-width: 768px) and (max-width: 1024px) {
                width: 90%;
            }

            @media (max-width: 767px) {
                width: 95%;
                padding: 2rem 10px;
            }
        }

        .postboard-form.inner-wrapper {
            padding: 0;
        }
    }

    .login-bg {
        width: 50%;
        background: url('../assets/login-bg.webp');
        min-height: 100vh;
        overflow: hidden;
        background-size: cover;
        background-position: center;
    }

    .v2-bg {
        min-height: 100vh;
        background-position: center;
        animation: bgfade 5s 2 forwards;
        width: 48%;
        margin: 8px;
        border-radius: 16px;

        @keyframes bgfade {

            0%,
            50% {
                background: url('../assets/landing/onboard-1.webp') no-repeat bottom center, #0D5F39;
                background-size: 75%;
                min-height: 100vh;
                /* Image + Color */
                // background-blend-mode: overlay;
            }

            51%,
            100% {
                background: url('../assets/landing/onboard-2.webp') center no-repeat;
                background-size: 100%;
                min-height: 100vh;
                /* Image Only */
            }


        }


        @media (max-width: 767px) {
            animation: unset;
        }
    }

    .page-meta {
        color: #fff;
        // margin-left: 10%;
        margin: 5% 0 2rem 4rem;
    }


    .logo-wrap img {
        width: 170px;
    }

    .page-title {
        font-size: 18px;
        font-weight: 300;
    }

    .postboard-form {
        flex-basis: 32%;

        &.inner-wrapper {
            padding: 0 10%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .postboarding-wrap {

        .page-meta {
            margin: 5% 0 2rem 2rem;
        }

    }
}

@media only screen and (max-width: 784px) {
    .postboarding-wrap {
        flex-direction: column-reverse;

        .page-meta {
            margin: 5% 0 2rem 2rem;
        }

        .login-bg {
            width: 100%;
            height: 50vh;
            background-repeat: no-repeat;
        }

        .postboarding-container {
            width: 100%;
        }
    }
}

.postboarding-content {
    background: #fff;
    padding: 15% 15%;
    border-radius: 5px;


    .content-heading {
        h2 {
            color: #312E2E;
            font-size: 26px;
            font-weight: $font-semibold;
            line-height: 39px;
        }

        .content-heading-msg {
            color: $brand-color3;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;

            a,
            a:hover {
                margin-left: 7px;
                color: $brand-color2;
            }

            a:hover {
                text-decoration: underline !important;
            }
        }
    }


    .form-wrap {
        margin-top: 20px;
    }

    .forgot {
        color: $brand-green-light;
        font-size: 13px;
        font-weight: $font-regular;
    }

    .space-between {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: .7rem 0
    }

    .form-group {
        margin-bottom: 15px;
    }

    .label {
        color: #605E5E;
        font-weight: $font-semiregular;
        font-size: 13px;
    }

    .input-wrap {
        font-size: 12px;
        font-weight: 300;
    }

    .input-wrap::placeholder {
        color: #BBB;
        font-size: 13px;
        font-weight: $font-semiregular;
    }

    .gsign {
        width: 50%;
    }

    .optional {
        text-align: center;

        .span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $semi-ligther-gray;
            font-size: 13px;
            font-weight: $font-semiregular;
        }

        .span a {
            color: $brand-green-light;
            font-weight: $font-regular;
            margin-top: 1rem;
            margin-left: .4rem;
        }
    }

    &.postboarding-content-2 {
        padding: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .postboarding-content {
        padding: 15% 10%;


    }
}

@media only screen and (max-width: 784px) {
    .postboarding-content {
        padding: 1% 5%;

        .content-heading {
            text-align: left;

            h2 {
                color: #312E2E;
                font-size: 20px;
                font-weight: $font-semibold;
                line-height: 34px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .postboarding-content {

        .content-heading {
            h2 {
                font-size: 18px;
                line-height: 30px;
            }

            .content-heading-msg {
                font-size: 12px;
                line-height: 20.4px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .postboarding-content {
        padding: 1% 3%;

        .content-heading {
            h2 {
                font-size: 16px;
                line-height: 28px;
            }

            .content-heading-msg {
                font-size: 11px;
                line-height: 19.4px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .v2-postboarding {
        width: 100% !important;
        .more-desc{
            font-size: 1rem !important;
        }
        .postboard-form.inner-wrapper{
            padding: 0;
        }
        .each-options{
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            padding: 5px;
            border-radius: 5px;
            margin-bottom: 10px;
            &.chosen-url{
                background: $brand-green-light;
                color: #FFF;
            }
            .custom_radio{
                height: 0;
                width: 0;
            }
            label{
                word-break: break-all;
                margin-left: 0 !important;
            }
        }
    
        
        .page-meta{
            margin: 0 !important;
        }
        .create-form{
            width: 100% !important;
        }
    }
}
@media only screen and (min-width: 769px)and (max-width: 1024px) {
    .v2-postboarding {
        width: 100% !important;
        .page-meta{
            // margin: 1.5rem 0 !important;
            width: 75% !important;
            margin: 0 auto !important;
            padding: 1rem 10% !important;
        }
        .create-form{
            width: 75% !important;
            margin: 0 auto;
        }
    }
}

.signup-wrap {
    // height: 100vh;
}

.special-label {
    display: none;
}

.success-message-wrap {
    text-align: center;
}

.signup-wrapper {
    // position: absolute;
    width: 100%;
    height: 100%;
    background: #F3F3F3;
    padding: 50px 0;

    .signup-header {
        text-align: center;
        margin: 30px 0;

        img {
            width: 150px;
        }
    }

    .address-wrap {
        svg {
            display: none;
        }

        &>div>div:nth-of-type(2) {
            font-size: 13px;
        }

        &>div>div:first-of-type {
            border: 1.2px solid #BDBDBD;
            border-radius: 5px;
            height: 45px;
            font-size: 13px;

            &>div:nth-of-type(2) {
                display: none;
            }
        }
    }

    .input-with-divider {
        height: 45px !important;

        &.with-url {
            background: #F4F6F7;

            input {
                background: transparent;
            }

            &>div:nth-of-type(2) {
                width: 100%;
            }
        }

        .url-input {
            font-size: 12px;
            cursor: not-allowed;
        }
    }

    .url_options {
        margin-bottom: 20px;
        background: #F7F9F9;
        padding: 10px;
        border-radius: 3px;

        .url_header {
            font-size: 14px;
            margin-bottom: 6px;
            font-weight: 500;
        }
    }

    .each-options {
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        label {
            font-size: 12px;
            margin-left: 6px;
        }
    }

    .signup-content {
        width: 38%;
        margin: 0 auto;
        background: #fff;
        padding: 30px;
    }

    .content-heading {
        margin-bottom: 20px;

        h2 {
            font-size: 30px;
            font-weight: 400;
            text-align: center;
        }
    }

    .form-wrap {
        .form-group {
            margin-bottom: 20px;

        }
    }

    .input-wrap {
        height: 45px;
    }

    .owner-info {
        border-top: 1px solid #BDBDBD;
        padding: 25px 0 0;
        margin-top: 25px;
    }

    .owner-heading {
        font-size: 22px;
        color: #757575;
        margin-bottom: 25px;
        line-height: 1;
    }

    .form-group.two-sided {
        display: flex;
        justify-content: space-between;

        &>div {
            flex-basis: 48%;
        }
    }

    .agree-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .agree-terms {
            flex-basis: 50%;
            font-size: 13px;
            display: flex;
            align-items: center;
            color: #757575;

            label {
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }

    .main-cta {
        flex-basis: 40%;
        background: #F93B41 !important;
    }

    .others-wrap {
        margin-top: 20px;
    }

    .optional {
        text-align: center;
        padding: 30px 0;
        color: #9B9B9B;
        font-weight: 300;
        font-size: 14px;

        a,
        a:hover {
            color: #9B9B9B;
            text-decoration: underline !important;
        }

        // a:hover {
        //     text-decoration: underline !important;
        // }
    }

    @media screen and (max-width: 768px) {
        .signup-content {
            width: 90%;
        }

        .content-heading h2 {
            font-size: 24px;
            font-weight: 600;
        }

        .form-group.two-sided {
            display: block;

            &>div {
                margin-bottom: 20px;
            }
        }

        .agree-cta {
            display: block;

            .main-cta {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}

.verify-wrap {
    background: #F3F3F3;

    .verify-head {
        background: #fff;
        padding: 10px 2.5%;
    }

    .logo-head img {
        width: 110px;
    }
}

.footer_wrap {
    display: block;
    padding: $desktop-width;

    .logo_wrap {

        // height: 54px;
        img {
            width: 120px;
        }
    }

    .footer_navs {
        display: flex;
        justify-content: space-between;
    }

    .nav_heading {
        font-size: $font-size-medium;
    }

    .each_navlist_wrap {
        flex-basis: 19%;

        &:not(:first-of-type) {
            .nav_items {
                margin-top: 54px;
            }
        }
    }

    .nav_items {

        a,
        a:hover {
            display: table;
            text-decoration: none;
            color: $light-gray;
            font-weight: $font-light;
            font-size: $font-size-small;
            margin-top: 10px;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .subsribe_box {
        text-align: center;
        padding: 80px 0;
        width: 70%;
        margin: 0 auto;

        input {
            border-radius: 50px;
            border: 1px solid lighten($light-gray, 5%);
            width: 100%;
            height: 48px;
            padding-left: 25px;
            font-size: 15px;
            background: transparent;
            font-weight: $font-light;
            outline: none;
        }

        .sub_heading {
            font-size: 30px;
            font-weight: $font-regular;
            margin-bottom: 14px;
        }
    }

    .input_wrap {
        position: relative;
    }

    .subscribe_btn {
        background: $brand-red;
        color: #fff;
        border-radius: 50px;
        height: 38px;
        font-weight: $font-light;
        font-size: $font-size-small;
        padding: 0 40px;
        position: absolute;
        right: 4px;
        top: 5px;
        border: none;
    }

    .page_bottom {
        border-top: 0.5px solid $light-gray;
        padding: 65px 0;
        display: flex;
        justify-content: space-between;

        .page_bottom_section:not(:last-of-type) {
            flex-basis: 20%;
        }

        .bottom_section_heading {
            color: $semi-black;
            font-weight: $font-semibold;
            font-size: $font-size-thick;
        }

        .page_bottom_section:last-of-type {
            flex-basis: 50%;
            display: flex;
            justify-content: space-around;

            .each_footlink {

                a,
                a:hover {
                    display: table;
                    text-decoration: none;
                    color: $light-gray;
                    font-weight: $font-light;
                    font-size: $font-size-small;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        .bottom_section_content {
            display: table;
            text-decoration: none;
            color: $light-gray;
            font-weight: $font-light;
            font-size: $font-size-small;
        }

        .social_links {
            display: flex;
            margin-top: 15px;

            .each_icon {
                // width: 20px;
                // height: 20px;
                margin-right: 10px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.loading-txt {
    font-size: 14px;
}

.verify-content {
    margin: 20px 3%;
    background: #fff;
    margin-bottom: 50px;

    .verify-heading {
        background: #D7F9EA;
        padding: 13px 24px;
    }

    .verify-title {
        color: #00A85B;
        font-weight: 600;
        font-size: 15px;
    }

    .other-txt {
        font-size: 12px;
        color: #818181;
    }

    .verify-steps {
        display: flex;
        justify-content: space-between;
        padding: 40px 20px;

        &.normal {
            display: block;
            text-align: center;

            .alert-msg {
                width: 50%;
                margin: 0 auto;
            }

            .try-again {
                color: $brand-color1;
                text-decoration: underline;
                display: inline-block;
                margin-top: 10px;
                cursor: pointer;

                &:hover {
                    font-weight: 500;
                }
            }
        }
    }

    .each-step {
        flex-basis: 30%;
        text-align: center;

        &:not(:last-of-type) {
            border-right: 1px solid #CFCFCF;
            padding-right: 3%;
        }

        .step_heading {
            h3 {
                font-weight: 500;
                color: #939393;
                font-size: 17px;
                margin-top: 10px;
                line-height: 1;
            }

            h4 {
                font-size: 15px;
                color: #00A85B;
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }

    .step-txt {
        font-size: 12px;
        color: #939393;
        margin-top: 5px;
        font-weight: 300;
    }

    .step-details {
        padding: 18% 0;
    }

    .selling {
        font-weight: 500;
        color: #DDDDDD;
        background: #FAFAFA;
        padding: 30px;
        width: 80%;
        border-radius: 5px;
        cursor: not-allowed;
        pointer-events: none;

        &.activated {
            background: #00A85B;
            color: #fff;
            cursor: pointer;
            pointer-events: unset;
        }
    }

    .each-phone-option {
        display: flex;
        background: #FAFAFA;
        margin-bottom: 20px;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(16, 24, 40, 0.05);
        padding: 16px 28px;
        border-radius: 3px;
        cursor: pointer;

        img {
            width: 20px;
        }

        .text {
            color: #00A85B;
            font-weight: 600;
            font-size: 14px;
            margin-left: 39px;
        }
    }

    .mail-wrap {
        background: #FAFAFA;
        border-radius: 5px;
        width: 70%;
        margin: 0 auto;
        padding: 20px 10px;
        color: #656565;

        img {
            width: 100px;
        }

        .mail-txt {
            font-size: 15px;
            width: 80%;
            margin: 0 auto;
            font-weight: 500;
        }
    }
}

.phone-pop {
    .modal-content {
        border-radius: 30px;
    }

    .btn-close {
        display: none;
    }

    form {
        width: 80%;
        margin: 0 auto;

        .btn {
            background: #00A85B;
            color: #fff;
            width: 100%;
            border: none;
            margin-top: 20px;
            font-weight: 400;
            font-size: 14px;
            height: 40px;
        }
    }

    input {
        font-size: 12px;
        height: 40px;
    }

    .modal-dialog {
        width: 34%;

    }

    .modal-header {
        border: none;
    }

    .modal-header .modal-title {
        text-align: center;
        color: #939393;
        font-size: 18px !important;
    }

    .sub-txt {
        font-weight: 400;
        font-size: 13px;
        margin-top: 10px;
    }
}

.biz-cat {
    .dropdown-container {
        box-shadow: none !important;
        border: 1.2px solid #BDBDBD;

        border-radius: 5px !important;
        // border-radius: 35px !important;
        height: 40px;
        border: 1.2px solid #BDBDBD;
        padding: 0 10px;
        font-size: 13px;
        // width: 100%;
        outline: none;

        &:focus-within {
            border: 1.2px solid #BDBDBD !important;
        }
    }

    &.invalid-input-wrap .dropdown-container {
        background: #fef3f2 !important;
        border-color: #fda29b !important;
    }
}

.all-cats-selected {
    display: flex;
    margin-bottom: 5px;

    .cat-name {
        background: #f2f2f2;
        margin-right: 9px;
        padding: 2px 5px;
        font-size: 12px;
        border-radius: 3px;
        font-weight: 500;
    }
}

.logo-image {
    margin-bottom: 20px;

    .logo-icon {
        width: 125px;
        height: 125px;
        margin: 0 auto;
        background: #fafafa;
        padding: 20px;
        border-radius: 100%;
        text-align: center;
        max-width: 125px;

        img {
            width: 60px;
            height: 60px;
            margin: 0 auto;
            border-radius: 100%;
        }
    }

    label {
        font-size: 12px;
        display: block;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        color: $brand-color1;
    }

    input {
        height: 0;
        width: 0;
    }
}

.form-control.select-input {
    padding: 0;
    font-size: 13px;
    color: #BDBDBD;
}

.width-40 {
    width: 40%;
}

.invalid-input-wrap.address-wrap {
    .store-address-select__control {
        border-color: #fda29b !important;
        background: #fef3f2 !important;
    }
}

.input-with-icon {
    position: relative;

    .show-hide {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
        cursor: pointer;

        img {
            height: 15px;
            opacity: 0.7;
        }
    }
}