@import "./shared/colors";
@import "./shared/constants";


.containers2 {
    width: 100%;
    height: 100%;
    background: #FCFCFC;


    .landing-header {
        display: flex;
        justify-content: space-between;
        background: #FFF;
        box-shadow: 0px 4px 14px 0px rgba(91, 87, 87, 0.10);
        padding: 1rem 5rem;

        .logo {
            width: 155.313px;
            height: 47.153px;
        }

        .logo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        .links {
            display: flex;
            align-items: center;
        }

        .login {
            color: $brand-green-light;
            font-size: 13px;
            font-weight: $font-regular;
            margin-right: 2rem;
        }

        .get-button {
            display: flex;
            height: 48px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px;
            background: $brand-green-light;
            color: #FFF;
            font-size: 13px;
            font-weight: $font-semibold;
        }

    }



    @media only screen and (max-width: 400px) {
        .landing-header {
            padding: 1rem 1rem;

            .get-button {
                display: none;
            }
        }

        .logo {
            width: 10.313px;
            height: 30.153px;
        }

    }

    .hero-section {
        background: $brand-green-light;

        .main-heading {
            color: #ffffff;
            font-weight: 600;
            font-size: 3.1rem;
            line-height: 1.2;
        }

        .sub-text {
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: 400;
            margin: 1.5rem 0 1.79rem !important;
            line-height: 1.4;
        }

        .start-cta {
            a {
                display: inline-block;
                color: $brand-green-light;
                background: #ffffff;
                padding: 0.7rem 1.2rem;
                font-weight: 500;
                font-size: 0.95rem;

                &:active {
                    border: none;
                }
            }
        }

        .hero-content {
            display: flex;
            padding: 3.5rem 5rem;
            align-items: center;
            justify-content: space-between;
        }

        .side-right {
            text-align: right;
            position: relative;
        }

        .main-img {

            img {
                width: 77%;
                object-fit: contain;
                border-radius: 20px;
            }
        }

        .other-imgs {
            position: absolute;
            right: 48%;
            bottom: 10%;

            .img-2 {
                margin-bottom: 1.5rem;
                width: 75%;
                margin-left: 25%;
            }
        }
    }

    .why-choose {
        padding: 4.1rem 5rem;

        .why-heading {
            font-weight: 600;
            text-align: center;
            font-size: 2.5rem;
            line-height: 1;
        }

        .why-items {
            display: flex;
            align-items: center;
            margin-top: 40px;
            justify-content: space-between;
        }

        .each-why-item {
            text-align: center;
            flex-basis: 32.3%;
        }

        .item-icon {
            width: 70px;
            height: 70px;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }

        .item-heading {
            margin: 1.3rem 0;

            h2 {
                font-size: 1.4rem;
                font-weight: 600;
            }
        }

        .item-details {
            font-size: 1rem;
            width: 90%;
            margin: 0 auto;
            color: #707070;
        }

    }

    .how-works {
        display: flex;
        align-items: center;
        padding: 2.1rem 5rem 4.5rem;
        justify-content: space-between;

        &>* {
            flex-basis: 47%;
        }

        .heading-text {
            h2 {
                font-weight: 600;
                font-size: 2.4rem;
                line-height: 1.1;
            }

        }

        .other-text {
            color: #707070;
            margin: 1.4rem 0 1.6rem;
            font-size: 1rem;
        }

        .get-started-cta {
            a {
                display: inline-block;
                background: $brand-green-light;
                color: #ffffff;
                padding: 0.7rem 1.2rem;
                font-weight: 500;
                font-size: 0.95rem;

                &:active {
                    border: none;
                }
            }
        }
    }




    .graphbg {
        background: url('../../src/assets/graph-bg-1.webp');
        width: 100%;
        height: 100%;
        background-position: center;
        background-position-y: -10%;
        background-size: cover;
        padding: 0;
        background-repeat: no-repeat;
        // display: flex;
        // justify-content: space-between;
        position: relative;

        &::before {
            content: " ";
            background: rgba(231, 250, 232, 0.65);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .steps-wrap {
            z-index: 2;
            padding: 5rem 4rem;
            position: relative;
        }

        .first {
            width: unset;
            margin-bottom: 4rem;
        }

        .first2 {
            display: flex;
            // flex-direction: column;
            width: 100%;
        }

        .first h3 {
            margin-top: 0;
            color: #312E2E;
            font-size: 2.3rem;
            font-weight: 600;
        }

        .first p {
            color: $brand-color3;
            font-size: 1.3rem;
            font-weight: $font-regular;
            margin-top: 1rem;
        }

        .grid {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            height: 100%;
            gap: 1.8rem;
            column-gap: 1rem;
        }

        .grid2 {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 2rem;
            height: 100%;

        }

        .cardBg {
            padding: 2rem 2rem 2rem 2rem;
            height: unset;
            position: relative;
            background-repeat: no-repeat !important;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            border-radius: 5px;

            .wrap-content {
                // margin-top: -6.2rem;
                position: relative;
                // width: 100%;
                // height: 100%;
                // left: 2rem;
                top: -0.6rem
            }

            .bg-text {
                position: absolute;
                right: 20px;
                top: -25px;
                font-size: 7.5rem;
                font-weight: 700;
                color: #E6F7EF;
                opacity: 0.7;
            }
        }

        .cardBg2 {
            padding: 2rem 2rem 2rem 2rem;
            height: 100%;
            margin-top: 30px;
        }

        .icon {
            // font-size: 20px;
            // color: $brand-green-light;
            width: 25px;
            height: 25px;
        }

        .cardTitle {
            color: #312E2E;
            font-size: 1.15rem;
            font-style: normal;
            font-weight: $font-semibold;
            margin-top: 1.3rem;
            width: 75%;
        }

        .cardtext {
            color: $semi-ligther-gray;
            font-size: 13px;
            font-weight: $font-semiregular;
            line-height: 22.4px;
            margin-top: 1rem;
        }


    }

    .how_slide {
        width: 100%;

        li {
            padding: 0 10px;

            &:nth-of-type(5) {
                .cardBg {
                    background-position: 80% center !important;
                }
            }
        }
    }



    .success {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;
    }

    

    .error {
        font-size: 11px;
        color: rgb(252, 112, 112);
    }

    @media only screen and (max-width: 1024px) {
        .hero-section {
            .side-right {

                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    background-color: rgba(#000, 0.3);
                }
            }

            .other-imgs {
                z-index: 2;
                left: 50%;
                right: unset;
                transform: translate(-50%, -50%);
                top: 60%;
                bottom: unset;

                .img-2 {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 1rem;
                }

                // .img-3{
                //     width: 70%;
                // }
            }

            .hero-content {
                padding: 0;
                display: block;

                .main-img {

                    // height: 60%;
                    img {
                        width: 100%;
                        border-radius: 0;
                    }
                }

                .main-heading {
                    // font-size: 2rem ;
                }
            }
        }

        .custom_controller_wrap {
            position: absolute;
            top: unset;
            margin-bottom: 50px;
            bottom: -40px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);

            button {
                width: 35px;
                height: 35px;
                appearance: none;
                background: none;
                border: none;
                margin-left: 10px;
            }

            button.prev_btn {
                background: url("../assets/landing/ctl_active.png") no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
            }

            button.disabled_prev_btn {
                background: url("../assets/landing/ctl_disabled.png") no-repeat;
                background-size: 100%;
                // transform: rotate(180deg);
            }

            button.next_btn {
                background: url("../assets/landing/ctl_active.png") no-repeat;
                background-size: 100%;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .hero-section {
            .hero-content {
                .main-heading {
                    font-size: 2.2rem;
                    font-weight: 600;
                }
            }
        }

        .landing-header {
            padding: 1rem 2rem 1rem 1rem;
        }

        .side-left {
            padding: 4rem 2rem 3.5rem 2rem;
        }

        .why-choose {
            padding: 4rem 2rem;

            .why-items {
                display: block;
                text-align: center;
            }

            .why-heading {
                font-size: 2rem;
            }

            .each-why-item {
                margin-bottom: 30px;
            }

            .item-icon {
                width: 50px;
                height: 50px;
            }

            .item-heading {
                margin-bottom: 10px;

                h2 {
                    font-size: 1.2rem;
                }
            }

            .item-details {
                font-size: 0.8rem;
            }
        }

        .how-works {
            padding: 0 2rem 4rem;
            display: block;

            .left-side {
                margin-bottom: 40px;
            }

            .heading-text h2 {
                font-size: 2rem;
            }

            .other-text {
                font-size: 0.9rem;
            }
        }



        .graphbg {
            .steps-wrap {
                padding: 4rem 2rem;
            }

            .first {
                h3 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                }
            }

            .cardBg {
                padding: 2rem 1rem 2rem 1rem;
                background-position: 50% center !important;
                height: 100%;

                .wrap-content {
                    top: unset;
                }
            }

            .how_slide li {
                padding: 0;
            }
        }
    }

    @media only screen and (min-width: 375px) {
        .graphbg .cardBg .wrap-content {
            padding: 0 15px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .landing-header {
            padding: 1rem 3rem;
        }

        .sub-text {
            width: 60%;
        }

        .main-img {}

        .hero-section .hero-content {

            .side-left {
                padding: 4rem 3rem 3.5rem 3rem;
            }


        }

        .how-works {
            padding: 4rem 3rem;

            .heading-text h2 {
                font-size: 2rem;
            }

            .other-text {
                font-size: 0.9rem;
            }
        }

        .why-choose {
            padding: 4rem 3rem;

            .why-heading {
                font-size: 2rem;
            }

            .each-why-item {
                flex-basis: 30%;
            }

            .item-icon {
                width: 50px;
                height: 50px;
            }

            .item-heading {
                h2 {
                    font-size: 1.2rem;
                }
            }

            .item-details {
                font-size: 0.8rem;
            }
        }

        .graphbg {
            .steps-wrap {
                padding: 4rem 3rem;
            }

            .first {
                h3 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                }
            }

            .cardBg {
                padding: 2rem 1rem 2rem 1rem;
                background-position: 50% center !important;
                height: 100%;

                .wrap-content {
                    top: unset;
                }
            }
        }
    }


}

.containers3 {


    .title {
        color: #FFF;
        font-size: 30px;
        font-weight: $font-mediumbold;
        width: 50%;
        padding-left: 5.5rem;
        padding-top: 4rem;
        line-height: 40.8px;
    }

    .resend {
        text-align: center;
    }
    .create-form {
        display: flex;
        flex-direction: column;
        background: #FFF;
        padding: unset;
        justify-content: space-between;
        width: unset;
        position: unset;
        // top: 35%;
        z-index: 1000;
    }

    .url-field {
        position: relative;

        input {
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .sideImg {
        width: 60%;
        height: fit-content;
        position: absolute;
        right: 0%;
        top: 0;
    }

    .sideImg .img1 {
        object-fit: contain;
        object-position: center;
        margin-right: 2rem;
        width: auto;
    }

    .sideImg .img2 {
        position: absolute;
        top: 66%;
        right: 10%;
        object-fit: contain;
        object-position: center;
        width: auto;
        bottom: 0;
        max-height: fit-content;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .formTitle {
        color: #312E2E;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .more-desc {}

    .times {
        color: #312E2E;
        font-size: 24px;
        margin-top: -.4rem;
        cursor: pointer;
    }


    .biz-cat {
        .dropdown-container {
            box-shadow: none !important;
            z-index: 1000;
            border-radius: 5px !important;
            // border-radius: 35px !important;
            height: 44px;
            border: 1.2px solid #BDBDBD;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 13px;
            // width: 100%;
            outline: none;
            width: 100%;
            height: 44px;
            outline: none;
            border: none;
            border-radius: 5px;
            background: #F4F4F4;
            color: #7A7A7A;
            font-size: 13px;
            font-weight: $font-semiregular;
            padding-left: -.6rem;
        }

        &.invalid-input-wrap .dropdown-container {
            background: #fef3f2 !important;
            border-color: #fda29b !important;
        }
    }

    .biz-cat input[type=checkbox] {
        width: 16px;
        height: 16px;
    }

    .css-yk16xz-control {
        border: none;
        outline: none;
        border-radius: 5px;
        background: #F4F4F4;
        color: #7A7A7A;
        font-size: 13px;
        box-shadow: 0 0 0 1px #FFF;
        padding-left: -.6rem;
        font-weight: $font-semiregular;
        padding-left: .6rem;
    }

    .css-1pahdxg-control {
        align-items: center;
        background-color: #F4F4F4;
        border-color: none;
        border-radius: 4px;
        border-style: none;
        border-width: 0;
        box-shadow: 0 0 0 1px #FFF;
        outline: none !important;
        position: relative;
        font-size: 13px;
        font-weight: 300;
        padding-left: .6rem;
    }

    .css-1hwfws3 {
        position: static;
        z-index: 1;
    }

    .each-options {
        font-size: 12px;
        margin-top: .5rem;
        display: flex;
        align-items: center;
    }

    .each-options label {
        margin-left: .4rem;
    }

    .url_header {
        font-size: 13px;
        margin-bottom: .6rem;
        margin-top: .7rem;
    }

    .text {
        color: $semi-ligther-gray;
        font-size: 1rem;
        font-weight: $font-semiregular;
        line-height: 22.4px;
        // margin-top: -.7rem;
        width: 75%;
        margin: 10px 0 35px !important;
    }

    .line {
        width: 49%;
        height: 4px;
        border-radius: 1px;
        background: rgba(161, 161, 162, 0.3);

    }

    .line_bg {
        height: 100%;
        width: 0px;
        border-radius: 1px;
        opacity: 1 !important;
        background: #00A85B;
        transition: ease all 500ms;
    }

    .line_bg_full {
        width: 100% !important;
    }

    .store-title {
        color: #312E2E;
        font-size: 15px;
        font-weight: 600;
        margin: 35px 0 25px !important;
    }

    .store-logo img {
        width: 68px;
        height: 68px;
    }

    .store-logo .store-img1 {
        width: 100%;
        height: 100%;
    }

    .inputbox {
        width: 80%;

        &.storename-wrap {
            position: relative;

            .error {
                margin-bottom: 0;
            }
            @media (min-width: 1440px) {
                width: 85%;
            }

        }



       
    }
    .spinner {
        position: absolute;
        top: 22px;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid $brand-color1;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    /* Spinner rotation animation */
    @keyframes spin {
        0% {
            transform: translateY(-50%) rotate(0deg);
        }

        100% {
            transform: translateY(-50%) rotate(360deg);
        }
    }

    

    .inputbox input {
        width: 100%;
        height: 44px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: #F4F4F4 !important;
        color: #7A7A7A;
        font-size: 13px;
        font-weight: $font-semiregular;
        padding-left: 1rem;
        box-shadow: none !important;
    }

    .inputbox2 {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
    }

    .inputbox2 input {
        width: 100%;
        height: 44px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: #F4F4F4;
        color: #7A7A7A;
        font-size: 13px;
        font-weight: $font-semiregular;
        padding-left: 1rem;
        // margin-top: 1rem;
    }

    .initial-fields {
        label {
            color: #535353;
            margin-bottom: 6px;
            font-weight: 400;
        }

        .inputbox2 input {
            background: unset;
            border: 1px solid #BBBBBB;
        }
    }

    .link {
        color: $brand-green-light;
        font-size: 10px;
        font-weight: $font-semiregular;
        line-height: 26px;
    }

    .select {
        width: 100%;
        height: 44px;
        outline: none;
        border: none;
        background: #F4F4F4;
        color: #7A7A7A;
        font-size: 13px;
        border-radius: 5px;
        padding-left: .8rem;
    }

    .select2 {
        width: auto;
        height: 44px;
        outline: none;
        border: none;
        background: #F4F4F4;
        color: #7A7A7A;
        font-size: 13px;
        border-radius: 5px 0 0 0;
        padding-left: .8rem;
    }

    .flex-box {
        display: flex;
        gap: 15px;
        margin-top: 1rem;
    }

    .input-container {
        display: flex;
        width: 100%;
        align-items: flex-start;
    }

    .inputbox3 {
        width: 50%;
        position: relative;
        padding-left: 0 !important;
    }

    .inputbox3,
    input {
        width: 100%;
        height: 44px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: #F4F4F4;
        color: #7A7A7A;
        font-size: 13px;
        font-weight: $font-semiregular;
        padding-left: 1rem;
    }

    .terms {
        color: #605E5E;
        font-size: 13px;
        font-weight: $font-semiregular;
        display: flex;
        flex-wrap: wrap;
        margin-left: .7rem;
    }

    .flexer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;
    }

    .check {
        width: 16px;
        height: 16px;
    }

    .terms2 {
        color: $brand-green-light;
        font-size: 13px;
        font-weight: $font-regular;
        margin: 0 .2rem;
    }

    .eye {
        position: absolute;
        right: 2%;
        top: 50%;
        color: #312E2E;
        font-weight: $font-semiregular;
    }

    .flex2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .email-txt {
        background: url("../assets/landing/email.png") no-repeat center left 10px !important;
        padding-left: 36px !important;
    }

    .continue {
        display: flex;
        width: 116px;
        height: 48px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 5px;
        background: $brand-green-light;
        border: none;
        outline: none;
        margin-bottom: 1rem;
        color: #FFF;
        font-size: 13px;
        font-weight: $font-semibold;

        &.full {
            width: 100%;
            margin-top: 30px !important;
        }
    }

    .steps-continue {

        .continue-wrap {
            display: flex;
            justify-content: space-between;

            button {
                display: unset;
                font-weight: 500;
                padding: 0;
                font-size: 14px;

                &.continue-now {
                    color: #D3D3D3;
                    cursor: not-allowed;
                    pointer-events: none;
                }

                &.back,
                &.continue-now {
                    background: none;
                    border: none;
                }

                &.back,
                &.continue-now.valid {
                    color: $brand-color1;
                    cursor: pointer;
                    pointer-events: all;
                }
            }
        }
    }

    .separator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    .separator-line {
        border: 0;
        border-top: 1px solid #757575;
        width: 40%;
        /* Adjust this value to make the lines longer or shorter */
    }

    .separator-text {
        margin: 0 10px;
        font-weight: 400;
        color: #757575;
    }

    .have-account {
        font-size: 14px;
        color: #757575;
        text-align: center;
        margin-bottom: 20px;

        a {
            color: $brand-green-light;
            font-weight: 500;
        }
    }

    .term-text {
        font-size: 14px;
        color: #757575;
        font-weight: 300;

        /* Default text color */
        a {
            text-decoration: none;
            // font-size: 16px;
            font-weight: 400;
        }

        .terms-link,
        .privacy {
            color: #00A85B;
            display: unset;

            /* Color for Terms and Privacy links */
        }

        a:hover {
            text-decoration: underline;
            /* Underline on hover for better UX */
        }
    }





    @media only screen and (max-width: 1230px) {
        .title {
            font-size: 26px;
            padding-left: 4rem;
            width: 50%;
        }

        .create-form {
            width: 44%;
            padding-left: 1rem;
            left: -2%;
        }

        .sideImg {
            width: 55%;
        }

        .sideImg .img1 {
            margin-right: 0;
        }

        .sideImg .img2 {
            right: 5%;
        }
    }

    @media only screen and (max-width: 1024px) {
        .title {
            font-size: 24px;
            padding-left: 3rem;
            line-height: 35.8px;
            width: 60%;
        }

        .sideImg .img2 {
            right: 0%;
        }

        .create-form {
            top: 27%;
        }
    }

    @media only screen and (max-width: 920px) {
        .title {
            font-size: 22px;
            padding-left: 2.5rem;
            line-height: 27.8px;
            width: 60%;
        }

        .sideImg .img2 {
            right: 0%;
            top: 75%;
        }

        .create-form {
            width: 50%;
            top: 25%;
        }
    }

    @media only screen and (max-width: 784px) {
        .title {
            font-size: 32px;
            padding-left: 4rem;
            line-height: 35.8px;
            width: 90%;
        }

        .create-form {
            width: 90%;
            position: relative;
            margin-bottom: 2rem;
            margin-top: 2rem;

        }
    }

    @media only screen and (max-width: 520px) {
        .title {
            font-size: 24px;
            padding-left: 1.5rem;
            line-height: 35.8px;
            width: 85%;
        }

        .create-form {
            width: 90%;
            position: relative;
            margin-bottom: 2rem;
            margin-top: 2rem;

        }

        @media only screen and (max-width: 400px) {
            .title {
                font-size: 20px;
                padding-left: 1.5rem;
                line-height: 30.8px;
                width: 90%;
            }

            .create-form {
                width: 90%;
                position: relative;
                margin-bottom: 2rem;
                margin-top: 2rem;

            }

            .text {
                font-size: 11px;
                line-height: 18.4px;
                width: 100%;
            }

            .store-logo img {
                width: 64px;
                height: 64px;
            }

            .inputbox {
                margin-left: .6rem;
            }

            .flex2 .text {
                display: none;
            }

            .flex2 {
                justify-content: flex-end;
            }

            .flex-box {
                flex-direction: column;
                gap: 2px;
            }

        }
    }

}

@media only screen and (max-width: 425px) {
    .containers2 {

        .why-choose .why-heading,
        .how-works .heading-text h2,
        .graphbg .first h3,
        .title {
            font-size: 1.7rem !important;
        }
    }
}

.login-link,
.login-link:hover,
.login-link:active,
.login-link:visited {
    color: #00A85B;
    text-decoration: none;
}