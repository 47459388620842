@import "./colors";
@import "./constants";

.footer_wrap {
    display: block;
    padding: $desktop-width;
    padding-top: 80px;
    padding-bottom: 80px;
    background: #FCFCFC;

    &.footer_shaded {
        background: #F3F3F3;
    }
    .soon_txt {
        // background: #00A85B;
        display: inline-block;
        // padding: 4px 10px;
        // border-radius: 30px;
        color: $brand-green-light;
        font-size: 8px;
        margin-left: 5px;
      }
    &.footer_shaded_green {
        background: $brand-green-light !important;
        color: #fff !important;

        .footer_navs {
            border-top: none;
        }

        .nav_items {

            a,
            a:hover {
                color: #fff !important;
            }
        }

        .summary {
            color: #fff !important;
        }
    }

    // .in_touch{
    //     text-align: ;
    // }

    .mobile_footer_top {
        display: flex;
        justify-content: space-between;

        .summary {
            font-size: 13px;
            font-weight: 400;
            flex-basis: 76%;
            margin-bottom: 45px;
        }

        @media screen and (max-width: 768px) {
            display: block;

            .summary {
                margin-top: 20px;
            }
        }
    }

    .logo_wrap {
        height: 44px;
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
    }

    .footer_navs {
        display: flex;
        justify-content: space-between;
        // border-top: 1px solid #D8D8D8;
        padding-top: 20px;

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    .nav_heading {
        font-size: $font-size-smallest;
        font-weight: $font-semibold;
        margin-bottom: 15px;
    }

    .in_touch {
        margin-top: 40px;

        .heading {
            font-weight: 500;
            font-size: 14px;
        }

        .address {
            color: #312E2E;
            margin-top: 20px;
            font-size: 12px;
        }

        .social_links {
            display: flex;
            margin-top: 15px;

            .each_icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                span {
                    height: 100% !important;
                }
            }
        }

        @media screen and (max-width: 767px) {
            text-align: left;
            .heading{
                font-weight: 600;
            }
            .social_links {
                justify-content: flex-start;
                .each_icon{
                    margin-right: 15px;
                }
            }
        }
    }

    .each_navlist_wrap {
        flex-basis: 30%;
        padding-right: 35px;

        &:not(:first-of-type) {
            flex-basis: 23%;
            padding-right: 0;

            .nav_items {
                margin-top: 0;
                @media screen and (min-width: 769px) {
                    margin-top: 54px;
                }
            }
        }

        .summary {
            color: #8A8A8A;
            font-size: 14px;
            // font-size: 12px;
            margin-top: 15px;
        }



        @media screen and (max-width: 767px) {
            flex-basis: 48% !important;
            margin-bottom: 50px;
            // &:first-of-type {
            //     flex-basis: 100%;
            //     text-align: center;
            // }

            // &:not(:first-of-type) {
            //     flex-basis: 46%;
            // }

        }
    }

    .nav_items {

        a,
        a:hover {
            display: table;
            text-decoration: none;
            color: #312E2E;
            font-weight: $font-semiregular;
            font-size: 13px;
            // font-size: $font-size-smallest;
            margin-top: 20px;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .subsribe_box {
        text-align: center;
        padding: 80px 0;
        width: 70%;
        margin: 0 auto;

        input {
            border-radius: 50px;
            border: 1px solid lighten($light-gray, 5%);
            width: 100%;
            height: 48px;
            padding-left: 25px;
            font-size: 15px;
            background: transparent;
            font-weight: $font-light;
        }

        .sub_heading {
            font-size: 30px;
            font-weight: $font-regular;
            margin-bottom: 14px;
        }
    }

    .input_wrap {
        position: relative;
    }

    .subscribe_btn {
        background: $brand-red;
        color: #fff;
        border-radius: 50px;
        height: 38px;
        font-weight: $font-light;
        font-size: $font-size-small;
        padding: 0 40px;
        position: absolute;
        right: 4px;
        top: 5px;
    }

    .page_bottom {
        border-top: 0.5px solid $light-gray;
        padding: 65px 0;
        display: flex;
        justify-content: space-between;

        .page_bottom_section:not(:last-of-type) {
            flex-basis: 20%;
        }

        .bottom_section_heading {
            color: $semi-black;
            font-weight: $font-semibold;
            font-size: $font-size-thick;
        }

        .page_bottom_section:last-of-type {
            flex-basis: 50%;
            display: flex;
            justify-content: space-around;

            .each_footlink {

                a,
                a:hover {
                    display: table;
                    text-decoration: none;
                    color: $light-gray;
                    font-weight: $font-light;
                    font-size: $font-size-small;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        .bottom_section_content {
            display: table;
            text-decoration: none;
            color: $light-gray;
            font-weight: $font-light;
            font-size: $font-size-small;

            a,
            a:focus,
            a:visited,
            a:active {
                color: $light-gray;
            }
        }

        .social_links {
            display: flex;
            margin-top: 15px;

            .each_icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }

                span {
                    height: 100% !important;
                }
            }
        }
    }

    .store_urls {
        display: flex;
        align-items: center;
justify-content: center;
        .each_store {
            a {
                margin: 0;
            }
        }
    }

}