$desktop-width: 30px 8%;
$side-width: 2.5%;
$font-extrabold : 900;
$font-thickbold : 800;
$font-mediumbold : 600;
$font-semibold : 500;
$font-regular : 400;
$font-semiregular : 300;
$font-light : 200;
$font-big: 32px;
$font-size-bigger: 28px;
$font-size-big: 26px;
$font-size-thicker: 24px;
$font-size-thick: 20px;
$font-size-medium: 16px;
$font-size-small: 14px;
$font-size-slight-small: 10px;
$font-size-smallest: 12px;
$font-size-tiny: 8px;
$font-size-very-tiny: 6px;
$side-padding: 35px;
// $side-padding: 8%;