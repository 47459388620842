// @import "../shared/constants";
// @import "../shared/colors";
// @import "../shared/global";

.faqContainer {
    background-color: #fcfcfc;
    padding: 3.5rem 4.5rem;

    .title {
        color: #00A85B;
        font-size: 12px;
        font-weight: 400;
    }

    .title2 {
        color: #312E2E;
        font-size: 28px;
        font-weight: 500;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: .7rem 1rem
    }

    .flex2 {
        display: flex;
        align-items: flex-start;
        margin-top: .5rem;
    }

    .texts {
        margin-left: 1rem;
    }

    .text1 {
        color: #312E2E;
        font-size: 15px;
        font-weight: 400;
    }

    .text2 {
        color: #757575;
        font-size: 13px;
        font-weight: 300;
        margin-top: -.5rem;

    }

    .button {
        display: flex;
        height: 48px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        background: #00A85B;
        border: none;
        outline: none;
    }
}

@media only screen and (max-width: 920px) {
    .faqContainer {
        padding: 3.5rem 3rem;

        .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5rem;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: .7rem 1rem
        }

        .text2 {
            width: 60%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .faqContainer {
        padding: 3.5rem 2rem;

        .title2 {
            font-size: 24px;
        }

        .info {
            flex-direction: column;
            align-items: flex-start;
        }

        .text2 {
            width: 100%;
        }

    }
}

@media only screen and (max-width: 500px) {
    .faqContainer {

        .title2 {
            font-size: 18px;
        }

    }
}

.tabsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #FFF;
    padding: 2rem 2rem;


    .tabPane {
        display: flex;
        flex-direction: column;
        width: 35%;
        margin-top: 1rem;

        .tab {
            display: flex;
            justify-content: space-between;
            width: 80%;
            margin-right: 20%;
            transition: 0.3 ease;

        }

        .tabOne {
            color: #00A85B;
            font-size: 15px;
            font-weight: 400;
        }

        .border {
            border-bottom: 2px solid #00A85B;
            width: 55%;
            margin: -.5rem auto;
        }

        .noborder {
            opacity: 0;
        }

        .tabTwo {
            color: #757575;
            font-size: 15px;
            font-weight: 300;
        }

        .keyword {
            color: #9B9B9B;
            font-size: 13px;
            font-weight: 300;
        }

        .searchbox {
            display: flex;
            align-items: center;
            position: relative;
        }

        .icon {
            position: absolute;
            top: 25%;
            left: 5%;
        }

        .input2 {
            width: 100%;
            height: 48px;
            padding-left: 3rem;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.05);
            border: none;
            outline: none;
            margin-bottom: 1rem;
            color: #B7B7B7;
            font-size: 13px;
            font-weight: 300;
        }

        .customerPane {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 37px;
            margin-top: .7rem;
            padding-left: 1rem;
        }

        .name {
            color: #555;
            font-size: 13px;
            font-weight: 300;
            margin-top: 1.2rem;
            margin-left: .8rem;
        }

        .name1 {
            color: #00A85B;
            font-size: 13px;
            font-weight: 400;
            margin-top: 1.2rem;
            margin-left: .8rem;
        }

        .icon1 {
            color: #00A85B;
        }

        .customs {
            border-radius: 5px;
            background: rgba(231, 250, 233, 0.80);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 37px;
            margin-top: .7rem;
            padding-left: 1rem;

        }

        .createTicket {
            width: 100%;
            background-color: #00A85B;
            margin-top: 2rem;
            border-radius: 10px;
        }

        .createContent {
            padding: 1rem;
        }

        .ticketTitle {
            color: #FFF;
            font-size: 18px;
            font-weight: 500;
        }

        .teamtitle {
            color: #F5F5F5;
            font-size: 13px;
            font-weight: 300;
        }

        .btn {
            border: none;
            outline: none;
            display: flex;
            height: 44px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px;
            background: #FFF;
            color: #00A85B;
            font-size: 13px;
            font-weight: 500;
            margin-top: 2rem;
        }
    }

    @media only screen and (max-width: 1100px) {
        .tabPane {
            width: 39%;

            .tab {
                width: 90%;
                margin-right: 10%;

            }
        }
    }

    @media only screen and (max-width: 920px) {
        .tabPane {
            width: 100%;

            .tab {
                width: 65%;

            }
        }
    }

    @media only screen and (max-width: 500px) {
        .tabPane {

            .tab {
                width: 83%;

            }
        }
    }

    .tabpane2 {
        width: 62%;
        margin-top: .7rem;

        .question {
            color: #312E2E;
            font-size: 16px;
            font-weight: 400;
            padding-top: .3rem;
            padding-bottom: .5rem;

           .question .row {
                display: flex;
            }
        }



        .titleIcon {
            color: #00A85B;
            font-size: 12px;
        }

        .questionTitle {
            color: #00A85B;
            font-size: 12px;
            font-weight: 400;
            margin-left: 1.7rem;
            margin-top: -.9rem;            
        }

        .question2 {
            border: 1px solid rgba(0, 0, 0, 0.10);
            color: #312E2E;
            font-size: 16px;
            font-weight: 400;
            padding: 1rem 1.5rem;
            margin-bottom: 2rem;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 15px 25px 0px rgba(73, 73, 73, 0.03);
        }

        .support {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .red_dot {
                background-color: red;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-bottom: 1rem;
                margin-right: .5rem;
            }

            .two {
                color: #00A85B;
                font-size: 15px;
                font-weight: 400;

            }

            .angle {
                font-size: 14px;
                color: #00A85B;
                margin-bottom: 1rem;
                margin-left: .4rem;
            }

            .angle2 {
                font-size: 14px;
                color: #00A85B;
                margin-bottom: 1rem;
                margin-left: .4rem;
                transform: rotate(180deg);
                transition: transform 0.3s ease;

            }

        }


        .icon1 {
            color: #00A85B;
        }

        .answer {
            color: #757575;
            font-size: 13px;
            font-weight: 300;
            line-height: 22.4px;
        }

        .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flexer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: #fcfcfc;
            margin-bottom: 2rem;
        }

        .input {
            border: none;
            width: 26%;
            outline: none;
            padding-left: 1rem;
            background-color: #fcfcfc;
            font-size: 13px;
            font-weight: 300;
            opacity: .6;
        }

        .bottom {
            border-bottom: .7px solid rgba(0, 0, 0, 0.15);
            width: 100%;
            margin-bottom: .6rem;
        }
    }

    @media only screen and (max-width: 1100px) {
        .tabpane2 {
            width: 57%;
            margin-top: .7rem;
        }
    }

    @media only screen and (max-width: 920px) {
        .tabpane2 {
            width: 100%;
            margin-top: .7rem;

            .question {
                font-size: 14px;
            }

            .answer {
                font-size: 12px;
                line-height: 19.4px;
            }

            .input {
                width: 30%;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .tabpane2 {
            .question {
                color: #312E2E;
                font-size: 13px;
            }

            .answer {
                font-size: 11px;
            }

            .input {
                width: 55%;
            }
        }
    }
}


@media only screen and (max-width: 920px) {
    .tabsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }
}